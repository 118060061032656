import api from '../../utils/axios';

//Constants
import { API_ROUTES } from '../../constants/apiRoutes';

export const jobDesignationlistApi = async ({ pageNumber, searchValue, sortBy, orderBy, size }) => {
  try {
    const response = await api.get(
      `${API_ROUTES.JOB_DESIGNATION}?page=${pageNumber}&searchName=${searchValue}&sortBy=${sortBy}&orderBy=${orderBy}&size=${size}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const addJobDesignationApi = async (designation) => {
  try {
    const response = await api.post(API_ROUTES.JOB_DESIGNATION, designation);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getJobDesignationByIdApi = async (ID) => {
  try {
    const response = await api.get(`${API_ROUTES.JOB_DESIGNATION}/${ID}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteDesignationApi = async (ID) => {
  try {
    const response = await api.delete(`${API_ROUTES.JOB_DESIGNATION}/${ID}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const editDesignationApi = async (details) => {
  try {
    const response = await api.put(`${API_ROUTES.JOB_DESIGNATION}/${details.ID}`, details.formdata);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

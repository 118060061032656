import React from 'react';

const HrCard = ({ userType, data, candidateIc }) => {
  return (
    <div className="dashboard-card-item half-width">
      <div className="white-box align-center">
        <div className="card-content full-width">
          <div className="card-title-block">
            <div className="content">
              <p className="number">{data[0]?.value || 0}</p>
              <p className="card-title">{userType}</p>
            </div>
            <div className="icon-block">
              <i className="candidate-ic">
                <img src={candidateIc} alt="candidate" />
              </i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HrCard;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import DataTable from '../../components/DataTable';
import UserDetails from '../../components/popup/UserDetails';
import { ActionButtons, Switch } from '../../components/ActionButtons';

// Constants
import {
  ACTION_TYPE,
  DEBOUNCE_TIMEOUT,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  ROLES,
  TABLE_NAME
} from '../../constants/common';
import { HR_COLUMNS } from '../../constants/tableColumns';

// Prime-React Components
import { confirmDialog } from 'primereact/confirmdialog';

// Redux-Actions
import { userDetailsAction } from '../../redux/actions/userDetails';
import { getHrList, deleteHR } from '../../redux/actions/hr';
import { userStatus } from '../../redux/actions/interviewers';

// Utils
import { wordCapitalize, getUserRole, formatExperience } from '../../utils/common';
import { debounce } from '../../utils/debounce';

// Apis
import AddEditHR from './AddEditHR';
import { editUserDetailsAction } from '../../redux/actions/admin';
import { getUpdatedYearsAndMonths } from '../../utils/date';
import ReactTooltip from 'react-tooltip';

const HRList = () => {
  const dispatch = useDispatch();
  const { hrs, userDetails } = useSelector((state) => state);
  const [hrId, setHrId] = useState(null);
  const [editHrId, setEditHrId] = useState(null);
  const [hrList, setHrList] = useState([]);
  const [hrExport, setHrExport] = useState([]);
  const [hrDetails, setHrDetails] = useState([]);
  const [tableStates, setTableStates] = useState({
    searchValue: '',
    pageNumber: INITIAL_PAGE_NUMBER,
    size: INITIAL_SIZE,
    sortBy: 'DateModified',
    orderBy: 'desc'
  });
  const [showPopup, setShowPopup] = useState(false);
  const onTogglePopup = () => setShowPopup((prevState) => !prevState);

  const [visibleRight, setVisibleRight] = useState(false);
  const onToggleSiderbar = () => setVisibleRight(!visibleRight);

  useEffect(() => {
    dispatch(getHrList(tableStates));
    if (hrId && hrId === editHrId) {
      dispatch(userDetailsAction(hrId));
    }
  }, [dispatch, tableStates, hrs?.isDeleted, hrs?.isUpdated, hrs?.isAdded]);

  useEffect(() => {
    const modifiedData = hrs?.data?.map((user) => {
      let updatedYearsAndMonths = getUpdatedYearsAndMonths(
        user.YearsOfExperience,
        user?.MonthsOfExperience,
        user.ExpirenceUPdatedDateTime
      );
      return {
        ...user,
        Mobile: (
          <div className="center">
            <span>{user.Mobile || '--'}</span>
            {user.Mobile && (
              <>
                <i
                  data-tip="Copied"
                  data-event="click focus"
                  data-for={`copyIcon_${user.Mobile}`}
                  id={`copyIcon_${user.Mobile}`}
                  className="pi pi-fw pi-copy ml-1 copy-icon"
                />
                <ReactTooltip
                  id={`copyIcon_${user.Mobile}`}
                  globalEventOff="click"
                  afterShow={() => {
                    navigator.clipboard.writeText(user.Mobile);
                    setTimeout(() => {
                      ReactTooltip.hide();
                    }, 500);
                  }}
                />
              </>
            )}
          </div>
        ),
        Email: (
          <div className="center">
            <span>{user.Email || '--'}</span>
            {user.Email && (
              <>
                <i
                  data-tip="Copied"
                  data-event="click focus"
                  data-for={`copyIcon_${user.Email}`}
                  id={`copyIcon_${user.Email}`}
                  className="pi pi-fw pi-copy ml-1 copy-icon"
                />
                <ReactTooltip
                  id={`copyIcon_${user.Email}`}
                  globalEventOff="click"
                  afterShow={() => {
                    navigator.clipboard.writeText(user.Email);
                    setTimeout(() => {
                      ReactTooltip.hide();
                    }, 500);
                  }}
                />
              </>
            )}
          </div>
        ),
        Experience: formatExperience(
          updatedYearsAndMonths.updatedYears,
          updatedYearsAndMonths.updatedMonths
        ),
        Name: (
          <Link to="#" className="table-view-popup-link" onClick={() => handleView(user.ID)}>
            {wordCapitalize(user.FirstName || '')} {wordCapitalize(user.LastName || '')}
          </Link>
        ),
        'Soft Skills':
          user.TechStack?.length > 0
            ? user.TechStack?.length > 3
              ? user.TechStack?.slice(0, 3)
                  .map((tech) => wordCapitalize(tech))
                  .join(', ') + ' ...'
              : user.TechStack?.map((tech) => wordCapitalize(tech)).join(', ')
            : '--',
        action: (
          <div className="flex align-items-center">
            <Switch UserStatus={user.UserStatus} onChange={(e) => handleActive(e, user.ID)} />
            <ActionButtons onClick={(actionType, e) => handleRowActions(actionType, user.ID, e)} />
          </div>
        )
      };
    });
    setHrList(modifiedData);
  }, [hrs]);

  useEffect(() => {
    const modifiedList = hrs?.data?.map((user) => {
      const name = `${wordCapitalize(user.FirstName)} ${wordCapitalize(user.LastName)}`;
      return {
        Experience: formatExperience(user.YearsOfExperience, user?.MonthsOfExperience),
        Name: name,
        Mobile: (
          <div className="center">
            <span>{user.Mobile || '--'}</span>
            {user.Mobile && (
              <>
                <i
                  data-tip="Copied"
                  data-event="click focus"
                  data-for={`copyIcon_${user.Mobile}`}
                  id={`copyIcon_${user.Mobile}`}
                  className="pi pi-fw pi-copy ml-1 copy-icon"
                />
                <ReactTooltip
                  id={`copyIcon_${user.Mobile}`}
                  globalEventOff="click"
                  afterShow={() => {
                    navigator.clipboard.writeText(user.Mobile);
                    setTimeout(() => {
                      ReactTooltip.hide();
                    }, 500);
                  }}
                />
              </>
            )}
          </div>
        ),
        Email: (
          <div className="center">
            <span>{user.Email || '--'}</span>
            {user.Email && (
              <>
                <i
                  data-tip="Copied"
                  data-event="click focus"
                  data-for={`copyIcon_${user.Email}`}
                  id={`copyIcon_${user.Email}`}
                  className="pi pi-fw pi-copy ml-1 copy-icon"
                />
                <ReactTooltip
                  id={`copyIcon_${user.Email}`}
                  globalEventOff="click"
                  afterShow={() => {
                    navigator.clipboard.writeText(user.Email);
                    setTimeout(() => {
                      ReactTooltip.hide();
                    }, 500);
                  }}
                />
              </>
            )}
          </div>
        ),
        'Soft Skills':
          user.TechStack?.length > 0
            ? user.TechStack?.map((tech) => wordCapitalize(tech)).join(', ')
            : '--'
      };
    });
    setHrExport(modifiedList);
  }, [hrs]);

  useEffect(() => {
    hrId && dispatch(userDetailsAction(hrId));
  }, [dispatch, hrId]);

  const modifyUserDetails = useCallback(() => {
    if (userDetails?.ID) {
      let updatedYearsAndMonths = getUpdatedYearsAndMonths(
        userDetails.YearsOfExperience,
        userDetails?.MonthsOfExperience,
        userDetails.ExpirenceUPdatedDateTime
      );
      setHrDetails([
        {
          title: 'Name',
          value: `${wordCapitalize(userDetails?.FirstName || '')} ${wordCapitalize(
            userDetails?.LastName || ''
          )}`
        },
        { title: 'Role', value: getUserRole(userDetails?.UserTypeId || '') },
        { title: 'Gender', value: userDetails?.Gender === 1 ? 'Male' : 'Female' || '' },
        ...(userDetails?.UserTypeId !== ROLES.HR_AND_INTERVIEWER
          ? [
              {
                title: 'Skills',
                value: userDetails?.TechStack?.map((tech) => wordCapitalize(tech)).join(' | ') || ''
              }
            ]
          : []),
        {
          title: 'Experience',
          value: formatExperience(
            updatedYearsAndMonths.updatedYears,
            updatedYearsAndMonths.updatedYears
          )
        },
        {
          title: 'Email Address',
          value: (
            <div className="center">
              <span>{userDetails?.Email || '--'}</span>
              {userDetails?.Email && (
                <>
                  <i
                    data-tip="Copied"
                    data-event="click focus"
                    data-for={`copyIcon_${userDetails?.Email}`}
                    id={`copyIcon_${userDetails?.Email}`}
                    className="pi pi-fw pi-copy ml-1 copy-icon"
                  />
                  <ReactTooltip
                    id={`copyIcon_${userDetails?.Email}`}
                    globalEventOff="click"
                    afterShow={() => {
                      navigator.clipboard.writeText(userDetails?.Email);
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 500);
                    }}
                  />
                </>
              )}
            </div>
          )
        },
        {
          title: 'Mobile',
          value: (
            <div className="center">
              <span>{userDetails?.Mobile || '--'}</span>
              {userDetails?.Mobile && (
                <>
                  <i
                    data-tip="Copied"
                    data-event="click focus"
                    data-for={`copyIcon_${userDetails?.Mobile}`}
                    id={`copyIcon_${userDetails?.Mobile}`}
                    className="pi pi-fw pi-copy ml-1 copy-icon"
                  />
                  <ReactTooltip
                    id={`copyIcon_${userDetails?.Mobile}`}
                    globalEventOff="click"
                    afterShow={() => {
                      navigator.clipboard.writeText(userDetails?.Mobile);
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 500);
                    }}
                  />
                </>
              )}
            </div>
          )
        },
        { title: 'Address', value: userDetails?.Address || '' }
      ]);
    }
  }, [userDetails]);

  useEffect(() => {
    modifyUserDetails();
  }, [userDetails, modifyUserDetails]);

  const handlePagination = (pageNumber, size) =>
    setTableStates((prevState) => {
      return {
        ...prevState,
        pageNumber,
        size
      };
    });

  const handleSearch = (searchValue) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        searchValue
      };
    });
  };

  const handleSort = (sortBy) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        sortBy,
        orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
      };
    });
  };

  const handleView = (ID) => {
    if (ID) {
      onTogglePopup();
      setHrId(ID);
    }
  };

  const handleRowActions = (actionType, rowId) => {
    if (actionType === ACTION_TYPE.VIEW) {
      onTogglePopup();
      setHrId(rowId);
    } else if (actionType === ACTION_TYPE.EDIT) {
      onToggleSiderbar();
      setEditHrId(rowId);
      dispatch(editUserDetailsAction(rowId));
      document.body.classList.add('sidebar-open');
    } else if (actionType === ACTION_TYPE.DELETE) {
      confirmDialog({
        header: 'Delete',
        message: 'Are you sure you want to delete?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => dispatch(deleteHR(rowId))
      });
    }
  };

  const handleActive = (event, id) => {
    dispatch(userStatus({ id, UserStatus: event.value }));
  };

  const handleAdd = () => {
    setEditHrId(null);
    onToggleSiderbar();
    setVisibleRight(true);
    document.body.classList.add('sidebar-open');
  };

  return (
    <>
      <UserDetails header="HR Details" data={hrDetails} onHide={onTogglePopup} show={showPopup} />
      <AddEditHR ID={editHrId} onHide={onToggleSiderbar} show={visibleRight} />
      <DataTable
        name={TABLE_NAME.HR}
        searchPlaceHolder="Search by HR name, Email and Mobile"
        columns={HR_COLUMNS}
        data={hrList}
        exportData={hrExport}
        totalRecords={hrs.pagination.totalRecords}
        onPagination={handlePagination}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        onAdd={() => handleAdd()}
        onSort={handleSort}
      />
    </>
  );
};

export default HRList;

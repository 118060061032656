import { createSlice } from '@reduxjs/toolkit';

// Utils
import { getDataFromLocalstorage, setDataInLocalstorage } from '../../utils/common';

// Constants
import { STORAGE_KEY } from '../../constants/common';

const auth = getDataFromLocalstorage(STORAGE_KEY);

const initialState = {
  isLogged: auth?.isLogged ? true : false,
  token: auth?.token,
  userId: auth?.userId,
  userType: auth?.userType,
  firstName: auth?.firstName,
  lastName: auth?.lastName,
  userName: auth?.userName,
  isRegisteredUser: auth?.isRegisteredUser,
  userEmail: auth?.email,
  userProfile: auth?.userProfile
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    login(state, action) {
      const authData = { ...state, ...action.payload, isLogged: true };
      setDataInLocalstorage(STORAGE_KEY, authData);
      return authData;
    },
    logout() {
      const clearState = {
        isLogged: false,
        token: null,
        userId: null,
        userType: null,
        userName: null,
        userEmail: null,
        userProfile: null,
        firstName: null,
        lastName: null,
        isRegisteredUser: true
      };
      setDataInLocalstorage(STORAGE_KEY, clearState);
      return clearState;
    }
  }
});
export const { login, logout } = authSlice.actions;
export default authSlice.reducer;

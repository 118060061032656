import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  feedbackData: { data: [] }
};

const interviewPendingFeedbackSlice = createSlice({
  name: 'interviewFeedbackPending',
  initialState,
  reducers: {
    addInterviewPendingFeedback(state, action) {
      const { data, pagination } = action.payload;
      return { data, pagination };
    }
  }
});
export const { addInterviewPendingFeedback } = interviewPendingFeedbackSlice.actions;
export default interviewPendingFeedbackSlice.reducer;

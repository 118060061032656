import { API_ROUTES } from '../../constants/apiRoutes';
import api from '../../utils/axios';

export const userDetailsApi = async (userId) => {
  try {
    const response = await api.get(`${API_ROUTES.USER_DETAILS}/${userId}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const candidateDetailsApi = async (candidateId) => {
  try {
    const response = await api.get(`${API_ROUTES.CANDIDATE_DETAILS}/${candidateId}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const interviewDetailsApi = async ({ interviewID, orderBy, interviewRoundStatus }) => {
  try {
    const response = await api.get(
      `${API_ROUTES.HR_INTERVIEW_DETAILS}/${interviewID}?orderBy=${orderBy}&interviewRoundStatus=${interviewRoundStatus}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const interviewerInterviewDetailApi = async (interviewID) => {
  try {
    const response = await api.get(`${API_ROUTES.INTERVIEWER_INTERVIEW_DETAILS}/${interviewID}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const interviewerFeedbackDetailApi = async (interviewRoundId) => {
  try {
    const response = await api.get(`${API_ROUTES.INTERVIEW_RATING}/${interviewRoundId}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const overallInterviewerFeedbackDetailApi = async (interviewId) => {
  try {
    const response = await api.get(`${API_ROUTES.GET_OVERALL_INTERVIEW_FEEDBACK}/${interviewId}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

import React from 'react';
import errorImg from '@assets/images/error-img.png';
import errorbgImg from '@assets/images/error-bg.png';

// Components
import CustomButton from '../../components/CustomButton';

// Constants
import { ROUTES, BUTTON_TYPES } from '../../constants/common';

const ErrorBoundryComponent = () => {
  const goBack = () => {
    window.location.href = ROUTES.HOME;
  };

  return (
    <div className="error-page">
      <div className="error-page-inner">
        <div className="text-wrapper">
          <h2>Something wents wrong...</h2>
          <p>
            Brace yourself till we get the error fixed.
            <br /> You may also refresh the page or try again later.
          </p>
          <CustomButton variant="contained" onClick={goBack} className="go-back-btn">
            {BUTTON_TYPES.GO_BACK_HOME}
          </CustomButton>
        </div>
        <div className="error-img">
          <img src={errorImg}></img>
        </div>
      </div>
      <div className="error-bg-img">
        <img src={errorbgImg}></img>
      </div>
    </div>
  );
};

export default ErrorBoundryComponent;

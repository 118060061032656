import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

//Components
import DataTable from '../../components/DataTable';
import { ActionButtonsForInterview, ResumeButton } from '../../components/ActionButtons';
import { Badge } from 'primereact/badge';
import ConfirmDialogWithReason from '../../components/ConfirmDialogWithReasonForInterview';

// Full calender component and plugins
import { Calendar, momentLocalizer } from 'react-big-calendar';

//Constants
import { INTERVIEW_COLUMNS } from '../../constants/tableColumns';
import {
  ROUTES,
  INTERVIEW_TYPE,
  DEBOUNCE_TIMEOUT,
  ACTION_TYPE,
  TABLE_NAME,
  HR_OVERALL_FEEDBACK,
  STATUS_INTERVIEW,
  Calendar_Messages,
  INTERVIEW_COLORS,
  eventStyleGetter,
  TooltipContent,
  STATUS,
  INTERVIEW_MODE,
  INTERVIEW_MODE_LIST,
  INTERVIEW_STATUS,
  CANDIDATE_SOURCE_TYPE,
  INTERVIEW_BACKGROUND_COLORS,
  INTERVIEW_BACKGROUND_COLORS_DARK_MODE,
  ROLES,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE
} from '../../constants/common';

//Redux-Actions
import { getInterviewDetails, getInterviewsList } from '../../redux/actions/interviews';

// Utils
import { debounce } from '../../utils/debounce';

// Redux-Slices
import { getNotification } from '../../redux/slices/notification';
import {
  wordCapitalize,
  downloadPdf,
  renderHrAvatar,
  isDarkMode,
  normalizeObject
} from '../../utils/common';
import { DROPDOWN_OPTIONS } from '../../constants/dropdownOptions';
import { interviewTableStatus } from '../../redux/slices/interviews';
import AddEditInterviews from './AddEditInterviews';
import { TabPanel, TabView } from 'primereact/tabview';
import { Overlay, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { filteredHrListApi } from '../../redux/apis/hr';
import UserDetails from '../../components/popup/UserDetails';
import { candidateDetailsAction } from '../../redux/actions/userDetails';

const localizer = momentLocalizer(moment);

const pathBase = `${process.env.REACT_APP_API_ENDPOINT}/public/uploads/resume`;

const interviewsTableStates = {
  searchValue: '',
  pageNumber: INITIAL_PAGE_NUMBER,
  size: INITIAL_SIZE,
  sortBy: 'DateModified',
  orderBy: 'desc',
  technology: '',
  interviewRoundStatus: null,
  result: null,
  filterByHR: '',
  viewMode: INTERVIEW_MODE_LIST.LIST
};

const Interviews = () => {
  const dispatch = useDispatch();
  const technologyListData = useSelector((state) => state.hrs.interviewTechnologyType);
  const navigate = useNavigate();
  const initialDateValue = dayjs();
  const { interviews, userDetails } = useSelector((state) => state);

  const { isNotification } = useSelector((state) => state.notification);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [candidateDetails, setCandidateDetails] = useState([]);
  const [interviewRound, setInterviewRound] = useState(null);
  const [interviewList, setInterviewList] = useState([]);
  const [interviewExport, setInterviewExport] = useState([]);
  const [hrs, setHrs] = useState([]);
  const [editInterviewId, setEditInterviewId] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [tableStates, setTableStates] = useState(interviews.tableStates);
  const [showPopup, setShowPopup] = useState(false);
  const [candidateId, setCandidateId] = useState(null);

  const openResumeLink = (link) => window.open(link, '_blank');

  const [visibleRight, setVisibleRight] = useState(false);
  const onToggleSiderbar = () => setVisibleRight(!visibleRight);

  const [displayConfirmAction, setDisplayConfirmAction] = useState(false);

  const [candidateName, setCandidateName] = useState(false);
  const [interviewId, setInterviewId] = useState(null);

  const onTogglePopup = () => setShowPopup((prevState) => !prevState);

  useEffect(() => {
    filteredHrListApi().then((res) => {
      if (res?.data?.data?.length) {
        let hrs = res.data.data.map((hr) => ({
          name: (hr?.FirstName || '') + ' ' + (hr?.LastName || ''),
          id: hr?.ID
        }));
        setHrs(hrs);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(
      getInterviewsList({
        ...tableStates,
        ...(activeIndex && {
          viewMode: INTERVIEW_MODE_LIST.CALENDER_VIEW,
          pageNumber: 1,
          size: 10000
        })
      })
    );
    if (isNotification) {
      dispatch(getNotification(false));
    }
  }, [
    dispatch,
    activeIndex,
    isNotification,
    tableStates,
    interviews?.isUpdated,
    interviews?.isAdded,
    interviews?.isCancelled
  ]);

  useEffect(() => {
    const modifiedCandidate = interviews?.data?.map((interviewData, index) => {
      const candidateName = `${wordCapitalize(
        interviewData?.Candidate?.FirstName
      )} ${wordCapitalize(interviewData?.Candidate?.LastName)}`;
      return {
        ...interviewData,
        CandidateName: (
          <Link
            to="#"
            className="table-view-popup-link"
            onClick={() => handleView(interviewData?.Candidate?.ID)}>
            {`${wordCapitalize(interviewData?.Candidate?.FirstName)} ${wordCapitalize(
              interviewData?.Candidate?.LastName
            )}`}
          </Link>
        ),
        Company: interviewData?.Candidate?.CurrentCompany
          ? wordCapitalize(interviewData?.Candidate?.CurrentCompany)
          : '--',

        Technology: (
          <ul className="technology-list">
            {interviewData?.Candidate?.UMap && interviewData?.Candidate?.UMap?.length > 0
              ? interviewData?.Candidate?.UMap?.map((tech) => (
                  <li
                    key={tech.MapT.ID}
                    style={{
                      backgroundColor: `${
                        isDarkMode() ? tech?.MapT?.DarkModeColor : tech?.MapT?.TechnologyColor
                      }`
                    }}
                    className="technology-badge">
                    {tech.MapT.Technology}
                  </li>
                ))
              : []}
          </ul>
        ),

        Result: `${HR_OVERALL_FEEDBACK.filter((num) => num.code === interviewData.Result).map(
          (type) => type.name
        )}`,

        Resume: (
          <div className="flex">
            {interviewData?.Candidate?.ResumeOfCandidate && (
              <>
                <ResumeButton
                  onClick={() => {
                    openResumeLink(
                      `${pathBase}/${interviewData?.Candidate?.ID}/${interviewData?.Candidate?.ResumeOfCandidate?.FilePath}`
                    );
                  }}
                />
                <i
                  data-tip="Download"
                  onClick={() =>
                    downloadPdf(
                      pathBase,
                      interviewData?.Candidate?.ID,
                      interviewData?.Candidate?.ResumeOfCandidate?.FilePath
                    )
                  }
                  className="pi pi-fw pi-download"
                />
              </>
            )}
          </div>
        ),
        HR: renderHrAvatar(
          interviewData?.CurrentRound?.HROfRound?.ID,
          interviewData?.CurrentRound?.HROfRound?.UserProfile,
          interviewData?.CurrentRound?.HROfRound?.FirstName,
          interviewData?.CurrentRound?.HROfRound?.LastName
        ),
        Interviewer: interviewData?.CurrentRound?.InterviewRoundInvitations?.length
          ? interviewData?.CurrentRound?.InterviewRoundInvitations.filter((interviewerDetails) => {
              if (
                interviewerDetails?.Status === STATUS.ACCEPT ||
                interviewerDetails?.Status === STATUS.PENDING
              )
                return true;
            })
              .map(
                (interview) =>
                  `${
                    interview?.Invited?.FirstName
                      ? wordCapitalize(interview?.Invited?.FirstName)
                      : ''
                  } ${
                    interview?.Invited?.LastName ? wordCapitalize(interview?.Invited?.LastName) : ''
                  }`
              )
              .join(', ')
          : '',

        JobDesignation:
          interviewData?.JobDesignation &&
          wordCapitalize(interviewData?.JobDesignation?.Designation),
        InterviewRound: `${INTERVIEW_TYPE.filter(
          (num) => num.code === interviewData?.InterviewRound
        ).map((type) => type.name)}`,

        Status: (
          <div className="center interview-status">
            {getBadgeAccordingToColor(interviewData)}
            {interviewData?.CurrentRound?.Status === STATUS_INTERVIEW.CANCELLED &&
              (interviewData?.CurrentRound?.CancellationReason ||
                interviewData?.CancellationReason) && (
                <>
                  <i
                    className="custom-target-icon pi pi-info-circle pl-1"
                    data-tip
                    data-for={`reason-tooltip-${index}`}
                    key={index}
                    style={{ cursor: 'pointer' }}
                  />
                  <ReactTooltip key={index} id={`reason-tooltip-${index}`} aria-haspopup="true">
                    {(interviewData?.CurrentRound?.CancelledByUser?.FirstName ||
                      interviewData?.CurrentRound?.CancelledByUser?.LastName) && (
                      <p>{`Cancelled By: ${
                        wordCapitalize(
                          interviewData?.CurrentRound?.CancelledByUser?.FirstName || ''
                        ) +
                        ' ' +
                        wordCapitalize(interviewData?.CurrentRound?.CancelledByUser?.LastName || '')
                      } ${
                        interviewData?.CurrentRound?.CancelledByUser?.UserTypeId === ROLES.HR
                          ? '(HR)'
                          : interviewData?.CurrentRound?.CancelledByUser?.UserTypeId ===
                            ROLES.INTERVIEWER
                          ? '(Interviewer)'
                          : interviewData?.CurrentRound?.CancelledByUser?.UserTypeId ===
                            ROLES.HR_AND_INTERVIEWER
                          ? '(HR and Interviewer)'
                          : ''
                      }`}</p>
                    )}
                    <p>{`Cancellation Reason: ${
                      interviewData?.CurrentRound?.CancellationReason ||
                      interviewData?.CancellationReason ||
                      '--'
                    }`}</p>
                  </ReactTooltip>
                </>
              )}
          </div>
        ),

        action: (
          <div className="flex align-items-center">
            <ActionButtonsForInterview
              disabledSendJD={interviewData?.CurrentRound?.Status === STATUS_INTERVIEW.CANCELLED}
              disabled={
                interviewData?.CurrentRound?.Status === STATUS_INTERVIEW.CANCELLED ||
                interviewData?.CurrentRound?.Status ===
                  STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_COMPLETE ||
                interviewData?.CurrentRound?.Status ===
                  STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_COMPLETE ||
                interviewData?.CurrentRound?.Status === STATUS_INTERVIEW.MANAGEMENT_COMPLETE ||
                interviewData?.CurrentRound?.Status === STATUS_INTERVIEW.PRACTICAL_COMPLETE ||
                interviewData?.CurrentRound?.Status === STATUS_INTERVIEW.HR_COMPLETE
              }
              onClick={(actionType) =>
                handleRowActions(actionType, interviewData.ID, candidateName)
              }
              status={interviewData?.CurrentRound?.Status}
            />
          </div>
        )
      };
    });
    setInterviewList(modifiedCandidate);
    const interviewsOnCalender = [];
    interviews?.data?.length > 0 &&
      interviews?.data.forEach((interview) => {
        const technology =
          interview?.Candidate?.UMap?.length > 0
            ? interview?.Candidate?.UMap?.map((item) => item.MapT.Technology).join(', ')
            : '';
        !!interview?.RoundOfInterview?.length &&
          interview.RoundOfInterview.forEach((round) => {
            interviewsOnCalender.push({
              interviewId: interview.ID,
              title: `${new Date(round.StartDateOfInterviewRound).getHours()}:${new Date(
                round.StartDateOfInterviewRound
              ).getMinutes()} - ${new Date(round.EndDateOfInterviewRound).getHours()}:${new Date(
                round.EndDateOfInterviewRound
              ).getMinutes()} ${
                (interview?.Candidate?.FirstName || '') +
                ' ' +
                (interview?.Candidate?.LastName || '')
              }`,
              start: round.StartDateOfInterviewRound,
              end: round.EndDateOfInterviewRound,
              technology: technology,
              interviewer: round?.InterviewRoundInvitations?.length
                ? round.InterviewRoundInvitations.filter((interviewerDetails) => {
                    if (
                      interviewerDetails?.Status === STATUS.ACCEPT ||
                      interviewerDetails?.Status === STATUS.PENDING
                    )
                      return true;
                  })
                    .map(
                      (interviewer) =>
                        `${interviewer?.Invited?.FirstName || ''} ${
                          interviewer?.Invited?.LastName || ''
                        }`
                    )
                    .join(', ')
                : '',
              interviewLevel: INTERVIEW_TYPE.find((num) => num.code === round?.InterviewRound)
                ?.name,
              interviewMode: INTERVIEW_MODE.find((num) => num.code === round.InterviewRoundMode)
                ?.name,
              scheduleBy:
                (round?.HROfRound?.FirstName || '') + ' ' + (round?.HROfRound?.LastName || ''),
              jobTitle:
                interview?.Candidate?.UMap?.length > 0 &&
                interview?.Candidate?.UMap.map((obj) => obj.MapT.Technology).join(', '),
              color:
                round?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_PENDING ||
                round?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_PENDING ||
                round?.Status === STATUS_INTERVIEW.MANAGEMENT_PENDING ||
                round.Status === STATUS_INTERVIEW.PRACTICAL_PENDING ||
                round.Status === STATUS_INTERVIEW.HR_PENDING
                  ? INTERVIEW_COLORS.PENDING
                  : round?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_SCHEDULE ||
                    round?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_SCHEDULE ||
                    round?.Status === STATUS_INTERVIEW.MANAGEMENT_SCHEDULE ||
                    round.Status === STATUS_INTERVIEW.PRACTICAL_SCHEDULE ||
                    round.Status === STATUS_INTERVIEW.HR_SCHEDULE
                  ? INTERVIEW_COLORS.SCHEDULE
                  : round?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_COMPLETE ||
                    round?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_COMPLETE ||
                    round?.Status === STATUS_INTERVIEW.MANAGEMENT_COMPLETE ||
                    round.Status === STATUS_INTERVIEW.PRACTICAL_COMPLETE ||
                    round.Status === STATUS_INTERVIEW.HR_COMPLETE
                  ? INTERVIEW_COLORS.COMPLETE
                  : round?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_RESCHEDULE ||
                    round?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_RESCHEDULE ||
                    round?.Status === STATUS_INTERVIEW.MANAGEMENT_RESCHEDULE ||
                    round.Status === STATUS_INTERVIEW.PRACTICAL_RESCHEDULE ||
                    round.Status === STATUS_INTERVIEW.HR_RESCHEDULE
                  ? INTERVIEW_COLORS.RESCHEDULE
                  : round.Status === STATUS_INTERVIEW.CANCELLED
                  ? INTERVIEW_COLORS.CANCEL
                  : '',
              backgroundColor:
                round?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_PENDING ||
                round?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_PENDING ||
                round?.Status === STATUS_INTERVIEW.MANAGEMENT_PENDING ||
                round.Status === STATUS_INTERVIEW.PRACTICAL_PENDING ||
                round.Status === STATUS_INTERVIEW.HR_PENDING
                  ? isDarkMode()
                    ? INTERVIEW_BACKGROUND_COLORS_DARK_MODE.PENDING
                    : INTERVIEW_BACKGROUND_COLORS.PENDING
                  : round?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_SCHEDULE ||
                    round?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_SCHEDULE ||
                    round?.Status === STATUS_INTERVIEW.MANAGEMENT_SCHEDULE ||
                    round.Status === STATUS_INTERVIEW.PRACTICAL_SCHEDULE ||
                    round.Status === STATUS_INTERVIEW.HR_SCHEDULE
                  ? isDarkMode()
                    ? INTERVIEW_BACKGROUND_COLORS_DARK_MODE.SCHEDULE
                    : INTERVIEW_BACKGROUND_COLORS.SCHEDULE
                  : round?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_COMPLETE ||
                    round?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_COMPLETE ||
                    round?.Status === STATUS_INTERVIEW.MANAGEMENT_COMPLETE ||
                    round.Status === STATUS_INTERVIEW.PRACTICAL_COMPLETE ||
                    round.Status === STATUS_INTERVIEW.HR_COMPLETE
                  ? isDarkMode()
                    ? INTERVIEW_BACKGROUND_COLORS_DARK_MODE.COMPLETE
                    : INTERVIEW_BACKGROUND_COLORS.COMPLETE
                  : round?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_RESCHEDULE ||
                    round?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_RESCHEDULE ||
                    round?.Status === STATUS_INTERVIEW.MANAGEMENT_RESCHEDULE ||
                    round.Status === STATUS_INTERVIEW.PRACTICAL_RESCHEDULE ||
                    round.Status === STATUS_INTERVIEW.HR_RESCHEDULE
                  ? isDarkMode()
                    ? INTERVIEW_BACKGROUND_COLORS_DARK_MODE.RESCHEDULE
                    : INTERVIEW_BACKGROUND_COLORS.RESCHEDULE
                  : round.Status === STATUS_INTERVIEW.CANCELLED
                  ? isDarkMode()
                    ? INTERVIEW_BACKGROUND_COLORS_DARK_MODE.CANCEL
                    : INTERVIEW_BACKGROUND_COLORS.CANCEL
                  : ''
            });
          });
      });
    setSelectedEvents(interviewsOnCalender);
  }, [interviews, isDarkMode()]);

  useEffect(() => {
    candidateId && dispatch(candidateDetailsAction(candidateId));
  }, [dispatch, candidateId]);

  const handleView = (ID) => {
    if (ID) {
      onTogglePopup();
      setCandidateId(ID);
    }
  };

  const modifyUserDetails = useCallback(() => {
    if (userDetails?.ID) {
      // Display -- if the candidate mobile number is not provided.
      let mobileValue = '--';
      if (userDetails?.Mobile) {
        mobileValue = userDetails?.Mobile;
      }
      if (mobileValue && mobileValue === 'null') {
        mobileValue = '--';
      }
      const candidateDetailsData = [
        {
          title: 'Name',
          value: `${wordCapitalize(userDetails?.FirstName) || ''} 
              ${wordCapitalize(userDetails?.LastName) || ''}`
        },
        { title: 'Gender', value: userDetails?.Gender === 1 ? 'Male' : 'Female' || '' },
        {
          title: 'Experience',
          value:
            `${userDetails?.YearsOfExperience} Years` +
            `${userDetails?.MonthsOfExperience ? ` ${userDetails?.MonthsOfExperience} Months` : ''}`
        },
        {
          title: 'Email Address',
          value: (
            <div className="center">
              <span>{userDetails?.Email || '--'}</span>
              {userDetails?.Email && (
                <>
                  <i
                    id={userDetails?.ID}
                    data-tip="Copied"
                    data-event="click focus"
                    data-for={userDetails?.ID}
                    className="pi pi-fw pi-copy ml-1 copy-icon"
                  />
                  <ReactTooltip
                    id={userDetails?.ID}
                    globalEventOff="click"
                    afterShow={() => {
                      navigator.clipboard.writeText(userDetails?.Email);
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 500);
                    }}
                  />
                </>
              )}
            </div>
          )
        },
        {
          title: 'Mobile',
          value: (
            <div className="center">
              <span>{mobileValue || '--'}</span>
              {userDetails?.Mobile && (
                <>
                  <i
                    id={mobileValue}
                    data-tip="Copied"
                    data-event="click focus"
                    data-for={mobileValue}
                    className="pi pi-fw pi-copy ml-1 copy-icon"
                  />
                  <ReactTooltip
                    id={mobileValue}
                    globalEventOff="click"
                    afterShow={() => {
                      navigator.clipboard.writeText(mobileValue);
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 500);
                    }}
                  />
                </>
              )}
            </div>
          )
        },
        {
          title: 'Second Mobile',
          value: (
            <div className="center">
              <span>{userDetails?.SecondaryMobile || '--'}</span>
              {userDetails?.SecondaryMobile && (
                <>
                  <i
                    id={userDetails?.SecondaryMobile}
                    data-tip="Copied"
                    data-event="click focus"
                    data-for={userDetails?.SecondaryMobile}
                    className="pi pi-fw pi-copy ml-1 copy-icon"
                  />
                  <ReactTooltip
                    id={userDetails?.SecondaryMobile}
                    globalEventOff="click"
                    afterShow={() => {
                      navigator.clipboard.writeText(userDetails?.SecondaryMobile);
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 500);
                    }}
                  />
                </>
              )}
            </div>
          )
        },
        {
          title: 'Current Company',
          value: userDetails?.CurrentCompany || ''
        },
        {
          title: 'Technology',
          value:
            userDetails.CandidateTechnology && userDetails.CandidateTechnology.length > 0
              ? userDetails.CandidateTechnology?.map((tech) => tech.Technology).join(', ')
              : ''
        },
        {
          title: 'Current CTC',
          value: userDetails?.CurrentCTC ? `${userDetails?.CurrentCTC} LPA` : ''
        },
        {
          title: 'Candidate Location',
          value: userDetails?.Address || ''
        },
        {
          title: 'Candidate Source',
          value: CANDIDATE_SOURCE_TYPE.find((type) => type.code === userDetails?.CandidateSource)
            ?.name
        }
      ];
      // Only add the candidate reference name if candidate provided source is reference.
      if (
        userDetails?.CandidateSource ===
        CANDIDATE_SOURCE_TYPE[CANDIDATE_SOURCE_TYPE.length - 1].code
      ) {
        candidateDetailsData.push({
          title: 'Employee Ref Name',
          value: userDetails?.EmployeeReferenceName || ''
        });
      }
      candidateDetailsData.push(
        {
          title: 'Created By',
          value: renderHrAvatar(
            userDetails?.ReportsToUser?.ID,
            userDetails?.ReportsToUser?.UserProfile,
            userDetails?.ReportsToUser?.FirstName,
            userDetails?.ReportsToUser?.LastName,
            true
          )
        },
        {
          title: 'Last Updated Time',
          value: userDetails?.DateModified ? moment(userDetails?.DateModified).format('lll') : null
        }
      );
      setCandidateDetails(candidateDetailsData);
    }
  }, [userDetails]);

  useEffect(() => {
    modifyUserDetails();
  }, [userDetails, modifyUserDetails]);

  useEffect(() => {
    const modifiedCandidate = interviews?.data?.map((interviewData) => {
      const Type =
        interviewData?.Candidate?.UMap?.length > 0
          ? interviewData?.Candidate?.UMap?.map((item) => item.MapT.Technology).join(', ')
          : '';
      return {
        CandidateName: `${wordCapitalize(interviewData?.Candidate?.FirstName)} ${wordCapitalize(
          interviewData?.Candidate?.LastName
        )}`,
        Technology: Type,
        Result: `${HR_OVERALL_FEEDBACK.filter((num) => num.code === interviewData.Result).map(
          (type) => type.name
        )}`,
        JobDesignation:
          interviewData?.JobDesignation &&
          wordCapitalize(interviewData?.JobDesignation?.Designation)
      };
    });
    setInterviewExport(modifiedCandidate);
  }, [interviews]);

  useEffect(() => {
    if (interviewRound) {
      dispatch(interviewTableStatus(tableStates.interviewRoundStatus));
      navigate(`${ROUTES.HR.INTERVIEW_ROUND}/${interviewRound}`);
    }
  }, [interviewRound, navigate]);

  useEffect(() => {
    if (candidateName && interviewId) {
      setDisplayConfirmAction(true);
    } else {
      setDisplayConfirmAction(false);
      setCandidateName('');
    }
  }, [candidateName, interviewId]);

  const handlePagination = (pageNumber, size) =>
    setTableStates((prevState) => {
      return {
        ...prevState,
        pageNumber,
        size
      };
    });

  const handleSearch = (searchValue) =>
    setTableStates((prevState) => {
      return {
        ...prevState,
        searchValue
      };
    });

  const handleSort = (sortBy) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        sortBy,
        orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
      };
    });
  };

  const handleStatusFilter = (interviewRoundStatus) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        interviewRoundStatus: interviewRoundStatus || null
      };
    });
  };

  const handleRowActions = (actionType, rowId, candidateName) => {
    if (actionType === ACTION_TYPE.EDIT) {
      onToggleSiderbar();
      setEditInterviewId(rowId);
      dispatch(getInterviewDetails(rowId));
      document.body.classList.add('sidebar-open');
    } else if (actionType === ACTION_TYPE.NEXT_ROUND) {
      setInterviewRound(rowId);
    } else if (actionType === ACTION_TYPE.CANCEL) {
      setInterviewId(rowId);
      setCandidateName(candidateName);
    } else if (actionType === ACTION_TYPE.REJECT) {
      setInterviewId(rowId);
      setDisplayRejectAction(true);
    }
  };

  const handleAdd = () => {
    setEditInterviewId(null);
    onToggleSiderbar();
    setVisibleRight(true);
    document.body.classList.add('sidebar-open');
  };

  const getBadgeAccordingToColor = (interviewData) => {
    const badgeArr = [];
    INTERVIEW_STATUS.forEach((statusObj) => {
      if (statusObj.code === Number(interviewData?.CurrentRound?.Status)) {
        let badgeColor;
        switch (interviewData?.CurrentRound?.Status) {
          case STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_SCHEDULE:
          case STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_SCHEDULE:
          case STATUS_INTERVIEW.MANAGEMENT_SCHEDULE:
          case STATUS_INTERVIEW.PRACTICAL_SCHEDULE:
          case STATUS_INTERVIEW.HR_SCHEDULE: {
            badgeColor = 'schedule-badge';
            break;
          }
          case STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_RESCHEDULE:
          case STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_RESCHEDULE:
          case STATUS_INTERVIEW.MANAGEMENT_RESCHEDULE:
          case STATUS_INTERVIEW.HR_RESCHEDULE:
          case STATUS_INTERVIEW.PRACTICAL_RESCHEDULE: {
            badgeColor = 'reschedule-badge';
            break;
          }
          case STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_COMPLETE:
          case STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_COMPLETE:
          case STATUS_INTERVIEW.MANAGEMENT_COMPLETE:
          case STATUS_INTERVIEW.PRACTICAL_COMPLETE:
          case STATUS_INTERVIEW.HR_COMPLETE: {
            badgeColor = 'complete-badge';
            break;
          }
          case STATUS_INTERVIEW.CANCELLED: {
            badgeColor = 'cancel-badge';
            break;
          }
          default: {
            badgeColor = 'pending-badge';
            break;
          }
        }
        badgeArr.push(
          <Badge
            key={interviewData?.CurrentRound?.Status}
            className={`white-space-nowrap ${badgeColor}`}
            value={
              [
                STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_RESCHEDULE,
                STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_RESCHEDULE,
                STATUS_INTERVIEW.PRACTICAL_RESCHEDULE,
                STATUS_INTERVIEW.MANAGEMENT_RESCHEDULE,
                STATUS_INTERVIEW.HR_RESCHEDULE
              ].indexOf(interviewData?.CurrentRound?.Status) !== -1
                ? `${statusObj.name} -  ${
                    interviewData?.CurrentRound?.InterviewRoundInvitations.filter(
                      (child) => child.Status === STATUS.ACCEPT
                    ).length >= 1
                      ? 'Accepted'
                      : 'Pending'
                  }`
                : `${statusObj.name}`
            }
          />
        );
      }
    });
    return badgeArr;
  };

  const CustomEvent = ({ event }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const closeTooltip = () => {
      setShowTooltip(false);
    };

    const openTooltip = () => {
      setShowTooltip(true);
    };

    const ref = useRef(null);

    const getTarget = () => {
      return ref.current;
    };

    const handleRef = (element) => {
      if (element) {
        ref.current = element.parentNode;
        ref.current.addEventListener('mouseover', openTooltip);
        ref.current.addEventListener('mouseout', closeTooltip);
      }
    };

    return (
      <div ref={handleRef}>
        <span style={{ whiteSpace: 'break-spaces' }}>{event.title}</span>
        <Overlay
          rootClose
          target={getTarget}
          show={showTooltip}
          placement="bottom"
          onHide={closeTooltip}>
          <Tooltip id="test" className="calender-tooltip">
            <TooltipContent event={event} />
          </Tooltip>
        </Overlay>
      </div>
    );
  };

  const handleFilterByHr = (filterByHR) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        filterByHR
      };
    });
  };

  const handleTechnologyFilter = (value) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        technology: value || ''
      };
    });
  };

  const handleResultFilter = (value) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        result: value || null
      };
    });
  };

  return (
    <div className="card interview-card">
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
        className="schedule-tabbing-wrap custom-tabbing">
        <TabPanel header="List view">
          {visibleRight && (
            <AddEditInterviews ID={editInterviewId} onHide={onToggleSiderbar} show={visibleRight} />
          )}
          {displayConfirmAction && (
            <ConfirmDialogWithReason
              bodyTitle={
                <>
                  Are you sure, you want to cancel the whole interview for
                  <strong> &nbsp;{candidateName}?</strong>
                </>
              }
              interviewId={interviewId}
              dialogHeader="Cancel interview"
              setInterviewId={setInterviewId}
              displayConfirmAction={displayConfirmAction}
              isWholeInterview
            />
          )}
          <DataTable
            onAdd={() => handleAdd()}
            searchPlaceHolder="Search by Candidate name, Email and Mobile"
            name={TABLE_NAME.INTERVIEW}
            totalRecords={interviews.pagination.totalRecords}
            onPagination={handlePagination}
            onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
            onSort={handleSort}
            data={interviewList}
            hrFilter
            hrs={hrs}
            onFilter={handleFilterByHr}
            selectedHrs={tableStates.filterByHR}
            exportData={interviewExport}
            columns={INTERVIEW_COLUMNS}
            isDropdownMultiSelect
            dropdownPlaceHolder="Select Technology"
            onStatusFilter={handleTechnologyFilter}
            dropdownValue={tableStates.technology}
            dropdown={
              technologyListData?.length
                ? technologyListData.map((tech) => ({ name: tech.Technology, value: tech.ID }))
                : []
            }
            secondDropdownValue={tableStates.interviewRoundStatus}
            secondDropdown={DROPDOWN_OPTIONS}
            secondDropdownPlaceHolder="Select Status"
            onSecondDropdownChange={handleStatusFilter}
            thirdDropdownPlaceHolder="Select Result"
            onThirdDropdownChange={handleResultFilter}
            thirdDropdownValue={tableStates.result}
            thirdDropdown={HR_OVERALL_FEEDBACK.map((feedback) => ({
              name: feedback.name,
              value: feedback.code
            }))}
            clearFilter
            isClearFilterDisabled={
              JSON.stringify(normalizeObject(interviewsTableStates)) ===
              JSON.stringify(normalizeObject(tableStates))
            }
            clearFilterOnClick={() => setTableStates(interviewsTableStates)}
          />
        </TabPanel>
        <TabPanel header="Calender view">
          <div className="interview-calender-view">
            <div className="col-12 p-0 full-calender-wrapper">
              {initialDateValue && (
                <div className={`calendar-wrap ${isDarkMode() && 'dark-mode'}`}>
                  <Calendar
                    localizer={localizer}
                    events={selectedEvents}
                    startAccessor={(e) => new Date(e.start)}
                    onSelectEvent={(event) => navigate(`/hr/interviews/${event.interviewId}`)}
                    eventPropGetter={eventStyleGetter}
                    tooltipAccessor={null}
                    messages={Calendar_Messages}
                    views={['month', 'week', 'day']}
                    showAllEvents
                    components={{
                      event: CustomEvent
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </TabPanel>
      </TabView>
      <div className="legends">
        <div className="legend-label">
          <span className="pending-label" />
          Pending
        </div>
        <div className="legend-label">
          <span className="schedule-label" />
          Schedule
        </div>
        <div className="legend-label">
          <span className="reschedule-label" />
          Reschedule
        </div>
        <div className="legend-label">
          <span className="complete-label" />
          Completed
        </div>
        <div className="legend-label">
          <span className="cancel-label" />
          Cancel
        </div>
      </div>

      <UserDetails
        header="Candidate Details"
        data={candidateDetails}
        onHide={onTogglePopup}
        show={showPopup}
      />
    </div>
  );
};

export default Interviews;

import {
  READ_NOTIFICATIONS,
  USER_NOTIFICATIONS,
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PERMISSION_CHANGE_REQUEST,
  USER_PERMISSION_REQUEST,
  USER_PROFILE_DETAILS,
  USER_PROFILE_EDIT_REQUEST
} from '../../constants/redux';

export const getUserProfileDetails = () => {
  return {
    type: USER_PROFILE_DETAILS
  };
};
export const editUserProfileDetails = (userProfileDetailsDataObj) => {
  return {
    type: USER_PROFILE_EDIT_REQUEST,
    payload: userProfileDetailsDataObj
  };
};
export const changeUserPassword = (credentials) => {
  return {
    type: USER_PASSWORD_CHANGE_REQUEST,
    payload: credentials
  };
};

export const userPermissionChangeRequestAction = (permission) => {
  return {
    type: USER_PERMISSION_CHANGE_REQUEST,
    payload: permission
  };
};

export const getUserPermissionRequestAction = (permission) => {
  return {
    type: USER_PERMISSION_REQUEST,
    payload: permission
  };
};

export const getUserNotificationsAction = (permission) => {
  return {
    type: USER_NOTIFICATIONS,
    payload: permission
  };
};

export const readNotificationsAction = (notifications) => {
  return {
    type: READ_NOTIFICATIONS,
    payload: notifications
  };
};

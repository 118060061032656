import api from '../../utils/axios';

// Constants
import { API_ROUTES } from '../../constants/apiRoutes';

export const getUserProfileDetailsApi = async () => {
  try {
    const response = await api.get(API_ROUTES.USER_PROFILE_DETAILS);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const editUserProfileDetailsApi = async (userProfileDetailsDataObj) => {
  try {
    const response = await api.put(`${API_ROUTES.USER_PROFILE_DETAILS}`, userProfileDetailsDataObj);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const changeUserPasswordApi = async (password) => {
  try {
    const response = await api.put(`${API_ROUTES.USER_PASSWORD_CHANGE}`, {
      oldPassword: password.oldPassword,
      password: password.password,
      confirmPassword: password.confirmPassword
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const userPermissionApi = async ({ isEmailEnable, isNotificationEnable }) => {
  try {
    const response = await api.put(`${API_ROUTES.USER_PERMISSION}`, {
      isEmailEnable,
      isNotificationEnable
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUserPermissionApi = async () => {
  try {
    const response = await api.get(`${API_ROUTES.USER_PERMISSION}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

import api from '../../utils/axios';

// Constants
import { API_ROUTES } from '../../constants/apiRoutes';

export const loginApi = async ({ email, password }) => {
  try {
    const response = await api.post(API_ROUTES.LOGIN, {
      Email: email,
      Password: password
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const forgotPasswordApi = async (email) => {
  try {
    const response = await api.post(API_ROUTES.FORGOT_PASSWORD, email);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const resetPasswordApi = async (password) => {
  try {
    const response = await api.post(`${API_ROUTES.RESET_PASSWORD}/${password.token}`, {
      password: password.password,
      confirmPassword: password.confirmPassword
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const adminDashboardSlice = createSlice({
  name: 'admin_dashboard',
  initialState,
  reducers: {
    editUserDetails(state, action) {
      const userDetails = action.payload;
      return { ...state, userDetails };
    },
    getAdminDashboardData(state, action) {
      const dashboardCountData = action.payload;
      return { ...state, dashboardCountData };
    },
    getInterviewRoundData(state, action) {
      const interviewRoundData = action.payload;
      return { ...state, interviewRoundData };
    },
    getInterviewersData(state, action) {
      const interviewersData = action.payload;
      return { ...state, interviewersData };
    },
    getInterviewersFilterdData(state, action) {
      const intervieweFilterdData = action.payload;
      return { ...state, intervieweFilterdData };
    },
    getPieChartFilterdData(state, action) {
      const pieChartFilterdData = action.payload;
      return { ...state, pieChartFilterdData };
    },
    getRoundFilterdData(state, action) {
      const roundFilterdData = action.payload;
      return { ...state, roundFilterdData };
    },
    setEmailMobileValidationData(state, action) {
      return { ...state, status: action.payload };
    }
  }
});

export const {
  editUserDetails,
  getAdminDashboardData,
  getInterviewRoundData,
  getInterviewersData,
  getInterviewersFilterdData,
  getPieChartFilterdData,
  getRoundFilterdData,
  setEmailMobileValidationData
} = adminDashboardSlice.actions;
export default adminDashboardSlice.reducer;

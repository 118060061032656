import {
  JOB_DESCRIPTIONS_REQUEST,
  ADD_JOB_DESCRIPTION_REQUEST,
  EDIT_JOB_DESCRIPTION_REQUEST,
  JOB_DESCRIPTION_GET_DETAILS,
  DELETE_JOB_DESCRIPTION_REQUEST,
  SEND_JOB_DESCRIPTION
} from '../../constants/redux';

export const getJobDescriptionList = (payload) => {
  return {
    type: JOB_DESCRIPTIONS_REQUEST,
    payload
  };
};

export const addJobDescription = (description) => {
  return {
    type: ADD_JOB_DESCRIPTION_REQUEST,
    payload: description
  };
};

export const deleteDescription = (ID) => {
  return {
    type: DELETE_JOB_DESCRIPTION_REQUEST,
    payload: ID
  };
};

export const editJobDescription = (Details) => {
  return {
    type: EDIT_JOB_DESCRIPTION_REQUEST,
    payload: Details
  };
};

export const getJobDescriptionById = (ID) => {
  return {
    type: JOB_DESCRIPTION_GET_DETAILS,
    payload: ID
  };
};

export const sendJobDescription = (Details) => {
  return {
    type: SEND_JOB_DESCRIPTION,
    payload: Details
  };
};

import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';

// Api-Services
import {
  jobDesignationlistApi,
  addJobDesignationApi,
  getJobDesignationByIdApi,
  editDesignationApi,
  deleteDesignationApi
} from '../apis/designation';

// Constants
import {
  JOB_DESIGNATION_REQUEST,
  ADD_JOB_DESIGNATION_REQUEST,
  JOB_DESIGNATION_GET_DETAILS,
  EDIT_JOB_DESIGNATION_REQUEST,
  DELETE_JOB_DESIGNATION_REQUEST
} from '../../constants/redux';
import { RESPONSE_STATUS_CODES } from '../../constants/common';

// Redux-Actions
import {
  addJobDesignationData,
  editJobDesignationDetails,
  deleteDesignation
} from '../slices/designation';
import { apiSuccess } from '../slices/apiStatus';

function* addJobDesignationSaga(action) {
  try {
    const response = yield call(addJobDesignationApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.OK) {
      toast.success('Designation Added Succesfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteDesignationData(action) {
  try {
    const response = yield call(deleteDesignationApi, action.payload);
    if (response?.status) {
      yield put(deleteDesignation(action.payload));
      toast.success('Designation Deleted Succesfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* editDesignationData(action) {
  try {
    const response = yield call(editDesignationApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.SUCCESS) {
      toast.success('Detail have been Updated Successfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

function* jobDesignationListSaga(action) {
  try {
    const response = yield call(jobDesignationlistApi, {
      ...action.payload
    });
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      const data = result.jobRole;
      const pagination = result.pagination;
      yield put(addJobDesignationData({ data, pagination }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getJobDesignationByIdSaga(action) {
  const ID = action.payload;
  const response = yield call(getJobDesignationByIdApi, ID);
  if (response.status === RESPONSE_STATUS_CODES.OK) {
    const designationDetails = response.data.data;
    yield put(editJobDesignationDetails(designationDetails));
    yield put(apiSuccess());
  }
}

export default function* watcherDesignationsSaga() {
  yield takeEvery(JOB_DESIGNATION_REQUEST, jobDesignationListSaga);
  yield takeEvery(ADD_JOB_DESIGNATION_REQUEST, addJobDesignationSaga);
  yield takeEvery(DELETE_JOB_DESIGNATION_REQUEST, deleteDesignationData);
  yield takeEvery(EDIT_JOB_DESIGNATION_REQUEST, editDesignationData);
  yield takeEvery(JOB_DESIGNATION_GET_DETAILS, getJobDesignationByIdSaga);
}

import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';

// Api-Services
import {
  addJobDescriptionApi,
  deleteDescriptionApi,
  editDescriptionApi,
  getJobDescriptionByIdApi,
  jobDescriptionlistApi,
  sendJobDescriptionApi
} from '../apis/jobDescriptions';

// Constants
import { RESPONSE_STATUS_CODES } from '../../constants/common';

// Redux-Actions
import {
  ADD_JOB_DESCRIPTION_REQUEST,
  DELETE_JOB_DESCRIPTION_REQUEST,
  EDIT_JOB_DESCRIPTION_REQUEST,
  JOB_DESCRIPTIONS_REQUEST,
  JOB_DESCRIPTION_GET_DETAILS,
  SEND_JOB_DESCRIPTION
} from '../../constants/redux';
import {
  addJobDescriptionData,
  deleteDescription,
  editJobDescriptionDetails
} from '../slices/jobDescriptions';
import { apiSuccess } from '../slices/apiStatus';

function* addJobDescriptionSaga(action) {
  try {
    const response = yield call(addJobDescriptionApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.OK) {
      toast.success('Job Description Added Succesfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteDescriptionData(action) {
  try {
    const response = yield call(deleteDescriptionApi, action.payload);
    if (response?.status) {
      yield put(deleteDescription(action.payload));
      toast.success('Job Description Deleted Succesfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* editDescriptionData(action) {
  try {
    const response = yield call(editDescriptionApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.SUCCESS) {
      toast.success('Detail have been Updated Successfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

function* jobDescriptionListSaga(action) {
  try {
    const response = yield call(jobDescriptionlistApi, {
      ...action.payload
    });
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      const data = result.jobDescriptions;
      const pagination = result.pagination;
      yield put(addJobDescriptionData({ data, pagination }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getJobDescriptionByIdSaga(action) {
  const ID = action.payload;
  const response = yield call(getJobDescriptionByIdApi, ID);
  if (response.status === RESPONSE_STATUS_CODES.OK) {
    const descriptionDetails = response.data.data;
    yield put(editJobDescriptionDetails(descriptionDetails));
    yield put(apiSuccess());
  }
}

function* sendJobDescriptionSaga(action) {
  const response = yield call(sendJobDescriptionApi, action.payload);
  if (response.status === RESPONSE_STATUS_CODES.OK) {
    toast.success(response?.data?.message || 'Job description sent successfully', {
      position: toast.POSITION.BOTTOM_RIGHT
    });
    yield put(apiSuccess());
  }
}

export default function* watcherDescriptionsSaga() {
  yield takeEvery(JOB_DESCRIPTIONS_REQUEST, jobDescriptionListSaga);
  yield takeEvery(ADD_JOB_DESCRIPTION_REQUEST, addJobDescriptionSaga);
  yield takeEvery(DELETE_JOB_DESCRIPTION_REQUEST, deleteDescriptionData);
  yield takeEvery(EDIT_JOB_DESCRIPTION_REQUEST, editDescriptionData);
  yield takeEvery(JOB_DESCRIPTION_GET_DETAILS, getJobDescriptionByIdSaga);
  yield takeEvery(SEND_JOB_DESCRIPTION, sendJobDescriptionSaga);
}

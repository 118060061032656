import React from 'react';
import { CustomeTooltip } from './ActionButtons';

const AdminCard = ({ userType, className, data, inactiveUserIC, activeUserIC }) => {
  return (
    <div className={`dashboard-card-item half-width ${className && className}`}>
      <div className="white-box align-center">
        <div className="card-content full-width">
          <div className="card-title-block secondary-title">
            <p className="number">{data[0]?.value || 0}</p>
            <p className="card-title">{userType}</p>
          </div>
          <div className="status-block">
            <div className="user-status">
              <CustomeTooltip></CustomeTooltip>
              <p data-tip="Active" className="status-title active-title">
                <i>
                  <img src={activeUserIC} />
                </i>
              </p>
              <p className="number">{data[1]?.value}</p>
            </div>
            <div className="user-status inactive-users">
              <CustomeTooltip></CustomeTooltip>
              <p data-tip="In Active" className="status-title inactive-title">
                <i>
                  <img src={inactiveUserIC} />
                </i>
              </p>

              <p className="number">{data[2]?.value}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCard;

import {
  JOB_DESIGNATION_REQUEST,
  ADD_JOB_DESIGNATION_REQUEST,
  EDIT_JOB_DESIGNATION_REQUEST,
  JOB_DESIGNATION_GET_DETAILS,
  DELETE_JOB_DESIGNATION_REQUEST
} from '../../constants/redux';

export const getJobDesignationList = (payload) => {
  return {
    type: JOB_DESIGNATION_REQUEST,
    payload
  };
};

export const addJobDesignation = (designation) => {
  return {
    type: ADD_JOB_DESIGNATION_REQUEST,
    payload: designation
  };
};

export const deleteDesignation = (ID) => {
  return {
    type: DELETE_JOB_DESIGNATION_REQUEST,
    payload: ID
  };
};

export const editJobDesignation = (Details) => {
  return {
    type: EDIT_JOB_DESIGNATION_REQUEST,
    payload: Details
  };
};

export const getJobDesignationById = (ID) => {
  return {
    type: JOB_DESIGNATION_GET_DETAILS,
    payload: ID
  };
};

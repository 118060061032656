import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

// Components
import CustomDatePicker from '../../components/CustomDatePicker';
import Label from '../../components/Label';
import CustomButton from '../../components/CustomButton';

// date-fns
import addMinutes from 'date-fns/addMinutes';

// Prime-React Components
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { classNames } from 'primereact/utils';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Sidebar } from 'primereact/sidebar';

// Constants
import {
  INTERVIEW_TYPE,
  BUTTON_TYPES,
  LABEL_TYPES,
  TEXTAREA_LIMIT,
  STATUS,
  INTERVIEW_MODE,
  STATUS_INTERVIEW,
  InterviewMode,
  InterviewType,
  queryParamsOfFeedbackParams
} from '../../constants/common';
import { NUMBER_OF_INTERVIEWERS } from '../../constants/dropdownOptions';

// Redux-Actions
import { allInterviewers } from '../../redux/actions/showOptions';
import {
  addInterviewRoundAction,
  editInterviewRoundAction,
  getInterviewDetails
} from '../../redux/actions/interviews';
import { getFilterCommonParamsList } from '../../redux/actions/commonParameter';

// Utils
import { compareArrays } from '../../utils/array';
import {
  checkIsOnlyFeedbackUpdate,
  filterFeedbackParams,
  formatAvailabilityTime,
  wordCapitalize
} from '../../utils/common';

// Redux-slices
import { updateData } from '../../redux/slices/userDetails';
import { apiStatusClear } from '../../redux/slices/apiStatus';

// Components
import AvailabilityDetails from '../../components/popup/AvailabilityDetails';
import { AvailabilityButton } from '../../components/ActionButtons';
import { TextAreaWithCounterLimit } from '../../components/TextAreaWithCounterLimit';

const AddEditInterviewRound = (props) => {
  const { onHide, show, interviewRoundId, technologies } = props;
  const defaultDate = moment().add(30, 'minutes').toDate();

  // React useRef hook.
  const selectedInterviewer = useRef([]);

  // React router dom hooks.
  const id = useParams()?.ID;
  const interviewRoundAddId = useParams()?.addingID;
  const { search } = useLocation();
  const ID = search.split('=')[1];

  // Redux hooks.
  const dispatch = useDispatch();
  const { InterviewDetails } = useSelector((state) => state.interviews);
  const { options } = useSelector((state) => state);
  const { filterParameters } = useSelector((state) => state.parameter);
  const { isLoading, isSucceed, isFailed } = useSelector((state) => state.apiStatus);

  // React useState hooks.
  const [startDate, setStartDate] = useState(defaultDate);
  const [interviewerOptions, setInterviewerOptions] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isUpdatedSuccess, setIsUpdatedSuccess] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [feedbackParamsOptions, setFeedbackParamsOptions] = useState([]);
  const [interviewRoundDetailsBackup, setInterviewRoundDetailsBackup] = useState(null);
  const [interviewData, setInterviewData] = useState({
    interviewerID: [],
    feedbackParameterIds: [],
    interviewType: '',
    interviewRoundMode: InterviewMode['Tatvasoft Office'],
    interviewStartDateTime: new Date(),
    interviewerLimit: NUMBER_OF_INTERVIEWERS[0],
    duration: 60,
    description: '',
    isRescheduleEnabled: false,
    isHrRound: true
  });

  const onTogglePopup = () => setShowPopup((prevState) => !prevState);

  useEffect(() => {
    if (
      interviewRoundId &&
      InterviewDetails &&
      InterviewDetails?.RoundOfInterview &&
      Object.keys(InterviewDetails).length !== 0
    ) {
      const { RoundOfInterview } = InterviewDetails;

      const interviewRoundDetails = RoundOfInterview.filter((interviewRound) => {
        if (interviewRound.ID === interviewRoundId) {
          return true;
        }
      })[0];

      if (interviewRoundDetails) {
        const {
          InterviewRoundInvitations,
          InterviewRound,
          InterviewRoundMode,
          StartDateOfInterviewRound,
          InterviewerLimit,
          Description
        } = interviewRoundDetails;
        setInterviewRoundDetailsBackup(interviewRoundDetails);
        const interviewersIDs = InterviewRoundInvitations.filter((interviewerDetails) => {
          if (
            interviewerDetails?.Status === STATUS.ACCEPT ||
            interviewerDetails?.Status === STATUS.PENDING
          )
            return true;
        }).map((interviewerDetail) => {
          return interviewerDetail?.Invited?.ID;
        });
        const selectedFeedbackParams = interviewRoundDetails?.IRMap?.map((param) => {
          return param?.ParentParameterID ? param?.ParentParameterID : param?.MapFp?.ID;
        });
        selectedInterviewer.current.value = interviewersIDs || [];
        setInterviewData({
          ...interviewData,
          isHrRound: false,
          interviewerID: interviewersIDs || [],
          feedbackParameterIds: [...new Set(selectedFeedbackParams)],
          interviewType: InterviewRound,
          interviewRoundMode: InterviewRoundMode,
          interviewStartDateTime: new Date(StartDateOfInterviewRound),
          interviewerLimit: InterviewerLimit.toString(),
          description: Description
        });
        // if time exist along with the interviewRoundId change the defaultDate value.
        const startDateSetter = moment(StartDateOfInterviewRound).isValid()
          ? moment(StartDateOfInterviewRound).toDate()
          : moment().toDate();
        setStartDate(startDateSetter);
      }
    }
  }, [InterviewDetails, interviewRoundId]);

  const interviewersOptions = (options) => {
    if (!options?.length) return [];
    return options?.map((opt) => {
      return {
        ...opt,
        name: `${opt.FirstName} ${opt.LastName}`,
        code: `${opt.ID}`,
        key: `${opt.ID}`,
        disabled: !opt.IsAvailable,
        isAvailable: opt.IsAvailable
      };
    });
  };

  useEffect(() => {
    const modifiedInterviewerOptions = interviewersOptions(options);
    if (InterviewDetails?.RoundOfInterview?.length) {
      const interviewRound = InterviewDetails?.RoundOfInterview?.filter(
        (round) => round.ID === interviewRoundId
      );
      if (interviewRound?.length && interviewRound?.[0]?.InterviewRoundInvitations?.length) {
        interviewRound?.[0]?.InterviewRoundInvitations.filter((interviewerDetails) => {
          if (
            interviewerDetails?.Status === STATUS.ACCEPT ||
            interviewerDetails?.Status === STATUS.PENDING
          )
            return true;
        }).forEach((interviewer) => {
          const interviewerData = interviewer?.Invited;
          let selectedInterviewer = formik.values.interviewerID;
          if (selectedInterviewer.indexOf(interviewerData.ID) === -1) {
            selectedInterviewer.push(interviewerData.ID);
            formik.setFieldValue('interviewerID', selectedInterviewer);
          }
          if (
            [
              STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_SCHEDULE,
              STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_SCHEDULE,
              STATUS_INTERVIEW.MANAGEMENT_SCHEDULE,
              STATUS_INTERVIEW.PRACTICAL_SCHEDULE,
              STATUS_INTERVIEW.HR_SCHEDULE
            ].indexOf(InterviewDetails.Status) !== -1 &&
            !modifiedInterviewerOptions.some((option) => option.code === interviewer.InterviewerID)
          ) {
            modifiedInterviewerOptions.push({
              name: `${interviewerData.FirstName} ${interviewerData.LastName}`,
              code: `${interviewerData.ID}`,
              ID: `${interviewerData.ID}`,
              key: `${interviewerData.ID}`,
              disabled: interviewerData?.UserStatus === 2
            });
          }
        });
      }
    }
    setInterviewerOptions(modifiedInterviewerOptions);
  }, [options]);

  const interviewerTemplate = (option) => {
    return (
      <>
        <ReactTooltip />
        <div data-tip={`${formatAvailabilityTime(option.InterviewerToAvailability)}`}>
          {!option.isAvailable ? (
            <span className="unavailable-interviewer-text-color mr-1">
              {wordCapitalize(option.name)}
            </span>
          ) : (
            <span className="mr-1">{wordCapitalize(option.name)}</span>
          )}
          {!!option?.UMap?.length && (
            <span className={`${!option.isAvailable && 'unavailable-interviewer-text-color'} mr-1`}>
              [{option?.UMap?.map((tech) => wordCapitalize(tech?.MapT?.Technology)).join(', ')}]
            </span>
          )}
          {!option.isAvailable && (
            <>
              {formatAvailabilityTime(option.InterviewerToAvailability) && (
                <span className="unavailable-interviewer-text-color">{`[${formatAvailabilityTime(
                  option.InterviewerToAvailability
                )}]`}</span>
              )}
              <i
                className="pi pi-exclamation-triangle unavailable-interviewer-text-color"
                data-tip="Might not be available for the above schedule selected"
              />
            </>
          )}
        </div>
      </>
    );
  };

  useEffect(() => {
    if (interviewRoundId && selectedInterviewer.current.value) {
      const interviewRoundStartDate = new Date(
        InterviewDetails?.RoundOfInterview?.filter((interviewRound) => {
          if (interviewRound?.ID === interviewRoundId) return true;
        })[0]?.StartDateOfInterviewRound?.toString()
      );
      const interviewRoundStatus = InterviewDetails?.RoundOfInterview?.filter((interviewRound) => {
        if (interviewRound?.ID === interviewRoundId) return true;
      })[0]?.Status;
      if (
        (interviewRoundStatus === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_SCHEDULE ||
          interviewRoundStatus === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_SCHEDULE ||
          interviewRoundStatus === STATUS_INTERVIEW.PRACTICAL_SCHEDULE ||
          interviewRoundStatus === STATUS_INTERVIEW.HR_SCHEDULE ||
          interviewRoundStatus === STATUS_INTERVIEW.MANAGEMENT_SCHEDULE) &&
        ((startDate && !moment(startDate).isSame(moment(interviewRoundStartDate))) ||
          compareArrays(selectedInterviewer.current.value, interviewData.interviewerID))
      ) {
        formik.setFieldValue('isRescheduleEnabled', true);
      } else {
        formik.setFieldValue('isRescheduleEnabled', false);
      }
    }
  }, [
    interviewRoundId,
    startDate,
    interviewData.interviewStartDateTime,
    selectedInterviewer.current.value,
    interviewData.interviewerID
  ]);

  const onDateChange = (value) => {
    setStartDate(value);
  };

  useEffect(() => {
    if (show && isUpdatedSuccess && isSucceed) {
      onHide(false);
      document.body.classList.remove('sidebar-open');
      formik.resetForm();
      dispatch(updateData());
      dispatch(apiStatusClear());
      setInterviewData({
        interviewerID: [],
        feedbackParameterIds: [],
        interviewType: '',
        interviewRoundMode: InterviewMode['Tatvasoft Office'],
        interviewStartDateTime: new Date(),
        interviewerLimit: NUMBER_OF_INTERVIEWERS[0],
        duration: 60,
        description: '',
        isRescheduleEnabled: false,
        isHrRound: true
      });
      setIsUpdatedSuccess(false);
    }
    if (isFailed) {
      setIsUpdatedSuccess(false);
    }
  }, [isFailed, isSucceed]);

  useEffect(() => () => setIsUpdatedSuccess(false), []);

  const formik = useFormik({
    initialValues: interviewData,
    validationSchema: Yup.object({
      isRescheduleEnabled: Yup.boolean(),
      isHrRound: Yup.boolean(),
      interviewType: Yup.string().trim().required('Interview Type is required.'),
      interviewRoundMode: Yup.string().trim().typeError('Please select interview round mode'),
      interviewerID: Yup.array()
        .min(1, 'Interviewer is required.')
        .required('Interviewer is required.'),
      feedbackParameterIds: Yup.array().when('interviewType', {
        is: (interviewType) =>
          interviewType === InterviewType.Practical.toString() ||
          interviewType === InterviewType['HR Round'].toString(),
        then: Yup.array(),
        otherwise: Yup.array()
          .min(1, 'Feedback Parameter is required.')
          .required('Feedback Parameter is required.')
      }),
      interviewerLimit: Yup.number()
        .min(1, 'Minimum Limit 1')
        .max(10, 'Maximum  Limit 10')
        .typeError('Only numbers are allowed.')
        .required('Interviewer Limit is required.'),
      description: Yup.string()
        .trim()
        .min(TEXTAREA_LIMIT.MIN, 'Description should be at least ${min} characters.')
        .max(TEXTAREA_LIMIT.MAX, 'Description should not exceed ${max} characters.')
        .nullable()
    }),
    onSubmit: (values) => {
      try {
        let selectedFeedbackParameterIds = filterFeedbackParams(
          values.feedbackParameterIds,
          feedbackParamsOptions
        );

        let formData = {
          interviewStartDateTime: startDate,
          interviewEndDateTime: addMinutes(startDate, values.duration),
          interviewerLimit: values.interviewerLimit,
          description: values.description,
          ...(values.interviewerID?.length && {
            interviewerID: values.interviewerID?.length ? values.interviewerID.join(',') : ''
          }),
          ...(selectedFeedbackParameterIds && {
            feedbackParameters: selectedFeedbackParameterIds
          }),
          interviewRound: values.interviewType,
          interviewRoundMode: values.interviewRoundMode
        };
        if (interviewRoundId) {
          formData = {
            interviewStartDateTime: startDate,
            interviewEndDateTime: addMinutes(startDate, values.duration),
            interviewerLimit: values.interviewerLimit,
            description: values.description?.trim(),
            interviewerID: values.interviewerID.join(','),
            ...(selectedFeedbackParameterIds && {
              feedbackParameters: selectedFeedbackParameterIds
            }),
            interviewType: values.interviewType,
            isOnlyFeedbackChange: checkIsOnlyFeedbackUpdate(
              interviewRoundDetailsBackup,
              values,
              startDate
            ),
            interviewRoundMode: values.interviewRoundMode
          };
        }

        let formdataJson = JSON.stringify(formData);

        if (interviewRoundId) {
          dispatch(
            editInterviewRoundAction({
              formdataJson,
              interviewRoundID: interviewRoundId
            })
          );
        } else {
          dispatch(addInterviewRoundAction({ interviewID: id, formdataJson }));
        }
      } catch (error) {
        console.log(error);
      }
      setIsUpdatedSuccess(true);
    },
    enableReinitialize: true
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  useEffect(() => {
    if (!show) {
      setStartDate(defaultDate);
    }
  }, [show]);

  // React useEffect hooks.
  useEffect(() => {
    interviewRoundId && !startDate && dispatch(getInterviewDetails(id));
    interviewRoundAddId && dispatch(getInterviewDetails(interviewRoundAddId));
    if (startDate) {
      const endDate = addMinutes(startDate, interviewData.duration);
      const interviewStartTime = moment(startDate).format();
      const interviewEndTime = moment(endDate).format();
      startDate &&
        dispatch(
          allInterviewers(
            interviewStartTime,
            interviewEndTime,
            technologies,
            interviewRoundId || '',
            formik.values.interviewType === InterviewType['HR Round'],
            formik.values.interviewType === InterviewType.Practical
          )
        );
    }
  }, [dispatch, interviewRoundId, startDate, formik.values.interviewType, interviewRoundAddId]);

  // React useEffect hooks.
  useEffect(() => {
    if (startDate) {
      formik.setFieldValue('interviewerID', []);
    }
  }, [formik.values.interviewType, startDate]);

  const feedbackParamsOptionsGenerate = (options) => {
    if (!options?.length) return [];

    const committedSingleParams = {
      single: new Set(),
      group: new Set()
    };
    const selectedParameterIds = new Set(formik.values.feedbackParameterIds);

    // Collect committed single parameters
    options.forEach((opt) => {
      if (selectedParameterIds.has(opt.ID)) {
        if (opt.GroupParameters?.length) {
          opt.GroupParameters.forEach((child) =>
            committedSingleParams.group.add(child.Parameter.ID)
          );
        } else {
          committedSingleParams.single.add(opt.ID);
        }
      }
    });

    // Generate parameter options
    const paramsOptions = options.map((opt) => ({
      name: opt.ParameterName,
      code: opt.ID,
      key: opt.ID,
      childParams:
        opt.GroupParameters?.map((child) => ({
          id: child.Parameter.ID || '',
          parameterName: child.Parameter.ParameterName || ''
        })) || [],
      disabled: isOptionDisabled(opt, selectedParameterIds, committedSingleParams)
    }));

    return paramsOptions;
  };

  // Helper function to determine if an option is disabled
  const isOptionDisabled = (opt, selectedParameterIds, committedSingleParams) => {
    if (selectedParameterIds.has(opt.ID)) {
      if (opt.GroupParameters?.length) {
        opt.GroupParameters.forEach((child) => {
          if (selectedParameterIds.has(child.Parameter.ID)) {
            // Remove the child ID from selectedParameterIds
            selectedParameterIds.delete(child.Parameter.ID);
            formik.setFieldValue('feedbackParameterIds', [...selectedParameterIds]);
          }
        });
      }
      return false;
    }

    if (opt.GroupParameters?.length) {
      return opt.GroupParameters.some((child) =>
        committedSingleParams.group.has(child.Parameter.ID)
      );
    }

    return committedSingleParams.single.has(opt.ID) || committedSingleParams.group.has(opt.ID);
  };

  useEffect(() => {
    const modifiedOptions = feedbackParamsOptionsGenerate(filterParameters || []);
    setFeedbackParamsOptions(modifiedOptions);
  }, [filterParameters, formik.values.feedbackParameterIds]);

  useEffect(() => {
    if (show) {
      dispatch(getFilterCommonParamsList(queryParamsOfFeedbackParams));
    }
  }, [show]);

  const feedBackParamsTemplate = (option) => {
    return (
      <>
        <span className="mr-1">{wordCapitalize(option.name)}</span>
        {!!option?.childParams?.length && (
          <span>
            [{option?.childParams?.map((tech) => wordCapitalize(tech?.parameterName)).join(', ')}]
          </span>
        )}
      </>
    );
  };

  return (
    <>
      <AvailabilityDetails onHide={onTogglePopup} show={showPopup} />
      <ConfirmDialog />
      <Sidebar
        ID={ID}
        visible={show}
        onHide={() => {
          onHide(true);
          document.body.classList.remove('sidebar-open');
        }}
        position="right"
        className="sidebar-drawer">
        <div className="form-box-wrapper">
          <div className="title-wrapper">
            <p className="card-title">{interviewRoundId ? 'Edit' : 'Schedule'} Interview Round</p>
            <button
              className="p-sidebar-close"
              onClick={() => {
                onHide(false);
                document.body.classList.remove('sidebar-open');
                setInterviewData({
                  interviewerID: [],
                  feedbackParameterIds: [],
                  interviewType: '',
                  interviewRoundMode: InterviewMode['Tatvasoft Office'],
                  interviewStartDateTime: new Date(),
                  interviewerLimit: NUMBER_OF_INTERVIEWERS[0],
                  duration: 60,
                  description: '',
                  isRescheduleEnabled: false,
                  isHrRound: true
                });
              }}>
              <span className="p-sidebar-close-icon pi pi-times" />
            </button>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="p-fluid"
            autoComplete="off"
            encType="multipart/form-data">
            <div className="form-row-wrapper">
              <div className="form-col full-width">
                <div className="custom-form-group">
                  <Label htmlFor="interviewType" text={LABEL_TYPES.INTERVIEW_TYPE} isMandatory />
                  <span className="p-input-icon-right">
                    <Dropdown
                      name="interviewType"
                      id="interviewType"
                      optionLabel="name"
                      optionValue="code"
                      value={formik.values.interviewType}
                      options={
                        !ID ? INTERVIEW_TYPE : INTERVIEW_TYPE.filter((type) => type.code <= 5)
                      }
                      disabled={!!interviewRoundId}
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (isFirstTime && technologies?.length) {
                          formik.setFieldValue('feedbackParameterIds', technologies);
                        } else {
                          formik.setFieldValue('feedbackParameterIds', []);
                        }
                      }}
                      placeholder="Select Interview Type *"
                      panelClassName="custom-dropdown-panel"
                      className={classNames({
                        'p-invalid': isFormFieldValid('interviewType')
                      })}
                    />
                  </span>
                  {getFormErrorMessage('interviewType')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="interviewRoundMode"
                      text={LABEL_TYPES.INTERVIEW_ROUND_MODE}
                      isMandatory
                    />
                    <Dropdown
                      name="interviewRoundMode"
                      id="interviewRoundMode"
                      optionLabel="name"
                      optionValue="code"
                      value={formik.values.interviewRoundMode}
                      options={INTERVIEW_MODE}
                      onChange={formik.handleChange}
                      placeholder="Select Interview Mode"
                      panelClassName="custom-dropdown-panel"
                      className={classNames({
                        'p-invalid': isFormFieldValid('interviewRoundMode')
                      })}
                    />
                  </div>
                  {getFormErrorMessage('interviewRoundMode')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="custom-form-group">
                  <CustomDatePicker
                    value={startDate}
                    onChange={onDateChange}
                    minDateTime={new Date().setDate(new Date().getDate())}
                    minTime={new Date().setHours(0, 0, 0)}
                    maxTime={new Date().setHours(23, 59, 59)}
                  />
                </div>
              </div>
              <div className="form-col full-width">
                <div className="custom-form-group">
                  <Label
                    htmlFor="interviewerLimit"
                    text={LABEL_TYPES.INTERVIEWER_LIMIT}
                    isChildren
                    isMandatory
                    tooltip="Sets the maximum no. of interviewers can join the interview."
                  />
                  <span className="p-input-icon-right">
                    <Dropdown
                      id="interviewerLimit"
                      name="interviewerLimit"
                      value={formik.values.interviewerLimit}
                      options={NUMBER_OF_INTERVIEWERS}
                      onChange={formik.handleChange}
                      placeholder="Select Number of Interviewers"
                      panelClassName="custom-dropdown-panel"
                    />
                  </span>
                  {getFormErrorMessage('interviewerLimit')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="custom-form-group">
                  <Label
                    htmlFor="interviewerIDs"
                    text={LABEL_TYPES.INTERVIEWERS}
                    isMandatory={formik.values.interviewType}
                  />
                  <AvailabilityButton onClick={onTogglePopup} />
                  <span className="p-input-icon-right">
                    <MultiSelect
                      filter
                      name="interviewerID"
                      id="interviewerID"
                      optionLabel="name"
                      optionValue="code"
                      value={formik.values.interviewerID}
                      onChange={(e) => {
                        formik.handleChange(e);
                        selectedInterviewer.current.value = e.target.value;
                      }}
                      options={interviewerOptions}
                      itemTemplate={interviewerTemplate}
                      placeholder="Select Interviewer"
                      display="chip"
                      className={classNames({
                        'p-invalid': isFormFieldValid('interviewerID')
                      })}
                    />
                  </span>
                  {getFormErrorMessage('interviewerID')}
                </div>
              </div>
              {(formik.values.interviewType === InterviewType['Technical Interview - 1'] ||
                formik.values.interviewType === InterviewType['Technical Interview - 2'] ||
                formik.values.interviewType === InterviewType['Management Round']) && (
                <div className="form-col full-width">
                  <div className="form-group-outer">
                    <div className="custom-form-group">
                      <Label
                        htmlFor="feedbackParameterIds"
                        text={LABEL_TYPES.FEEDBACK_PARAMETERS}
                        isMandatory
                        isBold
                      />
                      <MultiSelect
                        name="feedbackParameterIds"
                        id="feedbackParameterIds"
                        optionLabel="name"
                        optionValue="code"
                        value={formik.values.feedbackParameterIds}
                        onChange={(e) => {
                          formik.handleChange(e);
                          setIsFirstTime(false);
                        }}
                        options={feedbackParamsOptions}
                        itemTemplate={feedBackParamsTemplate}
                        placeholder="Select Feedback Params"
                        filter
                        showSelectAll={false}
                        display="chip"
                        className={classNames({
                          'p-invalid': isFormFieldValid('feedbackParameterIds')
                        })}
                      />
                    </div>
                    {getFormErrorMessage('feedbackParameterIds')}
                  </div>
                </div>
              )}
              <div className="form-col full-width">
                <div className="custom-form-group">
                  <span className="p-input-icon-right">
                    <TextAreaWithCounterLimit
                      placeholder={LABEL_TYPES.DESCRIPTION}
                      labelText={LABEL_TYPES.DESCRIPTION}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      errorMessage={
                        formik.touched['description'] ? formik.errors['description'] : ''
                      }
                      className={classNames({
                        'p-invalid': isFormFieldValid('description'),
                        'w-full': true
                      })}
                      rowsValue={1}
                      id="description"
                      name="description"
                      htmlFor="description"
                      validationOnMinLimit
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="form-btn-wrapper">
              <CustomButton
                type="submit"
                variant="contained"
                className="mb-3 w-auto"
                disabled={isLoading || formik.values.isRescheduleEnabled}>
                {BUTTON_TYPES.SAVE}
              </CustomButton>
              {interviewRoundId && (
                <CustomButton
                  type="submit"
                  variant="outlined"
                  className="mb-3 w-auto"
                  disabled={isLoading || !formik.values.isRescheduleEnabled}>
                  {BUTTON_TYPES.SAVE_RESCHEDULE}
                </CustomButton>
              )}
              <CustomButton
                variant="contained"
                onClick={() => {
                  onHide(false);
                  document.body.classList.remove('sidebar-open');
                  setInterviewData({
                    interviewerID: [],
                    interviewType: '',
                    interviewStartDateTime: new Date(),
                    interviewerLimit: NUMBER_OF_INTERVIEWERS[0],
                    duration: 60,
                    description: '',
                    isRescheduleEnabled: false,
                    isHrRound: true
                  });
                }}
                color="error"
                className="mb-3 w-auto gray-btn">
                {BUTTON_TYPES.CANCEL}
              </CustomButton>
            </div>
          </form>
        </div>
      </Sidebar>
    </>
  );
};

export default AddEditInterviewRound;

export const API_ROUTES = {
  LOGIN: '/v1/auth/login',
  FORGOT_PASSWORD: '/v1/auth/forgot-password',
  RESET_PASSWORD: '/v1/auth/reset-password',
  USER_PERMISSION: '/v1/user/permission',
  PUSH_NOTIFICATION: '/v1/messages/registration-token',
  NOTIFICATIONS: '/v1/user/notifications',
  READ_NOTIFICATIONS: '/v1/user/notification-status',
  ADMIN_DASHBOARD_COUNT: '/v1/admin/dashboard',
  ADMIN_INTERVIEW_ROUND: '/v1/admin/interview-rounds',
  ADMIN_INTERVIEW_LIST: '/v1/admin/interviewers-list',

  HRS_LIST: '/v1/admin/hrs',
  FILTERED_HRS_LIST: '/v1/hr/hr-list',
  HR_ADD: '/v1/admin/hr',
  USER_DETAILS: '/v1/admin/user',
  USER_STATUS: '/v1/admin/user-status',
  USER_PROFILE_DETAILS: '/v1/user/profile',
  USER_PASSWORD_CHANGE: '/v1/user/password',
  JOB_DESIGNATION: '/v1/user/job-designation',
  JOB_DESCRIPTIONS: '/v1/job-description',
  SEND_JOB_DESCRIPTION: '/v1/job-description/send',
  TECHNOLOGY: '/v1/user/interview-technology',
  SUB_TECHNOLOGY: '/v1/user/sub-technology',
  COMMON_TECHPARAMS: 'v1/feedback-parameter',

  INTERVIEWER_LIST: '/v1/admin/interviewers',
  INTERVIEWER_ADD: '/v1/admin/interviewer',
  INTERVIEWER_DELETE: '/v1/admin/user',
  INTERVIEWER_AVAILABILITY: '/v1/interviewer/availability',

  INTERVIEWER_INTERVIEW_DETAILS: '/v1/interviewer/interview',
  INTERVIEW_SCHEDULED: '/v1/interviewer/scheduled-interviews',
  INTERVIEW_PENDING_FEEDBACK: '/v1/interviewer/pending-feedbacks',
  INTERVIEW_INVITATIONS: '/v1/interviewer/interview-invitations',
  INTERVIEW_INVITATION_STATUS: '/v1/interviewer/interview-invitation-status',
  INTERVIEW_HISTORY: '/v1/interviewer/interview-history',
  INTERVIEW_STATUS: '/v1/interviewer/interview-status',
  INTERVIEW_FEEDBACK: '/v1/interviewer/feedback',
  INTERVIEW_RATING: '/v1/interviewer/ratings',
  INTERVIEW_TECHPARAMS: '/v1/user/techparams',

  HR_FEEDBACK_HISTORY_LOG: '/v1/hr/interview-logs',
  HR_INTERVIEW_DETAILS: '/v1/hr/interview-details',
  HR_DASHBOARD: '/v1/hr/interviews-dashboard',
  HR_AVAILABLE_SCHEDULE: '/v1/hr/available-interviewers',
  HR_JOB_DESIGNATION: '/v1/hr/designation-list',
  HR_TECHNOLOGY: '/v1/user/technology-list',
  HR_INTERVIEWER_AVAILABLITY: '/v1/hr/interviewer-availability',
  HR_SCHEDULE_INTERVIEW_COUNT: '/v1/hr/scheduled-interview-count',
  HR_INTERVIEWERS_LIST: '/v1/hr/interviewer-list',
  COMPLETE_INTERVIEW_FEEDBACK: 'v1/hr/updateFeedback',
  GET_OVERALL_INTERVIEW_FEEDBACK: 'v1/hr/getFeedback',

  HR_INTERVIEW_EDIT: 'v1/hr/interview-data',
  INTERVIEW_ROUND_ADD: '/v1/hr/interview',
  INTERVIEW_LIST: '/v1/hr/interview-details',
  INTERVIEWER_DROPDOWN: '/v1/hr/interviewers',
  INTERVIEW_ROUND_ADD_NEW: '/v1/hr/interview-round',
  CANDIDATE_LIST: '/v1/hr/candidates',
  CANDIDATE_SEARCH: '/v1/hr/candidate-list',
  CANDIDATE_DETAILS: '/v1/hr/candidate',
  USER_SECRET_KEY: '/v1/user/secretKey',
  OTHER_USER_SECRET_KEY: '/v1/user/generate-secretKey',
  CANDIDATE_EMAIL_CHECK: '/v1/user/email-check',
  CHECK_EMAIL_MOBILE: '/v1/user/validate-email-or-mobile',
  UPDATE_MOBILE_NUMBER: '/v1/admin/hr-interviewer-mobile-details',
  INTERVIEW_CANCEL: '/v1/hr/interview-cancel',
  INTERVIEW_ROUND_CANCEL: '/v1/hr/interview-round-cancel',
  REJECT_CANDIDATE_INTERVIEW_ROUND: '/v1/hr/reject-candidate/interview-round'
};

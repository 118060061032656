import api from '../../utils/axios';

//Constants
import { API_ROUTES } from '../../constants/apiRoutes';

export const jobDescriptionlistApi = async ({ pageNumber, searchValue, sortBy, orderBy, size }) => {
  try {
    const response = await api.get(
      `${API_ROUTES.JOB_DESCRIPTIONS}?page=${pageNumber}&searchName=${searchValue}&sortBy=${sortBy}&orderBy=${orderBy}&size=${size}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const addJobDescriptionApi = async (description) => {
  try {
    const response = await api.post(API_ROUTES.JOB_DESCRIPTIONS, description);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getJobDescriptionByIdApi = async (ID) => {
  try {
    const response = await api.get(`${API_ROUTES.JOB_DESCRIPTIONS}/${ID}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteDescriptionApi = async (ID) => {
  try {
    const response = await api.delete(`${API_ROUTES.JOB_DESCRIPTIONS}/${ID}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const editDescriptionApi = async (details) => {
  try {
    const response = await api.put(
      `${API_ROUTES.JOB_DESCRIPTIONS}/${details.ID}`,
      details.formdata
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const sendJobDescriptionApi = async ({ candidateId, jobDescriptionId }) => {
  try {
    const response = await api.post(API_ROUTES.SEND_JOB_DESCRIPTION, {
      candidateId,
      jobDescriptionId
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

import { ALL_INTERVIEWERS_REQUEST } from '../../constants/redux';

export const allInterviewers = (
  interviewStartTime,
  interviewEndTime,
  technologies = '',
  interviewRoundId = '',
  isHrRound = false,
  isPracticalAllow = false
) => {
  return {
    type: ALL_INTERVIEWERS_REQUEST,
    payload: {
      interviewStartTime,
      interviewEndTime,
      technologies,
      interviewRoundId,
      isHrRound,
      isPracticalAllow
    }
  };
};

import React, { useEffect, useState } from 'react';
import {
  ACTION_TYPE,
  DEBOUNCE_TIMEOUT,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  TABLE_NAME
} from '../../constants/common';
import { debounce } from '../../utils/debounce';
import { JOB_DESCRIPTION_COLUMNS } from '../../constants/tableColumns';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteDescription,
  getJobDescriptionById,
  getJobDescriptionList
} from '../../redux/actions/jobDescriptions';
import { ActionButtonWithoutView } from '../../components/ActionButtons';
import DataTable from '../../components/DataTable';
import AddEditJobDescription from './AddEditJobDescription';
import { confirmDialog } from 'primereact/confirmdialog';

const JobDescriptions = () => {
  const dispatch = useDispatch();
  const { jobDescription } = useSelector((state) => state);
  const [editJobDescriptionID, setJobDescriptionId] = useState(null);
  const [jobDescriptionsList, setJobDescriptionsList] = useState(null);
  const [tableStates, setTableStates] = useState({
    searchValue: '',
    pageNumber: INITIAL_PAGE_NUMBER,
    size: INITIAL_SIZE,
    sortBy: 'DateModified',
    orderBy: 'desc'
  });

  const [visibleRight, setVisibleRight] = useState(false);
  const onToggleSiderbar = (value) => setVisibleRight(value);

  useEffect(() => {
    dispatch(getJobDescriptionList(tableStates));
  }, [
    dispatch,
    tableStates,
    jobDescription?.isDescriptionDeleted,
    jobDescription?.isUpdated,
    jobDescription?.isAdded
  ]);

  useEffect(() => {
    const modifiedDescriptions = jobDescription?.data?.map((jd) => {
      return {
        ...jd,
        Name: jd?.Title || '',
        Subject: jd?.Subject || '',
        action: (
          <div className="flex align-items-center">
            <ActionButtonWithoutView
              onClick={(actionType, e) => handleRowActions(actionType, jd.ID, e)}
            />
          </div>
        )
      };
    });
    setJobDescriptionsList(modifiedDescriptions);
  }, [jobDescription]);

  const handlePagination = (pageNumber, size) =>
    setTableStates((prevState) => {
      return {
        ...prevState,
        pageNumber,
        size
      };
    });

  const handleSearch = (searchValue) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        searchValue
      };
    });
  };
  const handleSort = (sortBy) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        sortBy,
        orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
      };
    });
  };

  const handleRowActions = (actionType, rowId) => {
    if (actionType === ACTION_TYPE.EDIT) {
      onToggleSiderbar(true);
      setJobDescriptionId(rowId);
      dispatch(getJobDescriptionById(rowId));
    } else if (actionType === ACTION_TYPE.DELETE) {
      confirmDialog({
        header: 'Delete',
        message: 'Are you sure you want to delete?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => dispatch(deleteDescription(rowId))
      });
    }
  };

  const handleAdd = () => {
    setJobDescriptionId(null);
    onToggleSiderbar();
    setVisibleRight(true);
  };

  return (
    <>
      {visibleRight && (
        <AddEditJobDescription
          ID={editJobDescriptionID}
          onHide={onToggleSiderbar}
          show={visibleRight}
        />
      )}
      <DataTable
        name={TABLE_NAME.JOB_DESCRIPTION}
        searchPlaceHolder="Search by Job Description"
        columns={JOB_DESCRIPTION_COLUMNS}
        data={jobDescriptionsList}
        totalRecords={jobDescription?.pagination?.totalRecords}
        onPagination={handlePagination}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        onAdd={() => handleAdd()}
        onSort={handleSort}
      />
    </>
  );
};

export default JobDescriptions;

import axios from 'axios';
import { toast } from 'react-toastify';

// Redux-Slices
import { apiSuccess, apiPending, apiStatusClear, apiFail } from '../redux/slices/apiStatus';

// Utils
import { getDataFromLocalstorage } from './common';

// Constants
import {
  STORAGE_KEY,
  API_ERROR_MESSAGES,
  ROUTES,
  RESPONSE_STATUS_CODES
} from '../constants/common';
import { API_ROUTES } from '../constants/apiRoutes';

// Redux-Store
import { store } from '../redux/store';

const ignoredRoutes = [
  API_ROUTES.HR_JOB_DESIGNATION,
  API_ROUTES.HR_TECHNOLOGY,
  API_ROUTES.INTERVIEWER_DROPDOWN,
  API_ROUTES.CANDIDATE_SEARCH
];

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

axios.interceptors.request.use(
  function (config) {
    const url = config.url.split('?')[0];
    if (!ignoredRoutes.includes(url)) {
      store.dispatch(apiStatusClear());
      store.dispatch(apiPending());
    }
    if (config.url !== API_ROUTES.LOGIN) {
      const auth = getDataFromLocalstorage(STORAGE_KEY);
      config.headers.Authorization = `Bearer ${auth?.token}`;
    }
    return config;
  },
  function (error) {
    store.dispatch(apiFail());
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    store.dispatch(apiSuccess());
    return response;
  },
  function (error) {
    store.dispatch(apiFail());
    const { status, data } = error.response;
    const apiErrorMessage = data.message;
    if (
      window.location.pathname !== ROUTES.LOGIN &&
      (status === RESPONSE_STATUS_CODES.SESSION_EXPIRED ||
        status === RESPONSE_STATUS_CODES.UNAUTHORIZED ||
        status === RESPONSE_STATUS_CODES.TOKEN_EXPIRED)
    ) {
      localStorage.removeItem(STORAGE_KEY);
      window.location.replace(ROUTES.LOGIN);
    } else if (apiErrorMessage) {
      toast.error(apiErrorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else if (API_ERROR_MESSAGES[status]) {
      toast.error(API_ERROR_MESSAGES[status], {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    } else {
      toast.error(API_ERROR_MESSAGES.default, {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
    return error?.response;
  }
);

export default axios;

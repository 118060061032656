import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  pagination: {}
};

const interviewersSlice = createSlice({
  name: 'interviewers',
  initialState,
  reducers: {
    addInterviewers(state, action) {
      const { data, pagination } = action.payload;
      return { data, pagination, isDeleted: false, isUpdated: false, isAdded: false };
    },
    addAvailability(state, action) {
      const data = action.payload;
      return { data };
    },
    deleteInterviewer(state) {
      return { ...state, isDeleted: true };
    },
    updateInterviewer(state, action) {
      const interviewerId = action.payload.id;
      const interviewers = [...state.data].map((interviewer) => {
        if (interviewer.ID === interviewerId) {
          interviewer = {
            ...interviewer,
            ...action.payload
          };
        }
        return interviewer;
      });
      return { ...state, data: interviewers };
    },
    updateData(state) {
      return { ...state, isUpdated: true, isAdded: true };
    }
  }
});

export const {
  addInterviewers,
  deleteInterviewer,
  updateData,
  updateInterviewer,
  addAvailability,
  addInterviewer
} = interviewersSlice.actions;
export default interviewersSlice.reducer;

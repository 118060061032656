import React from 'react';

const Footer = () => {
  return (
    <></>
    // <div className="layout-footer">
    //   <p>Version: 22.12.12</p>
    //   <p>Copyright &copy; TatvaSoft All Rights Reserved</p>
    // </div>
  );
};

export default Footer;

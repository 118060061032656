export const compareArrays = (arrOne, arrTwo) => {
  return (
    arrOne.length !== arrTwo.length ||
    arrOne.some((item1) => !arrTwo.some((item2) => item2 === item1))
  );
};

export const technologyFilterbySubTechnology = (options) => {
  if (!options?.length) return [];

  const optionArray = options.reduce((acc, op) => {
    if (op.SubTechnologies?.length) {
      const items = op.SubTechnologies.map((sub) => ({
        name: sub.Technology,
        value: sub.ID
      }));
      acc.push({
        label: op.Technology,
        code: op.Technology,
        items
      });
    }
    return acc;
  }, []);

  return optionArray;
};

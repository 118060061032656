import {
  ADD_HR_REQUEST,
  DELETE_HR_REQUEST,
  HR_AVAILABLE_SCHEDULE_REQUEST,
  HR_EDIT_REQUEST,
  HR_INTERVIEW_SEARCH_TECHNOLOGY,
  HR_INTERVIEW_TECHNOLOGY,
  HR_JOB_DESIGNATION,
  HR_LIST_REQUEST,
  HR_SCHEDULE_INTERVIEW_REQUEST,
  HR_JOB_DESIGNATION_BY_SEARCH,
  HR_INTERVIEWER_AVAILABLITY_BY_FILTER,
  HR_INTERVIEWERERS_LIST_BY_SEARCHNAME,
  HR_SCHEDULE_INTERVIEW_COUNT
} from '../../constants/redux';

export const getHrList = (payload) => {
  return {
    type: HR_LIST_REQUEST,
    payload
  };
};

export const addHR = (hrDetails) => {
  return {
    type: ADD_HR_REQUEST,
    payload: hrDetails
  };
};

export const deleteHR = (hrID) => {
  return {
    type: DELETE_HR_REQUEST,
    payload: hrID
  };
};

export const editHR = (Details) => {
  return {
    type: HR_EDIT_REQUEST,
    payload: Details
  };
};

export const getAvailableSchedule = () => {
  return {
    type: HR_AVAILABLE_SCHEDULE_REQUEST
  };
};

export const getScheduleInterview = ({ monthName, interviewRoundStatusForDashboard }) => {
  return {
    type: HR_SCHEDULE_INTERVIEW_REQUEST,
    payload: { monthName, interviewRoundStatusForDashboard }
  };
};

export const getJobDesignation = () => {
  return {
    type: HR_JOB_DESIGNATION
  };
};

export const getInterviewTechnology = () => {
  return {
    type: HR_INTERVIEW_TECHNOLOGY
  };
};

export const getJobDesignationBySearch = (searchText) => {
  return {
    type: HR_JOB_DESIGNATION_BY_SEARCH,
    payload: searchText
  };
};

export const getInterviewTechnologyBySearch = (searchText) => {
  return {
    type: HR_INTERVIEW_SEARCH_TECHNOLOGY,
    payload: searchText
  };
};

export const getInterviewerAvailiblityByFilterAction = (payload) => {
  return {
    type: HR_INTERVIEWER_AVAILABLITY_BY_FILTER,
    payload: payload
  };
};

export const getInterviewersListBySearchNameAction = (payload) => {
  return {
    type: HR_INTERVIEWERERS_LIST_BY_SEARCHNAME,
    payload: payload
  };
};

export const getScheduleInterviewCountByHR = (interviewStartTime, interviewEndTime) => {
  return {
    type: HR_SCHEDULE_INTERVIEW_COUNT,
    payload: { interviewStartTime, interviewEndTime }
  };
};

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// Firebase
import { onMessageListener } from '../firebase/messaging';

// Constants
import { NOTIFICATION_PERMISSION_MSG, PERMISSIONS, ROUTES } from '../constants/common';

// Redux-Slices
import { getNotification, notificationPermissionAlertClosed } from '../redux/slices/notification';
import { getUserNotificationsAction } from '../redux/actions/profileDetails';

export default function Notification() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLogged } = useSelector((state) => state.auth);
  const { notificationAlertClosed } = useSelector((state) => state.notification);
  const { permissionAndToken, isMessagingInstanceExist } = useSelector(
    (state) => state.notification
  );
  const [notification, setNotification] = useState({ title: '', body: '' });
  const navigate = useNavigate();
  const options = {
    onClick: () => {
      if (notification?.data) {
        let notificationData = notification?.data;
        if (
          notificationData === 'InterviewInvitationToInterviewer' ||
          notificationData === 'InterviewRescheduledByHR'
        ) {
          navigate(ROUTES.INTERVIEWER.INTERVIEW_INVITATION, { replace: true });
        } else if (
          (notificationData === 'FeedBackAddedByInterviewer' ||
            notificationData === 'InvitationRejectedByInterviewer' ||
            notificationData === 'InvitationAcceptedByInterviewer') &&
          notification?.interviewId
        ) {
          navigate(`${ROUTES.HR.INTERVIEW_ROUND}/${notification?.interviewId}`, { replace: true });
        } else {
          navigate(ROUTES.HOME, { replace: true });
        }
      }
    },
    position: toast.POSITION.BOTTOM_RIGHT
  };

  useEffect(() => {
    if (notification?.title && notification?.body) {
      dispatch(getUserNotificationsAction());
      toast.info(notification?.body, options);
    }
  }, [notification]);

  useEffect(() => {
    if (isLogged && permissionAndToken?.permission !== PERMISSIONS.GRANTED) {
      isLogged &&
        !notificationAlertClosed &&
        permissionAndToken &&
        permissionAndToken?.permission !== PERMISSIONS.GRANTED &&
        toast.warn(NOTIFICATION_PERMISSION_MSG, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'notification-message'
        });
      dispatch(notificationPermissionAlertClosed(false));
    }
  }, [dispatch, permissionAndToken]);

  useEffect(() => {
    if (
      isLogged &&
      isMessagingInstanceExist &&
      permissionAndToken?.permission === PERMISSIONS.GRANTED &&
      permissionAndToken?.token
    ) {
      onMessageListener()
        .then((payload) => {
          setNotification({
            title: payload?.data?.title,
            body: payload?.data?.body,
            data: payload?.data.clickEvent,
            interviewId: payload?.data?.interviewId
          });
          let notificationPage = '';

          if (
            payload?.data.clickEvent === 'InterviewInvitationToInterviewer' ||
            payload?.data.clickEvent === 'InterviewRescheduledByHR'
          ) {
            notificationPage = [
              ROUTES.INTERVIEWER.INTERVIEW_INVITATION,
              ROUTES.INTERVIEWER.DASHBOARD,
              ROUTES.INTERVIEWER.INTERVIEW_SCHEDULED
            ];
          }
          if (
            (payload?.data.clickEvent === 'FeedBackAddedByInterviewer' ||
              payload?.data.clickEvent === 'InvitationRejectedByInterviewer' ||
              payload?.data.clickEvent === 'InvitationAcceptedByInterviewer') &&
            payload?.data?.interviewId
          ) {
            notificationPage = `${ROUTES.HR.INTERVIEW_ROUND}/${payload?.data?.interviewId}`;
          }

          if (notificationPage.includes(location.pathname)) {
            dispatch(getNotification(true));
          }
        })
        .catch((err) => console.log('failed: ', err));
    }
  });

  return (
    isLogged &&
    !notificationAlertClosed &&
    permissionAndToken &&
    permissionAndToken?.permission !== PERMISSIONS.GRANTED
  );
}

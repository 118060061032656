import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    getNotification(state, action) {
      return {
        ...state,
        isNotification: action.payload
      };
    },
    setPermissionAndToken(state, action) {
      return {
        ...state,
        permissionAndToken: action.payload
      };
    },
    notificationPermissionAlertClosed(state, action) {
      return {
        ...state,
        notificationAlertClosed: action.payload
      };
    },
    setUserNotifications(state, action) {
      return {
        ...state,
        ...action.payload
      };
    },
    messagingInstanceExists(state, action) {
      return {
        ...state,
        isMessagingInstanceExist: action.payload
      };
    }
  }
});

export const {
  getNotification,
  setPermissionAndToken,
  notificationPermissionAlertClosed,
  messagingInstanceExists,
  setUserNotifications
} = notificationSlice.actions;
export default notificationSlice.reducer;

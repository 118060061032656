import { format } from 'date-fns';
import moment from 'moment';

export const getCurrentFormattedDate = () => {
  const dateObj = new Date();
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const date = dateObj.getDate();
  return `${date}_${month}_${year}`;
};

// Convert 24Hours Time into 12Hours
export function formatTime(isoDate) {
  const date = new Date(isoDate);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 === 0 ? '12' : hours % 12;
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

/*
 -Param date: takes argument as a date in IST format which is to be converted.
 -Returns Date with Day Eg: Mon Sept 12 2022 16:01:40
 */
export const getDateWithDay = (date = new Date()) => {
  const dateTime = format(date, 'eee MMM dd yyyy HH:mm:ss');
  return dateTime;
};

export const getDateFromDay = (day) => {
  const d = new Date();
  const newDay = new Date(d.setDate(d.getDate() - d.getDay() + day));
  const year = newDay.getFullYear();
  const month = newDay.getMonth() + 1;
  const date = newDay.getDate();
  const response = `${year}-${month > 9 ? '' : '0'}${month}-${date > 9 ? '' : '0'}${date}`;
  return response;
};

/**
 * Returns a string representation of local timezone date Object(IST) in a format YYYY-MM-DD.
 *
 * @param {dateArg} string representation of local timezone date Object(IST)
 * @return {formattedDate} a string representation of local timezone date Object(IST) in a format YYYY-MM-DD.
 *
 */
export function getFormattedDate(dateArg) {
  const newDate = new Date(dateArg);
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let date = newDate.getDate();
  let formattedDate =
    year + '-' + (month > 9 ? '' : '0') + month + '-' + (date > 9 ? '' : '0') + date;
  return formattedDate;
}

export function getFormattedDateView(dateArg) {
  const newDate = new Date(dateArg);
  var month = newDate.toLocaleString('default', { month: 'short' });
  let year = newDate.getFullYear();
  let date = newDate.getDate();
  let formattedDate = (date > 9 ? '' : '0') + date + ' ' + month + ' ' + year;
  return formattedDate;
}

export function getDiffDuration(time) {
  const utcTime = moment(time);
  const currentTime = moment();

  // Calculate the difference
  return moment.duration(currentTime.diff(utcTime));
}

export function getUpdatedYearsAndMonths(currentYears, currentMonths, time) {
  const diffDuration = getDiffDuration(time);
  let updatedYears = time ? currentYears + diffDuration.years() : currentYears;
  let totalMonths = time ? currentMonths + diffDuration.months() : currentMonths;
  let updatedMonths = totalMonths;

  if (totalMonths >= 12) {
    updatedYears += Math.floor(totalMonths / 12);
    updatedMonths = totalMonths % 12;
  }

  return { updatedYears, updatedMonths };
}

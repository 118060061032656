import { InputTextarea } from 'primereact/inputtextarea';
import Label from './Label';
import { TEXTAREA_LIMIT } from '../constants/common';
import { Typography } from '@mui/material';
import classNames from 'classnames';

export const TextAreaWithCounterLimit = ({
  id,
  name,
  htmlFor,
  labelText,
  placeholder,
  ishidden,
  value,
  onChange,
  onBlur,
  className,
  disabled,
  autoResize,
  errorMessage,
  minLimit = TEXTAREA_LIMIT.MIN,
  maxLimit = TEXTAREA_LIMIT.MAX,
  rowsValue = 1,
  validationOnMinLimit = false,
  ...restProps
}) => {
  return (
    <>
      <Label htmlFor={htmlFor} text={labelText} ishidden={ishidden} />
      <InputTextarea
        autoResize={autoResize}
        rows={rowsValue}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className={className}
        disabled={disabled}
        {...restProps}
      />
      <div className="text-area-flex-container">
        <small className="text-area-formik-error"> {errorMessage}</small>
        <Typography paragraph align="right" className="text-area-limit-text-setter">
          <span
            className={classNames({
              'counter-limit-exceeded':
                maxLimit < value?.length ||
                (validationOnMinLimit && value?.length < minLimit && value?.length !== 0)
            })}>
            {value?.length}
          </span>
          <span className="text-area-limit-text-setter">/{maxLimit}</span>
        </Typography>
      </div>
    </>
  );
};

import {
  TECHNOLOGY_REQUEST,
  ADD_TECHNOLOGY_REQUEST,
  EDIT_TECHNOLOGY_REQUEST,
  DELETE_TECHNOLOGY_REQUEST,
  GET_TECHNOLOGY_DETAILS
} from '../../constants/redux';

export const getTechnologyList = (payload) => {
  return {
    type: TECHNOLOGY_REQUEST,
    payload
  };
};

export const addTechnology = (technology) => {
  return {
    type: ADD_TECHNOLOGY_REQUEST,
    payload: technology
  };
};

export const deleteTechnology = (ID) => {
  return {
    type: DELETE_TECHNOLOGY_REQUEST,
    payload: ID
  };
};

export const editTechnology = (Details) => {
  return {
    type: EDIT_TECHNOLOGY_REQUEST,
    payload: Details
  };
};

export const getTechnologyById = (ID) => {
  return {
    type: GET_TECHNOLOGY_DETAILS,
    payload: ID
  };
};

import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';

// Api-Services
import {
  addCandidateApi,
  addCandidateNotesApi,
  deleteCandidateApi,
  deleteCandidateNoteApi,
  editCandidateApi,
  editCandidateNoteApi,
  emailCheckApi,
  getCandidateApi,
  getCandidateDetailsApi,
  getCandidateListApi,
  getCandidateListBySearchApi,
  getCandidateNotesApi
} from '../apis/candidates';

// Constants
import {
  CANDIDATE_ADD_REQUEST,
  CANDIDATE_DELETE_REQUEST,
  CANDIDATE_LIST_REQUEST,
  CANDIDATE_GET_DETAILS,
  CANDIDATE_EDIT_REQUEST,
  CANDIDATE_REQUEST,
  CANDIDATE_SEARCH_REQUEST,
  CANDIDATE_EMAIL_CHECK_REQUEST,
  CANDIDATE_NOTE_DELETE_REQUEST,
  CANDIDATE_NOTE_EDIT_REQUEST,
  CANDIDATE_NOTE_ADD_REQUEST,
  CANDIDATE_NOTE_GET_DETAILS
} from '../../constants/redux';
import { RESPONSE_STATUS_CODES } from '../../constants/common';

// Redux-Actions
import { apiSuccess } from '../slices/apiStatus';
import {
  getCandidates,
  deleteCandidate,
  editCandidateDetails,
  getCandidateData,
  getCandidatesBySearch,
  getEmailCheck,
  editCandidateNotes,
  setIsUpdatedSuccess
} from '../slices/candidates';

function* addCandidate(action) {
  try {
    const response = yield call(addCandidateApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.CREATED) {
      yield put(setIsUpdatedSuccess(response.data.status));
      toast.success('Candidate Added Succesfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

function* emailCheckSaga(action) {
  try {
    const response = yield call(emailCheckApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      yield put(getEmailCheck(response.data.status));
      yield put(apiSuccess());
    }
    if (response.status === 400) {
      yield put(getExistCandidate(response.data.data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getCandidateList(action) {
  try {
    const response = yield call(getCandidateListApi, { ...action.payload });
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      const data = result.candidates;
      const pagination = result.pagination;
      yield put(getCandidates({ data, pagination }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getCandidateListBySearch(action) {
  const searchText = action.payload;
  try {
    const response = yield call(getCandidateListBySearchApi, searchText);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(getCandidatesBySearch(result));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getCandidate() {
  try {
    const response = yield call(getCandidateApi);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      const data = result.candidates;
      yield put(getCandidateData(data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteCandidateSaga(action) {
  try {
    const response = yield call(deleteCandidateApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      yield put(deleteCandidate(action.payload));
      toast.success('Candidate Deleted Successfully');
    }
  } catch (error) {
    console.log(error);
  }
}

function* getCandidateDetails(action) {
  const userId = action.payload;
  const response = yield call(getCandidateDetailsApi, userId);
  if (response?.status === RESPONSE_STATUS_CODES.OK) {
    const candidateDetailsData = response.data.data;
    yield put(editCandidateDetails(candidateDetailsData));
    yield put(apiSuccess());
  }
}

function* editCandidate(action) {
  try {
    const response = yield call(editCandidateApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.SUCCESS) {
      yield put(setIsUpdatedSuccess(response.status === RESPONSE_STATUS_CODES.SUCCESS));
      toast.success('Details have been Updated Successfully');
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

function* getCandidateNote(action) {
  try {
    const response = yield call(getCandidateNotesApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.OK) {
      const candidateNotes = response.data.data;
      yield put(editCandidateNotes(candidateNotes));
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

function* addCandidateNotes(action) {
  try {
    const response = yield call(addCandidateNotesApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.SUCCESS) {
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

function* editCandidateNote(action) {
  try {
    const response = yield call(editCandidateNoteApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.SUCCESS) {
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteCandidateNote(action) {
  try {
    const response = yield call(deleteCandidateNoteApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.OK) {
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* watcherCandidateSaga() {
  yield takeEvery(CANDIDATE_ADD_REQUEST, addCandidate);
  yield takeEvery(CANDIDATE_LIST_REQUEST, getCandidateList);
  yield takeEvery(CANDIDATE_SEARCH_REQUEST, getCandidateListBySearch);
  yield takeEvery(CANDIDATE_REQUEST, getCandidate);
  yield takeEvery(CANDIDATE_DELETE_REQUEST, deleteCandidateSaga);
  yield takeEvery(CANDIDATE_GET_DETAILS, getCandidateDetails);
  yield takeEvery(CANDIDATE_EDIT_REQUEST, editCandidate);
  yield takeEvery(CANDIDATE_EMAIL_CHECK_REQUEST, emailCheckSaga);
  yield takeEvery(CANDIDATE_NOTE_GET_DETAILS, getCandidateNote);
  yield takeEvery(CANDIDATE_NOTE_ADD_REQUEST, addCandidateNotes);
  yield takeEvery(CANDIDATE_NOTE_EDIT_REQUEST, editCandidateNote);
  yield takeEvery(CANDIDATE_NOTE_DELETE_REQUEST, deleteCandidateNote);
}

import React from 'react';

// Mui Components
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Label from './Label';
import { LABEL_TYPES } from '../constants/common';

const CustomDatePicker = (props) => {
  const {
    maxDateTime,
    minDateTime,
    dateLabel = LABEL_TYPES.START_DATE,
    timeLabel = LABEL_TYPES.START_TIME,
    isDateMandatory = true,
    isTimeMandatory = true,
    minTime,
    maxTime
  } = props;

  return (
    <>
      <div className="form-row-wrapper">
        <div className="form-col">
          <Label text={dateLabel} isMandatory={isDateMandatory} />
          <DatePicker
            id="date"
            dateFormat="dd/MM/yyyy"
            placeholderText="Date"
            selected={props.value}
            onChange={(value) => props.onChange(value)}
            minDate={new Date(minDateTime)}
            maxDate={new Date(maxDateTime)}
          />
        </div>
        <div className="form-col">
          <Label text={timeLabel} isMandatory={isTimeMandatory} />
          <DatePicker
            id="time"
            placeholderText="Time"
            selected={props.value}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            onChange={(value) => {
              const elements = document.getElementsByClassName('react-datepicker__time-list');
              const childValues = [];

              Array.from(elements).forEach((element) => {
                Array.from(element.children).forEach((child) => {
                  childValues.push(child.textContent);
                });
              });

              const selectedTime = moment(value).format('h:mm A') || '';
              if (selectedTime) {
                let scrollToHeight = 0;
                childValues.some((v) => {
                  if (
                    selectedTime === v ||
                    (selectedTime && v && selectedTime.toLowerCase() === v.toLowerCase())
                  ) {
                    return true; // Exit loop if selected time is found
                  } else {
                    scrollToHeight += 30; // Increment the scroll height
                  }
                });
                if (selectedTime && elements[0].clientHeight < scrollToHeight) {
                  elements[0].scrollTo(0, scrollToHeight);
                }
              }
              props.onChange(value);
            }}
            minTime={
              moment(props.value).isBefore(moment(minDateTime), 'day')
                ? maxTime // Disable if props.value's date is before minDateTime's date
                : moment(props.value).isAfter(moment(minDateTime), 'day')
                ? minTime // Enable if props.value's date is after minDateTime's date
                : new Date(minDateTime) // Set a specific time range if dates are the same
            }
            maxTime={maxTime}
          />
        </div>
      </div>
    </>
  );
};

export default CustomDatePicker;

import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    allInterviewers(state, action) {
      return action.payload;
    }
  }
});

export const { allInterviewers } = optionsSlice.actions;
export default optionsSlice.reducer;

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';

// Prime-React Components
import { Password } from 'primereact/password';

// Component
import Label from '../../components/Label';
import CustomButton from '../../components/CustomButton';

// Constants
import { BUTTON_TYPES, LABEL_TYPES } from '../../constants/common';

// Utils
import { classNames } from 'primereact/utils';

// Redux-Actions
import { changeUserPassword } from '../../redux/actions/profileDetails';
import { Sidebar } from 'primereact/sidebar';

const UserProfileUpdatePassword = (props) => {
  const { onHide, show } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false);
  const { isSucceed, isLoading } = useSelector((state) => state.apiStatus);

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      confirmPassword: ''
    },

    validationSchema: Yup.object({
      oldPassword: Yup.string().required('Old Password is required.'),
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(/^(?=.*[a-z]).*$/, 'Password must contain at least one lowercase letter.')
        .matches(/^(?=.*[A-Z]).*$/, 'Password must contain at least one uppercase letter.')
        .matches(/^(?=.*\d).*$/, 'Password must contain at least one digit.')
        .matches(
          /^(?=.*[!@#$%^&*()\-_=+{};:,<.>/?]).*$/,
          'Password must contain at least one special character.'
        )
        .matches(/^\S*$/, 'Password cannot contain spaces.'),
      confirmPassword: Yup.string()
        .required('Confirm Password is required.')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
    }),

    onSubmit: () => {
      try {
        dispatch(changeUserPassword(formik.values));
        setStatus(true);
      } catch (error) {
        console.log(error);
      }
    }
  });

  useEffect(() => {
    if (isSucceed && status) {
      onHide(false);
    }
  }, [isSucceed, status, navigate, dispatch]);

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  return (
    <>
      <Sidebar
        visible={show}
        onHide={() => onHide(false)}
        position="right"
        className="sidebar-drawer">
        <div className="form-box-wrapper">
          <div className="title-wrapper">
            <p className="card-title">Change Password</p>
            <button className="p-sidebar-close" onClick={() => onHide(false)}>
              <span className="p-sidebar-close-icon pi pi-times" />
            </button>
          </div>
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="form-row-wrapper form-row-wrap">
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="oldPassword" text={LABEL_TYPES.OLD_PASSWORD} isMandatory />
                    <Password
                      id="oldPassword"
                      name="oldPassword"
                      placeholder="Old Password *"
                      value={formik.values.oldPassword}
                      onChange={formik.handleChange}
                      toggleMask
                      className={classNames({ 'p-invalid': isFormFieldValid('oldPassword') })}
                      feedback={false}
                    />
                  </div>
                  {getFormErrorMessage('oldPassword')}
                </div>
              </div>

              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="newPassword" text={LABEL_TYPES.NEW_PASSWORD} isMandatory />
                    <Password
                      id="password"
                      name="password"
                      placeholder="New Password *"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      toggleMask
                      className={classNames({ 'p-invalid': isFormFieldValid('password') })}
                      feedback={false}
                    />
                  </div>
                  {getFormErrorMessage('password')}
                </div>
              </div>

              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="confirmPassword *"
                      text={LABEL_TYPES.CONFIRM_PASSWORD}
                      isMandatory
                    />
                    <Password
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      toggleMask
                      className={classNames({ 'p-invalid': isFormFieldValid('confirmPassword') })}
                      feedback={false}
                    />
                  </div>
                  {getFormErrorMessage('confirmPassword')}
                </div>
              </div>
            </div>
            <div className="form-btn-wrapper">
              <CustomButton
                type="submit"
                variant="contained"
                className="ims-blue-btn"
                disabled={isLoading}>
                {BUTTON_TYPES.UPDATE_PASSWORD}
              </CustomButton>
              <CustomButton
                variant="contained"
                onClick={() => onHide(false)}
                color="error"
                className="gray-btn border-btn">
                {BUTTON_TYPES.CANCEL}
              </CustomButton>
            </div>
          </form>
        </div>
      </Sidebar>
    </>
  );
};

export default UserProfileUpdatePassword;

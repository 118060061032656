import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import Label from './Label';
import { LABEL_TYPES } from '../constants/common';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { cancelInterviewAction, cancelInterviewRoundAction } from '../redux/actions/interviews';
import { useNavigate } from 'react-router-dom';

const ConfirmDialogWithReason = (props) => {
  const dispatch = useDispatch();
  const {
    dialogHeader,
    interviewId,
    bodyTitle,
    isReasonRequired,
    displayConfirmAction,
    setInterviewId,
    handleSubmit,
    placeholder,
    fieldValidation,
    isWholeInterview = false,
    ishandleSuccess = false
  } = props;
  const navigate = useNavigate();
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };
  const initialValues = {
    cancelReason: '',
    isReasonRequired: !!isReasonRequired
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      cancelReason: Yup.string().when('isReasonRequired', {
        is: true,
        then:
          fieldValidation ||
          Yup.string()
            .required(`${LABEL_TYPES.CANCEL_REASON} is required.`)
            .min(5, 'Must be 5 characters or more')
            .max(255, 'Must be 255 characters or less'),
        otherwise: Yup.string()
          .min(5, 'Must be 5 characters or more')
          .max(255, 'Must be 255 characters or less')
      })
    }),
    onSubmit: (values, action) => {
      const dataObj = {
        cancelReason: values.cancelReason,
        interviewId
      };
      // Cancel out the parent main interview
      if (isWholeInterview) {
        dispatch(cancelInterviewAction(dataObj));
      } else if (ishandleSuccess) {
        props.onSuccess(dataObj);
      } else {
        // Cancel the interview round
        dispatch(cancelInterviewRoundAction(dataObj));
        navigate('/hr/interviews');
      }
      action.resetForm();
      setInterviewId(null);
    }
  });

  const handleCancel = () => {
    formik.resetForm();
    setInterviewId(null);
  };

  const dialogBody = (
    <>
      <div className="confirm-title">
        <i className="pi pi-fw pi-exclamation-triangle"></i>
        {bodyTitle}
      </div>
      <div className="form-group-outer">
        <div className="custom-form-group">
          <Label htmlFor="cancelReason" text={LABEL_TYPES.CANCEL_REASON} ishidden />
          <InputText
            id="cancelReason"
            name="cancelReason"
            value={formik.values.cancelReason}
            placeholder={placeholder || LABEL_TYPES.CANCEL_REASON}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={classNames({
              'p-invalid': isFormFieldValid('firstName'),
              'w-full': true
            })}
          />
        </div>
        {getFormErrorMessage('cancelReason')}
      </div>
    </>
  );

  const dialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => handleCancel()}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        disabled={formik.values.cancelReason ? !!formik.errors.cancelReason : isReasonRequired}
        onClick={() => {
          if (typeof handleSubmit === 'function') {
            handleSubmit(formik.values);
          } else {
            formik.submitForm();
          }
        }}
        autoFocus
      />
    </>
  );

  return (
    <Dialog
      className="confirm-dialog-wrapper"
      visible={displayConfirmAction}
      onHide={() => {
        handleCancel();
      }}
      header={dialogHeader}
      footer={dialogFooter}>
      {dialogBody}
    </Dialog>
  );
};

export default ConfirmDialogWithReason;

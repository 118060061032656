import api from '../../utils/axios';

// Constants
import { API_ROUTES } from '../../constants/apiRoutes';

export const addCandidateApi = async (candidateDetails) => {
  try {
    const response = await api.post(API_ROUTES.CANDIDATE_DETAILS, candidateDetails);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const emailCheckApi = async ({ firstName, email }) => {
  try {
    const response = await api.post(API_ROUTES.CANDIDATE_EMAIL_CHECK, {
      email,
      firstName
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getCandidateListApi = async ({
  pageNumber,
  searchValue,
  sortBy,
  orderBy,
  size,
  filterByHR,
  technology,
  minExp,
  maxExp
}) => {
  try {
    const response = await api.get(
      `${API_ROUTES.CANDIDATE_LIST}?page=${pageNumber}&searchName=${searchValue}&technologies=${technology}&sortBy=${sortBy}&orderBy=${orderBy}&size=${size}&filterByHR=${filterByHR}&minExp=${minExp}&maxExp=${maxExp}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getCandidateListBySearchApi = async (searchText) => {
  try {
    const response = await api.get(`${API_ROUTES.CANDIDATE_SEARCH}?searchName=${searchText}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getCandidateApi = async () => {
  try {
    const response = await api.get(API_ROUTES.CANDIDATE_LIST);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const deleteCandidateApi = async (candidateId) => {
  try {
    const response = await api.delete(`${API_ROUTES.CANDIDATE_DETAILS}/${candidateId}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getCandidateDetailsApi = async (candidateId) => {
  try {
    const response = await api.get(`${API_ROUTES.CANDIDATE_DETAILS}/${candidateId}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const editCandidateApi = async (candidateDetails) => {
  try {
    const response = await api.put(
      `${API_ROUTES.CANDIDATE_DETAILS}/${candidateDetails.CandidateId}`,
      candidateDetails.postData
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getCandidateNotesApi = async (candidateId) => {
  try {
    const response = await api.get(`${API_ROUTES.CANDIDATE_LIST}/${candidateId}/notes`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const addCandidateNotesApi = async ({ candidateId, notes }) => {
  try {
    const response = await api.post(`${API_ROUTES.CANDIDATE_LIST}/${candidateId}/notes`, notes);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const editCandidateNoteApi = async ({ noteId, notes }) => {
  try {
    const response = await api.put(`${API_ROUTES.CANDIDATE_LIST}/notes/${noteId}`, notes);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteCandidateNoteApi = async (noteId) => {
  try {
    const response = await api.delete(`${API_ROUTES.CANDIDATE_LIST}/notes/${noteId}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isSucceed: false,
  isFailed: false,
  error: ''
};

const apiStatusSlice = createSlice({
  name: 'apiStatus',
  initialState: initialState,
  reducers: {
    apiPending: (state) => {
      state.isLoading = true;
    },
    apiSuccess: (state) => {
      state.isLoading = false;
      state.isFailed = false;
      state.isSucceed = true;
      state.error = '';
    },
    apiFail: (state, { payload }) => {
      state.isLoading = false;
      state.isSucceed = false;
      state.isFailed = true;
      state.error = payload;
    },
    apiStatusClear: (state) => {
      state.isLoading = false;
      state.isSucceed = false;
      state.isFailed = false;
      state.error = '';
    }
  }
});
export const { apiPending, apiSuccess, apiFail, apiLoading, apiStatusClear } =
  apiStatusSlice.actions;
export default apiStatusSlice.reducer;

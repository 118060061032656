import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EqualHeight } from 'react-equal-height';

// Redux-Actions
import { getAdminDashboardCount, getInterviewerRound } from '../redux/actions/admin';
import { getScheduleInterviewCountByHR } from '../redux/actions/hr';

// Images
import interviewIc from '@assets/images/inteview.svg';
import interviewIcDark from '@assets/images/interview-dark.svg';
import candidateIc from '@assets/images/candidates-ic.svg';
import activeUserIC from '@assets/images/active-user.svg';
import inactiveUserIC from '@assets/images/inactive-user.svg';

// Prime-React Components
import DoughnutChart from '../components/DoughnutChart';
import PieChart from '../components/PieChart';
// import UpcommingDoughnutChart from '../components/UpcommingDoughnutChart';
import AdminCard from '../components/AdminCard';
import HrCard from '../components/HrCard';
import AvailableInterviewersBarChart from '../components/AvailableInterviewersBarChart';

// Constants
import { STATUS_INTERVIEW, USER_TYPE_TEXT } from '../constants/common';
import { getDateWithDay } from '../utils/date';
import moment from 'moment';

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const { dashboardCountData, interviewRoundData } = useSelector((state) => state.adminDashboard);

  useEffect(() => {
    dispatch(getAdminDashboardCount());
    dispatch(getInterviewerRound());
    dispatch(
      getScheduleInterviewCountByHR(
        getDateWithDay(moment().startOf('day').toDate()),
        getDateWithDay(moment().endOf('day').toDate())
      )
    );
  }, [dispatch]);

  //for cards
  function parseData(data) {
    if (!data) {
      return { candidates: [], hrs: [], interviewers: [] };
    } else {
      const { candidates, hrs, interviewers } = data;
      return {
        candidates: [{ name: 'Total', value: candidates?.totalCandidates }],
        hrs: [
          { name: 'Total', value: hrs?.totalHRs },
          { name: 'Active', value: hrs?.activeHRs },
          { name: 'Inactive', value: hrs?.inactiveHRs }
        ],
        interviewers: [
          { name: 'Total', value: interviewers?.totalInterviewers },
          { name: 'Active', value: interviewers?.activeInterviewers },
          { name: 'Inactive', value: interviewers?.inactiveInterviewers }
        ]
      };
    }
  }

  const { candidates, hrs, interviewers } = parseData(dashboardCountData);

  return (
    <>
      <div className="white-box-grid dashboard-card-list">
        <div className="white-box-item small-width">
          <div className="white-box-item dashboard-card-item-height dashboard-card-upper-item display-flex">
            <div className="dashboard-card-item half-width left-block-padding">
              <div className="white-box align-center">
                <div className="card-content full-width">
                  <div className="card-title-block">
                    <div className="content">
                      <p className="number">
                        {(!!interviewRoundData?.length &&
                          interviewRoundData.filter(
                            (round) =>
                              round.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_PENDING ||
                              round.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_SCHEDULE ||
                              round.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_RESCHEDULE ||
                              round.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_PENDING ||
                              round.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_SCHEDULE ||
                              round.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_RESCHEDULE ||
                              round.Status === STATUS_INTERVIEW.MANAGEMENT_PENDING ||
                              round.Status === STATUS_INTERVIEW.MANAGEMENT_SCHEDULE ||
                              round.Status === STATUS_INTERVIEW.MANAGEMENT_RESCHEDULE ||
                              round.Status === STATUS_INTERVIEW.PRACTICAL_PENDING ||
                              round.Status === STATUS_INTERVIEW.PRACTICAL_SCHEDULE ||
                              round.Status === STATUS_INTERVIEW.PRACTICAL_RESCHEDULE ||
                              round.Status === STATUS_INTERVIEW.HR_PENDING ||
                              round.Status === STATUS_INTERVIEW.HR_SCHEDULE ||
                              round.Status === STATUS_INTERVIEW.HR_RESCHEDULE
                          )?.length) ||
                          0}
                      </p>
                      <p className="card-title">{USER_TYPE_TEXT.PLANED_INTERVIEWS}</p>
                    </div>
                    <div className="icon-block">
                      <i>
                        <img src={interviewIc} alt="interview" className="normal-img" />
                        <img src={interviewIcDark} alt="interview" className="dark-theme-img" />
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <HrCard
              candidateIc={candidateIc}
              data={candidates}
              userType={USER_TYPE_TEXT.CANDIDATES}
            />
          </div>
          <div className="white-box-item dashboard-card-item-height dashboard-card-lower-item display-flex">
            <AdminCard
              userType={USER_TYPE_TEXT.HRS}
              data={hrs}
              activeUserIC={activeUserIC}
              inactiveUserIC={inactiveUserIC}
              className="left-block-padding"
            />
            <AdminCard
              userType={USER_TYPE_TEXT.INTERVIEWERS}
              data={interviewers}
              activeUserIC={activeUserIC}
              inactiveUserIC={inactiveUserIC}
            />
          </div>
        </div>
        <EqualHeight>
          <DoughnutChart />
        </EqualHeight>
        <EqualHeight>
          <PieChart />
          {/* <UpcommingDoughnutChart /> */}
          {/* <InterviewCountBarChart /> */}
          <AvailableInterviewersBarChart />
        </EqualHeight>
      </div>
    </>
  );
};

export default AdminDashboard;

import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

// Components
import DataTable from '../../components/DataTable';
import InterviewDetails from '../../components/popup/InterviewDetails';
import { ActionButtonsForInvitationInterview, ResumeButton } from '../../components/ActionButtons';

// Prime-React Components
import { confirmDialog } from 'primereact/confirmdialog';

// Redux-Actions
import {
  getInterviewInvitationsList,
  interviewInvitaionStatus
} from '../../redux/actions/interviews';
import { interviewerInterviewDetailAction } from '../../redux/actions/userDetails';

// Redux-Slices
import { apiStatusClear } from '../../redux/slices/apiStatus';
import { getNotification } from '../../redux/slices/notification';
import { redirectionOnStatusChange } from '../../redux/slices/interviews';

// Constants
import { INTERVIEW_INVITATIONS_COLUMNS } from '../../constants/tableColumns';
import {
  ACTION_TYPE,
  DEBOUNCE_TIMEOUT,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  INTERVIEW_INVITATION_STATUS,
  INTERVIEW_MODE,
  INTERVIEW_TYPE,
  Interview_Invitations_Status,
  ROUTES,
  STATUS,
  TABLE_NAME
} from '../../constants/common';

// Prime-React Components
import { Badge } from 'primereact/badge';

// Utils
import { formatTime, getFormattedDateView } from '../../utils/date';
import { debounce } from '../../utils/debounce';
import {
  wordCapitalize,
  downloadPdf,
  formatExperience,
  renderHrAvatar,
  isDarkMode,
  normalizeObject,
  setPreviousRoundDetails
} from '../../utils/common';
import { INVITATIONS_DROPDOWN_OPTIONS } from '../../constants/dropdownOptions';
import ConfirmDialogWithReason from '../../components/ConfirmDialogWithReasonForInterview';
import ReactTooltip from 'react-tooltip';

const pathBase = `${process.env.REACT_APP_API_ENDPOINT}/public/uploads/resume`;

const invitationsTableStates = {
  searchValue: '',
  pageNumber: INITIAL_PAGE_NUMBER,
  size: INITIAL_SIZE,
  invitationStatus: null,
  sortBy: 'Status',
  orderBy: 'asc'
};

const InterviewInvitation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { redirection, invitationStatus } = useSelector((state) => state.interviews);
  const { interviewInvitaion, userDetails } = useSelector((state) => state);
  const { isNotification } = useSelector((state) => state.notification);
  const [invitationList, setInvitationList] = useState([]);
  const [invitationExport, setInvitationExport] = useState([]);
  const [interviewId, setInterviewId] = useState(null);
  const [invitationId, setInvitationId] = useState(null);
  const [interviewDate, setInterviewDate] = useState(null);
  const [displayConfirmAction, setDisplayConfirmAction] = useState(false);
  const [tableStates, setTableStates] = useState(invitationsTableStates);
  const [showPopup, setShowPopup] = useState(false);
  const [isSelectedCandidateInviteAccepted, setIsSelectedCandidateInviteAccepted] = useState(false);
  const [currentRoundData, setCurrentRoundData] = useState({});
  const [previousRoundData, setPreviousRoundData] = useState({});
  const onTogglePopup = () => setShowPopup((prevState) => !prevState);

  useEffect(() => {
    dispatch(getInterviewInvitationsList(tableStates));
    if (isNotification) {
      dispatch(getNotification(false));
    }
  }, [dispatch, tableStates, isNotification]);

  const openResumeLink = (link) => window.open(link, '_blank');

  useEffect(() => {
    const modifiedList = interviewInvitaion?.data?.map((data) => {
      const { InterviewRound, RejectionReason, Status } = data;
      const { Interview, ID, Description, StartDateOfInterviewRound } = InterviewRound;
      return {
        ...data,
        CandidateName: (
          <Link to="#" className="table-view-popup-link" onClick={() => handleView(ID, Status)}>
            {Interview?.Candidate?.FirstName ? wordCapitalize(Interview.Candidate?.FirstName) : ''}{' '}
            {Interview?.Candidate?.LastName ? wordCapitalize(Interview.Candidate?.LastName) : ''}
          </Link>
        ),
        HrName: renderHrAvatar(
          InterviewRound.HROfRound?.ID,
          InterviewRound.HROfRound?.UserProfile,
          InterviewRound.HROfRound?.FirstName,
          InterviewRound.HROfRound?.LastName
        ),
        'Start Date': `${getFormattedDateView(
          InterviewRound.StartDateOfInterviewRound
        )} ${formatTime(InterviewRound.StartDateOfInterviewRound)}`,
        InterviewType: (
          <ul className="technology-list">
            {Interview?.Candidate.UMap && Interview?.Candidate?.UMap?.length > 0
              ? Interview.Candidate.UMap?.map((tech) => (
                  <li
                    key={tech.MapT.ID}
                    style={{
                      backgroundColor: `${
                        isDarkMode() ? tech?.MapT?.DarkModeColor : tech?.MapT?.TechnologyColor
                      }`
                    }}
                    className="technology-badge">
                    {tech.MapT.Technology}
                  </li>
                ))
              : []}
          </ul>
        ),
        JobDesignation:
          Interview?.JobDesignation && wordCapitalize(Interview?.JobDesignation?.Designation),
        RoundOfInterview: `${INTERVIEW_TYPE.filter(
          (num) => num.code === InterviewRound?.InterviewRound
        ).map((type) => type.name)}`,
        resume: (
          <div className="flex">
            {Interview?.Candidate?.ResumeOfCandidate?.FilePath && (
              <>
                <ResumeButton
                  onClick={() =>
                    openResumeLink(
                      `${pathBase}/${Interview?.Candidate?.ID}/${Interview?.Candidate?.ResumeOfCandidate?.FilePath}`
                    )
                  }
                />
                <i
                  data-tip="Download"
                  onClick={() =>
                    downloadPdf(
                      pathBase,
                      Interview?.Candidate?.ID,
                      Interview?.Candidate?.ResumeOfCandidate?.FilePath
                    )
                  }
                  className="pi pi-fw pi-download"
                />
              </>
            )}
          </div>
        ),
        Status: (
          <div className="center interview-status">
            <Badge
              key={Status}
              value={INTERVIEW_INVITATION_STATUS[Status]}
              className={`white-space-nowrap ${
                Status === STATUS.ACCEPT
                  ? 'complete-badge'
                  : Status === STATUS.ACCEPT_BY_OTHER
                  ? 'reschedule-badge'
                  : Status === STATUS.PENDING
                  ? 'pending-badge'
                  : 'cancel-badge'
              }`}
            />
            {Status === Interview_Invitations_Status.Rejected && RejectionReason && (
              <i
                className="custom-target-icon pi pi-info-circle pl-1"
                data-tip={`Cancellation Reason : ${RejectionReason}`}
                style={{ cursor: 'pointer' }}
              />
            )}
            <ReactTooltip place="top" type="dark" effect="float" />
          </div>
        ),
        Description: Description || '',
        action: Status === STATUS.PENDING && (
          <div className="flex align-items-center">
            <ActionButtonsForInvitationInterview
              disabled={Status !== STATUS.PENDING}
              onClick={(actionType, e) =>
                handleRowActions(
                  actionType,
                  InterviewRound.ID,
                  data.ID,
                  StartDateOfInterviewRound,
                  e
                )
              }
            />
          </div>
        )
      };
    });
    setInvitationList(modifiedList);
  }, [interviewInvitaion, isDarkMode()]);

  useEffect(() => {
    const modifiedList = interviewInvitaion?.data?.map((data) => {
      const { InterviewRound } = data;
      const { Interview } = InterviewRound;
      const name = `${
        Interview?.Candidate?.FirstName ? wordCapitalize(Interview.Candidate?.FirstName) : ''
      } ${Interview?.Candidate?.LastName ? wordCapitalize(Interview.Candidate?.LastName) : ''}`;
      const Type = Interview?.Candidate?.UMap?.length
        ? Interview?.Candidate?.UMap.map((item) => item.MapT.Technology).join(', ')
        : [];
      return {
        CandidateName: name,
        HrName: `${
          InterviewRound.HROfRound?.FirstName
            ? wordCapitalize(InterviewRound.HROfRound?.FirstName)
            : ''
        } ${
          InterviewRound.HROfRound?.LastName
            ? wordCapitalize(InterviewRound.HROfRound?.LastName)
            : ''
        }`,
        'Start Date': `${getFormattedDateView(
          InterviewRound.StartDateOfInterviewRound
        )} ${formatTime(InterviewRound.StartDateOfInterviewRound)}`,
        InterviewType: Type,
        JobDesignation:
          Interview?.JobDesignation && wordCapitalize(Interview?.JobDesignation?.Designation)
      };
    });
    setInvitationExport(modifiedList);
  }, [interviewInvitaion]);

  useEffect(() => {
    if (interviewDate && invitationId) {
      setDisplayConfirmAction(true);
    } else {
      setDisplayConfirmAction(false);
    }
  }, [invitationId, interviewDate]);

  const modifyInterviewDetails = useCallback(() => {
    if (userDetails?.interviewData?.Interview?.Candidate && userDetails?.interviewData?.HROfRound) {
      const currentRoundDatails = {
        'Interview Details': {
          'Start Date': `${getFormattedDateView(
            userDetails?.interviewData?.StartDateOfInterviewRound
          )} ${formatTime(userDetails?.interviewData?.StartDateOfInterviewRound || '')} `,
          'End Date': `${getFormattedDateView(
            userDetails?.interviewData?.EndDateOfInterviewRound
          )} ${formatTime(userDetails?.interviewData?.EndDateOfInterviewRound || '')} `,
          'Interview Type': `${INTERVIEW_TYPE.filter(
            (num) => num.code === userDetails?.interviewData?.InterviewRound
          ).map((type) => type.name)}`,
          Technology:
            userDetails?.interviewData?.Interview?.Candidate.UMap &&
            userDetails?.interviewData?.Interview?.Candidate.UMap.length > 0
              ? userDetails?.interviewData?.Interview?.Candidate.UMap?.map((tech) =>
                  wordCapitalize(tech.MapT.Technology)
                ).join(', ')
              : null,
          'Interview Mode': userDetails?.interviewData?.InterviewRoundMode
            ? INTERVIEW_MODE.find(
                (num) => num.code === userDetails?.interviewData?.InterviewRoundMode
              )?.name
            : '--'
        },
        'Candidate Details': {
          'Candidate Name': `${
            wordCapitalize(userDetails?.interviewData?.Interview?.Candidate?.FirstName) || ''
          } ${wordCapitalize(userDetails?.interviewData?.Interview?.Candidate?.LastName) || ''}`,
          'Candidate Exp': formatExperience(
            userDetails?.interviewData?.Interview?.Candidate?.YearsOfExperience,
            userDetails?.interviewData?.Interview?.Candidate?.MonthsOfExperience
          )
        },
        Description: {
          Description: userDetails?.interviewData?.Description || '--'
        },
        'HR Details': {
          HR: renderHrAvatar(
            userDetails?.interviewData?.HROfRound?.ID,
            userDetails?.interviewData?.HROfRound?.UserProfile,
            userDetails?.interviewData?.HROfRound?.FirstName,
            userDetails?.interviewData?.HROfRound?.LastName,
            true
          )
        }
      };
      let previousRoundDetails = {};
      if (isSelectedCandidateInviteAccepted) {
        previousRoundDetails = setPreviousRoundDetails(userDetails);
      }
      setCurrentRoundData(currentRoundDatails);
      setPreviousRoundData(previousRoundDetails);
    }
  }, [userDetails, isSelectedCandidateInviteAccepted]);

  useEffect(() => {
    modifyInterviewDetails();
  }, [userDetails, modifyInterviewDetails]);

  useEffect(() => {
    interviewId && dispatch(interviewerInterviewDetailAction(interviewId));
  }, [dispatch, interviewId]);

  const handlePagination = (pageNumber, size) =>
    setTableStates((prevState) => {
      return {
        ...prevState,
        pageNumber,
        size
      };
    });

  const handleSearch = (searchValue) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        searchValue
      };
    });
  };

  const handleSort = (sortBy) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        sortBy,
        orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
      };
    });
  };

  const handleView = (ID, status) => {
    if (ID) {
      onTogglePopup();
      setInterviewId(ID);
    }
    setIsSelectedCandidateInviteAccepted(status === Interview_Invitations_Status.Accepted);
  };

  const handleRowActions = (actionType, rowId, acceptRejectId, date) => {
    if (actionType === ACTION_TYPE.ACCEPT) {
      const interviewDate = `${getFormattedDateView(date)} at ${formatTime(date)}`;
      const dateOfInterview = `${getFormattedDateView(interviewDate)}`;
      const TodaysDate = `${getFormattedDateView(new Date())}`;
      if (TodaysDate === dateOfInterview) {
        confirmDialog({
          message: (
            <>
              This interview is scheduled for <strong>{interviewDate}</strong> (TODAY). Are you sure
              you want to accept?
            </>
          ),
          header: 'Accept',
          icon: 'pi pi-exclamation-triangle',
          accept: () =>
            dispatch(
              interviewInvitaionStatus({ id: acceptRejectId, invitationStatus: STATUS.ACCEPT })
            )
        });
      } else {
        confirmDialog({
          message: (
            <>
              This interview is scheduled for <strong>{interviewDate}</strong>. Are you sure you
              want to accept?
            </>
          ),
          header: 'Accept',
          icon: 'pi pi-exclamation-triangle',
          accept: () =>
            dispatch(
              interviewInvitaionStatus({ id: acceptRejectId, invitationStatus: STATUS.ACCEPT })
            )
        });
      }
    } else if (actionType === ACTION_TYPE.REJECT) {
      const interviewDate = `${getFormattedDateView(date)} at ${formatTime(date)}`;
      setInvitationId(acceptRejectId);
      setInterviewDate(interviewDate);
    }
  };

  useEffect(() => {
    if (redirection) {
      setDisplayConfirmAction(false);
      if (invitationStatus == STATUS.ACCEPT) {
        navigate(ROUTES.INTERVIEWER.INTERVIEW_SCHEDULED);
        toast.success('Invitation Accepted Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        dispatch(apiStatusClear());
        dispatch(redirectionOnStatusChange({ redirection: false, invitationStatus }));
      } else {
        navigate(ROUTES.INTERVIEWER.DASHBOARD);
        dispatch(redirectionOnStatusChange({ redirection: false, invitationStatus }));
      }
    }
  }, [redirection, navigate, dispatch]);

  const handleFilter = (value) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        invitationStatus: value || null
      };
    });
  };

  return (
    <>
      <InterviewDetails
        data={
          isSelectedCandidateInviteAccepted
            ? {
                'Current Round Details': currentRoundData,
                'Previous Round Details': previousRoundData
              }
            : currentRoundData
        }
        isTabView={isSelectedCandidateInviteAccepted}
        onHide={onTogglePopup}
        show={showPopup}
      />
      {displayConfirmAction && (
        <ConfirmDialogWithReason
          bodyTitle={
            <span>
              This interview is scheduled for <strong>{interviewDate}</strong>{' '}
              {getFormattedDateView(new Date()) === getFormattedDateView(interviewDate)
                ? ' (TODAY)'
                : ''}
              . Are you sure you want to reject?
            </span>
          }
          interviewId={invitationId}
          dialogHeader="Reject Invitation"
          setInterviewId={setInvitationId}
          displayConfirmAction={displayConfirmAction}
          isReasonRequired
          handleSubmit={(values) => {
            dispatch(
              interviewInvitaionStatus({
                id: invitationId,
                rejectionReason: values.cancelReason,
                invitationStatus: STATUS.REJECT
              })
            );
          }}
        />
      )}
      <DataTable
        name={TABLE_NAME.INTERVIEW_INVITATIONS}
        columns={INTERVIEW_INVITATIONS_COLUMNS}
        searchPlaceHolder="Search by Candidate name, Email and Mobile"
        data={invitationList}
        exportData={invitationExport}
        totalRecords={interviewInvitaion?.pagination?.totalRecords}
        onPagination={handlePagination}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        onSort={handleSort}
        dropdown={INVITATIONS_DROPDOWN_OPTIONS}
        onStatusFilter={handleFilter}
        dropdownValue={tableStates.invitationStatus}
        clearFilter
        isClearFilterDisabled={
          JSON.stringify(normalizeObject(invitationsTableStates)) ===
          JSON.stringify(normalizeObject(tableStates))
        }
        clearFilterOnClick={() => setTableStates(invitationsTableStates)}
      />
    </>
  );
};

export default InterviewInvitation;

import api from '../../utils/axios';

// Constants
import { API_ROUTES } from '../../constants/apiRoutes';
import moment from 'moment';
import { getDateWithDay } from '../../utils/date';

export const editUserDetailsApi = async (userId) => {
  try {
    const response = await api.get(`${API_ROUTES.USER_DETAILS}/${userId}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAdminDashboardCountApi = async () => {
  try {
    const response = await api.get(API_ROUTES.ADMIN_DASHBOARD_COUNT);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getInterviewRoundApi = async () => {
  const interviewStartTime = getDateWithDay(moment().startOf('day').toDate());
  const interviewEndTime = getDateWithDay(moment().endOf('day').toDate());
  try {
    const response = await api.get(
      `${API_ROUTES.ADMIN_INTERVIEW_ROUND}?interviewStartTime=${interviewStartTime}&interviewEndTime=${interviewEndTime}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getInterviewRoundApiWithFilter = async ({ interviewStartTime, interviewEndTime }) => {
  try {
    const response = await api.get(
      `${API_ROUTES.ADMIN_INTERVIEW_ROUND}?interviewStartTime=${interviewStartTime}&interviewEndTime=${interviewEndTime}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getTechnologyApiWithFilter = async ({ interviewStartTime, interviewEndTime }) => {
  try {
    const response = await api.get(
      `${API_ROUTES.ADMIN_INTERVIEW_ROUND}?interviewStartTime=${interviewStartTime}&interviewEndTime=${interviewEndTime}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const getRoundApiWithFilter = async ({ interviewStartTime, interviewEndTime }) => {
  try {
    const response = await api.get(
      `${API_ROUTES.ADMIN_INTERVIEW_ROUND}?interviewStartTime=${interviewStartTime}&interviewEndTime=${interviewEndTime}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getInterviewerListApi = async () => {
  try {
    const response = await api.get(API_ROUTES.ADMIN_INTERVIEW_LIST);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const checkEmailMobileApi = async (payload) => {
  try {
    const response = await api.post(API_ROUTES.CHECK_EMAIL_MOBILE, payload);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

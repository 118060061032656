import React, { useEffect, useRef, useState } from 'react';
import { EqualHeightElement } from 'react-equal-height';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// Prime-React Components
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-datalabels';

// Components
import { CustomeTooltip } from './ActionButtons';

// Redux-Actions
import { getTechnologyWithFilter } from '../redux/actions/admin';

// Constants
import { INTERVIEW_COLORS, INTERVIEW_STATUS_LIST } from '../constants/common';

// Images
import filterIc from '@assets/images/filter-ic.svg';
import filterIcDark from '@assets/images/filter-ic-dark.svg';

// Utils
import { getDateWithDay } from '../utils/date';
import { getBarOptions } from '../utils/chart';

const PieChart = () => {
  const dispatch = useDispatch();
  const { interviewRoundData, pieChartFilterdData } = useSelector((state) => state.adminDashboard);
  const menu = useRef(null);
  const [roundStatusCode, setRoundStatusCode] = useState({});
  const [filterStatus, setStatusFilter] = useState('day');
  const [modifiedData, setModifiedData] = useState([]);

  const formattedData = (interviewRoundData || [])
    .map((item) => item)
    .flatMap((formattedItem) => formattedItem);

  const formattedDataFilter = (pieChartFilterdData || [])
    .map((item) => item)
    .flatMap((formattedItem) => formattedItem);

  useEffect(() => {
    const modifiedInterviewRoundStatus = formattedData.map((item) => {
      return INTERVIEW_STATUS_LIST.filter((num) => num.code === item?.Status);
    });

    const newArray = modifiedInterviewRoundStatus
      .map((item) => item)
      .flatMap((formattedItem) => formattedItem);

    setModifiedData(newArray);
  }, [interviewRoundData, pieChartFilterdData]);

  useEffect(() => {
    const modifiedInterviewRoundStatus = formattedDataFilter.map((item) => {
      return INTERVIEW_STATUS_LIST.filter((num) => num.code === item?.Status);
    });

    const newArray = modifiedInterviewRoundStatus
      .map((item) => item)
      .flatMap((formattedItem) => formattedItem);

    setModifiedData(newArray);
  }, [pieChartFilterdData]);

  const interviewStatusObject = () => {
    return INTERVIEW_STATUS_LIST.filter((item) => !item.name.includes('Complete')).reduce(
      (obj, item) => {
        obj[item.name] = 0;
        return obj;
      },
      {}
    );
  };

  const generateLabelForChart = () => {
    return INTERVIEW_STATUS_LIST.filter((item) => !item.name.includes('Complete')).map((status) => {
      const parts = status.name.split(' ');
      const first = parts.shift(); // First word
      const last = parts.pop(); // Last word
      const middle = parts.join(' '); // Middle part
      return [first, middle, last].filter((part) => part !== '' && part !== undefined);
    });
  };

  // for interview status
  function modifiedStatus(data) {
    const formattedData = (data || [])
      .map((item) => item?.name)
      .flatMap((formattedItem) => formattedItem);

    let statusCodeData = interviewStatusObject();
    if (!formattedData) {
      return { statusData: [] };
    } else {
      for (var i = 0, statusData = []; i < formattedData.length; i++) {
        var el = formattedData[i];
        const count = statusCodeData[el] !== undefined ? statusCodeData[el] + 1 : 0;
        statusCodeData[el] = count;
        statusData.push(el);
      }
      setRoundStatusCode(statusCodeData);
      return statusData;
    }
  }

  function modifiedStatusFilter(data) {
    let statusCodeData = interviewStatusObject();
    const formattedData = (data || [])
      .map((item) => item?.name)
      .flatMap((formattedItem) => formattedItem);
    if (!formattedData) {
      return { statusFilterData: [] };
    } else {
      for (var i = 0, statusFilterData = []; i < formattedData.length; i++) {
        var el = formattedData[i];
        const count = statusCodeData[el] !== undefined ? statusCodeData[el] + 1 : 0;
        statusCodeData[el] = count;
        statusFilterData.push(el);
      }
      setRoundStatusCode(statusCodeData);
      return statusFilterData;
    }
  }
  useEffect(() => {
    modifiedStatus(modifiedData);
  }, [interviewRoundData, modifiedData]);

  useEffect(() => {
    modifiedStatusFilter(modifiedData);
  }, [modifiedData, pieChartFilterdData]);

  useEffect(() => {
    var startOfWeek = getDateWithDay(moment().startOf(filterStatus).toDate());
    var endOfWeek = getDateWithDay(moment().endOf(filterStatus).toDate());

    if (filterStatus) {
      dispatch(getTechnologyWithFilter(startOfWeek, endOfWeek));
    }
  }, [dispatch, filterStatus]);

  const statusFilter = [
    {
      label: 'Today',
      value: 1,
      command: () => setStatusFilter('day'),
      className: filterStatus === 'day' ? 'highlighted-item' : ''
    },
    {
      label: 'Weekly',
      value: 1,
      command: () => setStatusFilter('week'),
      className: filterStatus === 'week' ? 'highlighted-item' : ''
    },
    {
      label: 'Monthly',
      value: 2,
      command: () => setStatusFilter('month'),
      className: filterStatus === 'month' ? 'highlighted-item' : ''
    }
  ];

  const statusChartData = {
    labels: generateLabelForChart(),
    datasets: [
      {
        data: INTERVIEW_STATUS_LIST.filter((item) => !item.name.includes('Complete')).map(
          (status) => roundStatusCode[status.name]
        ),
        borderWidth: 0,
        borderRadius: 50,
        barThickness: 20,
        backgroundColor: [
          INTERVIEW_COLORS.PENDING,
          INTERVIEW_COLORS.SCHEDULE,
          INTERVIEW_COLORS.RESCHEDULE,
          INTERVIEW_COLORS.PENDING,
          INTERVIEW_COLORS.SCHEDULE,
          INTERVIEW_COLORS.RESCHEDULE,
          INTERVIEW_COLORS.PENDING,
          INTERVIEW_COLORS.SCHEDULE,
          INTERVIEW_COLORS.RESCHEDULE,
          INTERVIEW_COLORS.PENDING,
          INTERVIEW_COLORS.SCHEDULE,
          INTERVIEW_COLORS.RESCHEDULE,
          INTERVIEW_COLORS.PENDING,
          INTERVIEW_COLORS.SCHEDULE,
          INTERVIEW_COLORS.RESCHEDULE,
          INTERVIEW_COLORS.CANCEL
        ],
        hoverBackgroundColor: [
          INTERVIEW_COLORS.SCHEDULE,
          INTERVIEW_COLORS.RESCHEDULE,
          INTERVIEW_COLORS.PENDING,
          INTERVIEW_COLORS.SCHEDULE,
          INTERVIEW_COLORS.RESCHEDULE,
          INTERVIEW_COLORS.PENDING,
          INTERVIEW_COLORS.SCHEDULE,
          INTERVIEW_COLORS.RESCHEDULE,
          INTERVIEW_COLORS.PENDING,
          INTERVIEW_COLORS.SCHEDULE,
          INTERVIEW_COLORS.RESCHEDULE,
          INTERVIEW_COLORS.PENDING,
          INTERVIEW_COLORS.SCHEDULE,
          INTERVIEW_COLORS.RESCHEDULE,
          INTERVIEW_COLORS.CANCEL
        ],
        datalabels: {
          color: '#FFF'
        }
      }
    ]
  };

  return (
    <div className="white-box-item full-width">
      <div className="white-box">
        <div className="card-title-block">
          <EqualHeightElement name="title">
            <div className="card-title-wrapper">
              <span className="card-title">Interview status</span>
              <i
                className="custom-target-icon pi pi-info-circle pl-1"
                data-tip={`${
                  filterStatus === 'week'
                    ? "Last week's"
                    : filterStatus === 'month'
                    ? "Last month's"
                    : "Today's"
                } Interviews based on Status`}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </EqualHeightElement>
          <div className="filter-block">
            <Menu
              model={statusFilter}
              popup
              ref={menu}
              id="popup_menu"
              appendTo="self"
              className="popup_menu action-popup chartFilterMenu"
            />
            <CustomeTooltip />
            <Button
              data-tip="Filter By"
              type="button"
              onClick={(event) => menu.current.toggle(event)}
              aria-controls="popup_menu"
              aria-haspopup
              className="p-button-rounded p-button-text border-none primary-icon-btn filter-btn">
              <i>
                <img src={filterIc} className="default-img" alt="filter" />
                <img src={filterIcDark} className="dark-mode-img" alt="filter" />
              </i>
            </Button>
          </div>
        </div>

        <div className="chart-container">
          <div className="chart-figure-wrapper" style={{ width: '1500px' }}>
            <Chart
              type="bar"
              data={statusChartData}
              options={getBarOptions()}
              plugins={[ChartDataLabels]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieChart;

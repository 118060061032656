import React from 'react';
import { useSelector } from 'react-redux';

// Prime-React-Components
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';

// Pages
import Schedule from '../../pages/hr/Schedule';

const AvailabilityDetails = (props) => {
  const { onHide, show } = props;
  const { isLoading } = useSelector((state) => state.hrs);
  return (
    <Dialog
      header="Availability Details"
      style={{ width: '800px' }}
      className={classNames('card py-0 availability-detail-popup', { hidden: isLoading })}
      visible={show}
      onHide={onHide}>
      <Schedule onHide={onHide} />
    </Dialog>
  );
};

export default AvailabilityDetails;

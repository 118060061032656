import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';

// Api-Services
import {
  addCommonParamsApi,
  commonParamsListApi,
  getCommonParamsByIdApi,
  editCommonParameterApi,
  deleteParameterApi,
  filterCommonParamsListApi
} from '../apis/commonParameter';

// Constants
import {
  ADD_COMMON_PARAMS_REQUEST,
  COMMON_PARAMS_LIST_REQUEST,
  GET_COMMON_PARAMS_DETAILS,
  EDIT_COMMON_PARAMS_REQUEST,
  DELETE_COMMON_PARAMS_REQUEST,
  FILTER_COMMON_PARAMS_LIST_REQUEST
} from '../../constants/redux';
import { RESPONSE_STATUS_CODES } from '../../constants/common';

// Redux-Slices
import { apiSuccess } from '../slices/apiStatus';
import {
  addParameterData,
  deleteParameter,
  editParameterDetails,
  filterParameters
} from '../slices/commonParamters';

function* commonParamsListSaga(action) {
  try {
    const response = yield call(commonParamsListApi, {
      ...action.payload
    });
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      const data = result.feedbackParameters;
      const pagination = result.pagination;
      yield put(addParameterData({ data, pagination }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* filterCommonParamsListSaga(action) {
  try {
    const response = yield call(filterCommonParamsListApi, {
      ...action.payload
    });
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      const data = result.feedbackParameters;
      yield put(filterParameters(data));
    }
  } catch (error) {
    console.log(error);
  }
}

function* addCommonTechParamsSaga(action) {
  try {
    const response = yield call(addCommonParamsApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.OK) {
      toast.success('Parameter Added Succesfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

function* getCommonParameterByIdSaga(action) {
  const ID = action.payload;
  const response = yield call(getCommonParamsByIdApi, ID);
  if (response.status === RESPONSE_STATUS_CODES.OK) {
    const designationDetails = response.data.data;
    yield put(editParameterDetails(designationDetails));
    yield put(apiSuccess());
  }
}

function* editCommonParametersData(action) {
  try {
    const response = yield call(editCommonParameterApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.OK) {
      toast.success('Detail have been Updated Successfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteCommonParameterData(action) {
  try {
    const response = yield call(deleteParameterApi, action.payload);
    console.log(response);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      yield put(deleteParameter(action.payload));
      toast.success('Parameter Deleted Succesfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* watcherCommonParameterSaga() {
  yield takeEvery(ADD_COMMON_PARAMS_REQUEST, addCommonTechParamsSaga);
  yield takeEvery(COMMON_PARAMS_LIST_REQUEST, commonParamsListSaga);
  yield takeEvery(FILTER_COMMON_PARAMS_LIST_REQUEST, filterCommonParamsListSaga);
  yield takeEvery(GET_COMMON_PARAMS_DETAILS, getCommonParameterByIdSaga);
  yield takeEvery(EDIT_COMMON_PARAMS_REQUEST, editCommonParametersData);
  yield takeEvery(DELETE_COMMON_PARAMS_REQUEST, deleteCommonParameterData);
}

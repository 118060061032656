import React from 'react';

// Mui Components
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#6366F1'
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
});

const CustomButton = (props) => {
  const { variant, onClick, className, color, children, ...rest } = props;
  return (
    <ThemeProvider theme={theme}>
      <Button
        size="large"
        variant={variant}
        onClick={onClick}
        className={className}
        color={color ? color : 'primary'}
        {...rest}>
        {children}
      </Button>
    </ThemeProvider>
  );
};

export default CustomButton;

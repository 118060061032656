import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: { data: [] }
};

const interviewersDashboardSlice = createSlice({
  name: 'interviewersDashboard',
  initialState,
  reducers: {
    addInterviewersSchedule(state, action) {
      const scheduleData = action.payload;
      return { ...state, scheduleData };
    },
    addInterviewersInvitation(state, action) {
      const invitationData = action.payload;
      return { ...state, invitationData };
    },
    addInterviewersFeedback(state, action) {
      const feedbackData = action.payload;
      return { ...state, feedbackData };
    }
  }
});
export const { addInterviewersSchedule, addInterviewersInvitation, addInterviewersFeedback } =
  interviewersDashboardSlice.actions;
export default interviewersDashboardSlice.reducer;

// Firebase and firebase messaging
import { initializeApp, getApps } from 'firebase/app';

const validateFirebaseConfig = () => {
  const {
    REACT_APP_API_KEY,
    REACT_APP_AUTH_DOMAIN,
    REACT_APP_PROJECT_ID,
    REACT_APP_STORAGE_BUCKET,
    REACT_APP_MESSAGING_SENDERID,
    REACT_APP_APP_ID,
    REACT_APP_MEASUREMENT_ID
  } = process.env;
  if (
    !(
      REACT_APP_API_KEY &&
      REACT_APP_AUTH_DOMAIN &&
      REACT_APP_PROJECT_ID &&
      REACT_APP_STORAGE_BUCKET &&
      REACT_APP_MESSAGING_SENDERID &&
      REACT_APP_APP_ID &&
      REACT_APP_MEASUREMENT_ID
    )
  ) {
    return false;
  }
  return true;
};

const getFirebaseConfig = () => {
  if (process.env && validateFirebaseConfig()) {
    return {
      apiKey: `${process.env.REACT_APP_API_KEY}`,
      authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
      projectId: `${process.env.REACT_APP_PROJECT_ID}`,
      storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET}`,
      messagingSenderId: `${process.env.REACT_APP_MESSAGING_SENDERID}`,
      appId: `${process.env.REACT_APP_APP_ID}`,
      measurementId: `${process.env.REACT_APP_MEASUREMENT_ID}`
    };
  }
  return;
};

export let firebaseInstance;

export const initializeFirebase = () => {
  const config = getFirebaseConfig();
  if (config && getApps().length === 0) firebaseInstance = initializeApp(config);

  if (!config) console.log('Configuration not found for firebase');
};

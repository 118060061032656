import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Components
import CustomButton from '../../components/CustomButton';
import Label from '../../components/Label';
import { TextAreaWithCounterLimit } from '../TextAreaWithCounterLimit';

// Prime-React-Components
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { AutoComplete } from 'primereact/autocomplete';
import { confirmDialog } from 'primereact/confirmdialog';

// Constants
import {
  BUTTON_TYPES,
  FEEDBACK_TEXTAREA_LIMIT,
  HR_OVERALL_FEEDBACK,
  HR_OVERALL_FEEDBACK_STATUS,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  InterviewType,
  Interview_Category,
  LABEL_TYPES,
  MSG_TYPES,
  OVERALL_GRADE,
  ROUTES,
  TEXTAREA_LIMIT
} from '../../constants/common';

// Redux-Actions
import { addFeedback, getInterviewHistoryList } from '../../redux/actions/interviews';
import { getJobDesignationBySearch } from '../../redux/actions/hr';

// Redux-Slices
import { apiStatusClear } from '../../redux/slices/apiStatus';
import { useNavigate } from 'react-router-dom';

// Utils

const AddFeedback = (props) => {
  // Props.
  const { id, interviewType, confirmDialogMessage, disabled, feedbackData, interviewCategory } =
    props;

  // Redux hooks.
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state);
  const { isLoading } = useSelector((state) => state.apiStatus);
  const { searchData } = useSelector((state) => state.hrs);

  // React useState hooks.
  const [designationOptions, setDesignationOptions] = useState(null);
  const [designationText, setDesignationText] = useState('');

  // React useEffect hooks.
  useEffect(() => {
    if (searchData) {
      const modifiedDesignationOptions =
        searchData && searchData.length > 0 ? searchData : [{ Designation: MSG_TYPES.NO_RESULT }];
      setDesignationOptions(modifiedDesignationOptions);
    }
  }, [searchData]);

  let validationShape;
  const commonValidationShape = {
    overallGrade: Yup.string().required('Please select an overall grade'),
    result: Yup.string().required('Please enter result'),
    feedbackNote: Yup.string()
      .trim()
      .min(
        interviewCategory === Interview_Category['Non Technical'] ? 4 : TEXTAREA_LIMIT.MIN,
        'Feedback note should be at least ${min} characters.'
      )
      .max(TEXTAREA_LIMIT.MAX, 'Feedback note should not exceed ${max} characters.')
      .nullable()
      .required('Please enter notes.')
  };

  if (
    props.interviewType === InterviewType['Technical Interview - 1'] ||
    props.interviewType === InterviewType['Technical Interview - 2'] ||
    props.interviewType === InterviewType['Management Round']
  ) {
    validationShape = Yup.object().shape({
      ...commonValidationShape
      // overallFeedback: Yup.string().required('Please select feedback')
    });
  } else if (props.interviewType === InterviewType.Practical) {
    validationShape = Yup.object().shape({
      ...commonValidationShape,
      codeQuality: Yup.string().required('Please enter code quality')
    });
  } else if (props.interviewType === InterviewType['HR Round']) {
    validationShape = Yup.object().shape({
      ...commonValidationShape,
      // overallFeedback: Yup.string().required('Please select feedback'),
      overallGrade: Yup.string().when('result', {
        is: HR_OVERALL_FEEDBACK_STATUS.Rejected,
        then: Yup.string(),
        otherwise: Yup.string().required('Please select an overall grade')
      }),
      jobDesignation: Yup.string().when('result', {
        is: HR_OVERALL_FEEDBACK_STATUS.Rejected,
        then: Yup.string(),
        otherwise: Yup.string().required('Please select a designation')
      }),
      package: Yup.string().when('result', {
        is: HR_OVERALL_FEEDBACK_STATUS.Rejected,
        then: Yup.string()
          .typeError('package must be a number')
          .max(2, 'package should not be more then 99'),
        otherwise: Yup.string()
          .typeError('package must be a number')
          .max(2, 'package should not be more then 99')
          .required('Please enter package value')
      }),
      yearsOfExperience: Yup.number().when('result', {
        is: HR_OVERALL_FEEDBACK_STATUS.Rejected,
        then: Yup.number(),
        otherwise: Yup.number()
          .min(0, 'Minimum 0 Year.')
          .max(100, 'Maximum 100 Years.')
          .typeError('Only numbers are allowed.')
          .required('Exp. in Years is Required.')
      }),
      monthsOfExperience: Yup.number().when('result', {
        is: HR_OVERALL_FEEDBACK_STATUS.Rejected,
        then: Yup.number(),
        otherwise: Yup.number()
          .min(0, 'Minimum 0 Month.')
          .max(11, 'Maximum 11 Months.')
          .required('Exp. in Months is required.')
          .typeError('Only numbers are allowed.')
      }),
      candidateExpectation: Yup.string().when('result', {
        is: HR_OVERALL_FEEDBACK_STATUS.Rejected,
        then: Yup.string()
          .typeError('candidate expectation must be a number')
          .max(2, 'candidate expectation should not be more then 99'),
        otherwise: Yup.string()
          .typeError('candidate expectation must be a number')
          .max(2, 'candidate expectation should not be more then 99')
          .required('Please enter candidate expectation')
      })
    });
  }

  const submitHRFeedbackAfterConfirmation = (values) => {
    const interviewRoundId = userDetails?.ID;
    const formData = {
      interviewRoundId: id,
      // overallFeedback: values.overallFeedback.toString(),
      result: values.result.toString(),
      feedbackNote: values.feedbackNote,
      overallGrades: values.overallGrade.toString(),
      designation: values.jobDesignation,
      packagePerAnum: values.package.toString(),
      yearsOfExperience: values.yearsOfExperience.toString(),
      monthsOfExperience: values.monthsOfExperience.toString(),
      candidateExpectations: values.candidateExpectation.toString(),
      interviewType
    };
    dispatch(apiStatusClear());
    dispatch(addFeedback(formData, interviewRoundId));
    props.onHide(false);
    setTimeout(() => {
      if (props.ByInterviewer) {
        dispatch(
          getInterviewHistoryList({
            searchValue: '',
            pageNumber: INITIAL_PAGE_NUMBER,
            size: INITIAL_SIZE,
            sortBy: 'DateModified',
            orderBy: 'desc'
          })
        );
        navigate(ROUTES.INTERVIEWER.INTERVIEW_HISTORY);
      }
    }, [250]);
  };

  const handleConfirmFeedbackFromHR = (values) => {
    confirmDialog({
      header: 'Confirmation',
      message:
        confirmDialogMessage || 'Are you sure you want to submit the feedback for this candidate?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        submitHRFeedbackAfterConfirmation(values);
      },
      reject: () => {}
    });
  };

  const formik = useFormik({
    initialValues: {
      // overallFeedback: feedbackData?.overallFeedback || '',
      result: feedbackData?.result || '',
      overallGrade: feedbackData?.overallGrade || '',
      codeQuality: feedbackData?.codeQuality || '',
      taskCompletion: feedbackData?.taskCompletion || 0,
      feedbackNote: feedbackData?.feedbackNote || '',
      interviewRoundId: '',
      jobDesignation: feedbackData?.jobDesignation || '',
      package: feedbackData?.package || '',
      yearsOfExperience: feedbackData?.yearsOfExperience || '',
      monthsOfExperience: feedbackData?.monthsOfExperience || '',
      candidateExpectation: feedbackData?.candidateExpectation || ''
    },
    validationSchema: validationShape,
    onSubmit: (values) => {
      if (interviewType === InterviewType['HR Round']) {
        handleConfirmFeedbackFromHR(values);
      }
    },
    enableReinitialize: true
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name, additionalError = '') => {
    let response = null;
    if (isFormFieldValid(name) || additionalError) {
      response = <small className="p-error">{formik.errors[name] || additionalError}</small>;
    }
    return response;
  };

  const handleDesignationSearch = () => {
    if (designationText) {
      const sanitizeString = designationText.trim().toString();
      dispatch(getJobDesignationBySearch(sanitizeString));
    }
  };
  return (
    <div className="form-box-wrapper feedback-form-popup">
      <form onSubmit={formik.handleSubmit}>
        <div className="form-row-wrapper">
          {interviewType === InterviewType['HR Round'] && (
            <>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="result" text="Result" isMandatory />
                    <Dropdown
                      name="result"
                      id="result"
                      optionLabel="name"
                      optionValue="code"
                      value={formik.values.result}
                      options={HR_OVERALL_FEEDBACK}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={disabled}
                      placeholder="Select result"
                      className={classNames({
                        'p-invalid': isFormFieldValid('result'),
                        'w-full': true
                      })}
                    />
                    {getFormErrorMessage('result')}
                  </div>
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="jobDesignation"
                      text={LABEL_TYPES.DESIGNATION}
                      isMandatory={formik.values.result !== HR_OVERALL_FEEDBACK_STATUS.Rejected}
                    />
                    <AutoComplete
                      field="Designation"
                      name="jobDesignation"
                      id="jobDesignation"
                      forceSelection
                      value={formik.values.jobDesignation}
                      suggestions={designationOptions}
                      completeMethod={handleDesignationSearch}
                      onChange={(e) => {
                        if (typeof e.target.value === 'object') {
                          setDesignationText(e.target.value?.Designation);
                          formik.setFieldValue('jobDesignation', e.target.value?.Designation);
                        } else {
                          setDesignationText(e.target.value);
                          formik.setFieldValue('jobDesignation', e.target.value);
                        }
                      }}
                      disabled={disabled}
                      onBlur={formik.handleBlur}
                      placeholder="Select Designation"
                      className={classNames({
                        'p-invalid': isFormFieldValid('jobDesignation'),
                        'w-full': true
                      })}
                    />
                    {getFormErrorMessage('jobDesignation')}
                  </div>
                </div>
              </div>
              <div className="form-col">
                <div className="form-row-wrapper">
                  <div className="form-col">
                    <div className="custom-form-group mr-4">
                      <Label
                        htmlFor="yearsOfExperience"
                        text={LABEL_TYPES.YEAR_EXPERIENCE}
                        isMandatory={formik.values.result !== HR_OVERALL_FEEDBACK_STATUS.Rejected}
                      />
                      <InputText
                        id="yearsOfExperience"
                        name="yearsOfExperience"
                        placeholder="0-30"
                        type="number"
                        value={formik.values.yearsOfExperience}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={disabled}
                        className={classNames({
                          'p-invalid': isFormFieldValid('yearsOfExperience')
                        })}
                      />
                      {getFormErrorMessage('yearsOfExperience')}
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="custom-form-group">
                      <Label
                        htmlFor="monthsOfExperience"
                        text={LABEL_TYPES.MONTH_EXPERIENCE}
                        isMandatory={formik.values.result !== HR_OVERALL_FEEDBACK_STATUS.Rejected}
                      />
                      <InputText
                        id="monthsOfExperience"
                        name="monthsOfExperience"
                        placeholder="0-11"
                        type="number"
                        value={formik.values.monthsOfExperience}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={disabled}
                        className={classNames({
                          'p-invalid': isFormFieldValid('monthsOfExperience')
                        })}
                      />
                      {getFormErrorMessage('monthsOfExperience')}
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="package"
                      text={LABEL_TYPES.PACKAGE}
                      isMandatory={formik.values.result !== HR_OVERALL_FEEDBACK_STATUS.Rejected}
                    />
                    <div className="p-inputgroup w-full">
                      <InputText
                        id="package"
                        name="package"
                        value={formik.values.package}
                        onChange={formik.handleChange}
                        className={classNames({
                          'p-invalid': isFormFieldValid('package'),
                          'w-full': true
                        })}
                        onBlur={formik.handleBlur}
                        disabled={disabled}
                        keyfilter={'money'}
                      />
                      <span className="p-inputgroup-addon add-candidate-lpa">LPA</span>
                    </div>
                    {getFormErrorMessage('package')}
                  </div>
                </div>
              </div>

              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="candidateExpectation"
                      text={LABEL_TYPES.CANDIDATE_EXPECTATION}
                      isMandatory={formik.values.result !== HR_OVERALL_FEEDBACK_STATUS.Rejected}
                    />
                    <div className="p-inputgroup w-full">
                      <InputText
                        id="candidateExpectation"
                        name="candidateExpectation"
                        value={formik.values.candidateExpectation}
                        onChange={formik.handleChange}
                        className={classNames({
                          'p-invalid': isFormFieldValid('candidateExpectation'),
                          'w-full': true
                        })}
                        onBlur={formik.handleBlur}
                        disabled={disabled}
                        keyfilter={'money'}
                      />
                      <span className="p-inputgroup-addon add-candidate-lpa">LPA</span>
                    </div>
                    {getFormErrorMessage('candidateExpectation')}
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="form-col">
            <div className="form-group-outer">
              <div className="custom-form-group">
                <Label
                  htmlFor="address"
                  text="Select an overall grade"
                  isMandatory={formik.values.result !== HR_OVERALL_FEEDBACK_STATUS.Rejected}
                />
                <Dropdown
                  name="overallGrade"
                  id="overallGrade"
                  optionLabel="name"
                  optionValue="code"
                  value={formik.values.overallGrade}
                  options={OVERALL_GRADE}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={disabled}
                  placeholder="Select an overall grade"
                  className={classNames({
                    'p-invalid': isFormFieldValid('overallGrade'),
                    'w-full': true
                  })}
                />
                {getFormErrorMessage('overallGrade')}
              </div>
            </div>
          </div>
          <div className="form-col full-width">
            <div className="form-group-outer">
              <div className="custom-form-group">
                <Label htmlFor="Note" text="Note" isMandatory />
                <TextAreaWithCounterLimit
                  placeholder="Add Note"
                  value={formik.values.feedbackNote}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.touched['feedbackNote'] ? formik.errors['feedbackNote'] : ''}
                  className={classNames({
                    'p-invalid': isFormFieldValid('feedbackNote')
                  })}
                  autoResize
                  id="feedbackNote"
                  name="feedbackNote"
                  htmlFor="feedbackNote"
                  validationOnMinLimit
                  minLimit={
                    interviewCategory === Interview_Category['Non Technical']
                      ? 4
                      : TEXTAREA_LIMIT.MIN
                  }
                  maxLimit={FEEDBACK_TEXTAREA_LIMIT.MAX}
                  rows={3}
                  cols={30}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="p-dialog-footer block">
          <CustomButton type="submit" variant="contained" disabled={isLoading || disabled}>
            {BUTTON_TYPES.SUBMIT}
          </CustomButton>
        </div>
      </form>
    </div>
  );
};

const AddHrFeedback = (props) => {
  const {
    id,
    onHide,
    show,
    interviewType,
    confirmDialogMessage,
    ByInterviewer,
    disabled,
    feedbackData,
    interviewCategory
  } = props;
  const { isLoading } = useSelector((state) => state.apiStatus);

  return (
    <Dialog
      header="HR Round Feedback "
      className={classNames('card overflow-hidden resize-none feedback-dialog', {
        hidden: isLoading
      })}
      visible={show}
      onHide={() => onHide(false)}>
      <AddFeedback
        id={id}
        onHide={onHide}
        disabled={disabled}
        interviewType={interviewType}
        confirmDialogMessage={confirmDialogMessage}
        feedbackData={feedbackData}
        ByInterviewer={ByInterviewer}
        interviewCategory={interviewCategory}
      />
    </Dialog>
  );
};

export default AddHrFeedback;

import api from '../../utils/axios';

//Constants
import { API_ROUTES } from '../../constants/apiRoutes';

export const technologylistApi = async ({ pageNumber, searchValue, sortBy, orderBy, size }) => {
  try {
    const response = await api.get(
      `${API_ROUTES.TECHNOLOGY}?page=${pageNumber}&searchName=${searchValue}&sortBy=${sortBy}&orderBy=${orderBy}&size=${size}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const addTechnologyApi = async (technology) => {
  try {
    const response = await api.post(API_ROUTES.TECHNOLOGY, technology);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getTechnologyByIdApi = async (ID) => {
  try {
    const response = await api.get(`${API_ROUTES.TECHNOLOGY}/${ID}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteTechnologyApi = async (ID) => {
  try {
    const response = await api.delete(`${API_ROUTES.TECHNOLOGY}/${ID}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const editTechnologyApi = async (details) => {
  try {
    const response = await api.put(`${API_ROUTES.TECHNOLOGY}/${details.ID}`, details.formdata);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteSubTechnology = async (id) => {
  try {
    const response = await api.delete(`${API_ROUTES.SUB_TECHNOLOGY}/${id}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Components
import Label from '../../components/Label';
import CustomButton from '../../components/CustomButton';

// Prime-React Components
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Sidebar } from 'primereact/sidebar';

// Redux-Actions
import { addTechnology, editTechnology, getTechnologyById } from '../../redux/actions/technology';

// Redux-Slices
import { apiStatusClear } from '../../redux/slices/apiStatus';
import { updateData } from '../../redux/slices/technology';

// Constants
import { BUTTON_TYPES, LABEL_TYPES } from '../../constants/common';

// Utils
import { isDarkMode, wordCapitalize } from '../../utils/common';
import { Avatar } from 'primereact/avatar';
import { TECHNOLOGY_COLORS_OPTIONS } from '../../constants/dropdownOptions';
import { Dropdown } from 'primereact/dropdown';

const AddEditTechnology = (props) => {
  const dispatch = useDispatch();
  const { show, onHide, ID } = props;
  const { technologyDetails } = useSelector((state) => state.technology);
  const { isSucceed, isLoading } = useSelector((state) => state.apiStatus);
  const [isUpdatedSuccess, setIsUpdatedSuccess] = useState(false);

  const initialData = {
    technology: '',
    parameter: [],
    technologyColor: '',
    technologyDarkModeColor: ''
  };

  useEffect(() => {
    ID && dispatch(getTechnologyById(ID));
  }, [dispatch, ID]);

  useEffect(() => {
    if (show && isUpdatedSuccess && isSucceed) {
      onHide(false);
      formik.resetForm();
      document.body.classList.remove('sidebar-open');
      dispatch(updateData());
      dispatch(apiStatusClear());
      setIsUpdatedSuccess(false);
    }
  }, [isSucceed]);

  useEffect(() => () => setIsUpdatedSuccess(false), []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: Yup.object({
      technology: Yup.string()
        .trim()
        .required('Technology is required.')
        .max(100, 'Must be 100 characters or less'),
      parameter: Yup.array()
        .of(Yup.string('Parameter should be type of string.'))
        .required('Parameter is required.')
        .min(0, 'Minimum 0 Parameter is required.'),
      technologyColor: Yup.string()
        .required('Technology color is required.')
        .trim()
        .min(6, 'Minimun 6 Characters is required.')
        .max(7, 'Minimun 6 Characters is required.'),
      technologyDarkModeColor: Yup.string()
        .required('Technology color is required.')
        .trim()
        .min(6, 'Minimun 6 Characters is required.')
        .max(7, 'Minimun 6 Characters is required.')
    }),

    onSubmit: (values) => {
      if (ID) {
        dispatch(
          editTechnology({
            formdata: {
              technology: values.technology,
              parameter: values.parameter.join(', '),
              technologyColor: values.technologyColor,
              technologyDarkModeColor: values.technologyDarkModeColor
            },
            ID: ID
          })
        );
      } else {
        dispatch(
          addTechnology({
            technology: values.technology,
            parameter: values.parameter && values.parameter.join(', '),
            technologyColor: values.technologyColor,
            technologyDarkModeColor: values.technologyDarkModeColor
          })
        );
      }
      setIsUpdatedSuccess(true);
    }
  });

  useEffect(() => {
    if (ID && technologyDetails && Object.keys(technologyDetails).length !== 0) {
      formik.setFieldValue('technology', technologyDetails?.Technology || []);
      formik.setFieldValue(
        'parameter',
        technologyDetails?.Parameters && technologyDetails?.Parameters.length > 0
          ? technologyDetails?.Parameters?.map((tech) => wordCapitalize(tech))
          : []
      );

      formik.setFieldValue('technologyColor', technologyDetails?.TechnologyColor || '');
      formik.setFieldValue('technologyDarkModeColor', technologyDetails?.DarkModeColor || '');
    }
  }, [technologyDetails]);

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const colorTemplete = (option) => {
    return (
      option && (
        <div className="center">
          <Avatar
            shape="circle"
            size="small"
            style={{
              marginRight: 10,
              background: isDarkMode() ? option.technologyDarkModeColor : option.technologyColor
            }}
          />
          <span className="p-ml-2">
            {isDarkMode() ? option.technologyDarkModeColorLabel : option.technologyColorLabel}
          </span>
        </div>
      )
    );
  };

  return (
    <Sidebar
      ID={ID}
      visible={show}
      onHide={() => {
        onHide(true);
        document.body.classList.remove('sidebar-open');
      }}
      position="right"
      className="sidebar-drawer">
      <div className="form-box-wrapper">
        <div className="title-wrapper">
          <p className="card-title">{ID ? 'Edit' : 'Add'} Technology</p>
          <button
            className="p-sidebar-close"
            onClick={() => {
              onHide(false);
              document.body.classList.remove('sidebar-open');
              formik.resetForm();
            }}>
            <span className="p-sidebar-close-icon pi pi-times" />
          </button>
        </div>
        <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
          <div className="form-row-wrapper">
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label htmlFor="technology" text={LABEL_TYPES.TECHNOLOGY} isMandatory />
                  <InputText
                    id="technology"
                    name="technology"
                    value={formik.values.technology}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Add Technology *"
                    className={classNames({
                      'p-invalid': isFormFieldValid('technology')
                    })}
                  />
                </div>
                {getFormErrorMessage('technology')}
              </div>
            </div>
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label
                    htmlFor="technology-color"
                    text={LABEL_TYPES.COLOR_PICKER}
                    isMandatory
                    isBold
                  />
                  <Dropdown
                    id="technologyColor"
                    name="technologyColor"
                    value={
                      isDarkMode()
                        ? formik.values.technologyDarkModeColor
                        : formik.values.technologyColor
                    }
                    optionLabel={
                      isDarkMode() ? 'technologyDarkModeColorLabel' : 'technologyColorLabel'
                    }
                    optionValue={isDarkMode() ? 'technologyDarkModeColor' : 'technologyColor'}
                    options={TECHNOLOGY_COLORS_OPTIONS}
                    onChange={(e) => {
                      const selectedValue = e.value; // The selected value (e.g., '#9575CD')
                      const selectedOption = TECHNOLOGY_COLORS_OPTIONS.find(
                        (option) =>
                          option.technologyColor === selectedValue ||
                          option.technologyDarkModeColor === selectedValue
                      );
                      formik.setFieldValue(
                        'technologyColor',
                        selectedOption?.technologyColor || ''
                      );
                      formik.setFieldValue(
                        'technologyDarkModeColor',
                        selectedOption?.technologyDarkModeColor || ''
                      );
                    }}
                    // optionLabel="label"
                    placeholder="Select a technology"
                    itemTemplate={colorTemplete}
                    valueTemplate={colorTemplete}
                  />
                  {getFormErrorMessage('technologyColor')}
                </div>
              </div>
            </div>
          </div>
          <div className="form-btn-wrapper">
            <CustomButton type="submit" variant="contained" className="" disabled={isLoading}>
              {BUTTON_TYPES.SAVE}
            </CustomButton>
            <CustomButton
              variant="contained"
              onClick={() => {
                onHide(false);
                document.body.classList.remove('sidebar-open');
                formik.resetForm();
              }}
              color="error"
              className="gray-btn">
              {BUTTON_TYPES.CANCEL}
            </CustomButton>
          </div>
        </form>
      </div>
    </Sidebar>
  );
};

export default AddEditTechnology;

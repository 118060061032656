import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Components
import CustomButton from '../../components/CustomButton';

// Prime-React-Components
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';

// Constants
import { BUTTON_TYPES, INITIAL_PAGE_NUMBER } from '../../constants/common';

// Redux-Slices
import { getJobDescriptionList, sendJobDescription } from '../../redux/actions/jobDescriptions';

// Utils

const SendJobDescription = (props) => {
  // Props.
  const { candidateId, show, onHide } = props;

  // Redux hooks.
  const dispatch = useDispatch();
  const { jobDescription } = useSelector((state) => state);

  // React useState hooks.
  const [descriptionOptions, setDescriptionOptions] = useState([]);

  // React useEffect hooks.
  useEffect(() => {
    dispatch(
      getJobDescriptionList({
        pageNumber: INITIAL_PAGE_NUMBER,
        size: 1000,
        searchValue: '',
        sortBy: 'DateModified',
        orderBy: 'desc'
      })
    );
  }, []);

  useEffect(() => {
    if (jobDescription?.data?.length) {
      const modifiedJobDescription = jobDescription?.data?.map((jd) => ({
        name: jd.Title,
        code: jd.ID
      }));
      setDescriptionOptions(modifiedJobDescription);
    }
  }, [jobDescription]);

  const formik = useFormik({
    initialValues: {
      jobDescription: ''
    },
    validationSchema: Yup.object().shape({
      jobDescription: Yup.string().required('Please select Job Description')
    }),
    onSubmit: (values) => {
      dispatch(sendJobDescription({ candidateId, jobDescriptionId: values.jobDescription }));
      onHide();
    }
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name, additionalError = '') => {
    let response = null;
    if (isFormFieldValid(name) || additionalError) {
      response = <small className="p-error">{formik.errors[name] || additionalError}</small>;
    }
    return response;
  };

  return (
    <Dialog
      header="Select Job description from below list"
      className={classNames('card overflow-hidden resize-none')}
      visible={show}
      style={{ width: '500px' }}
      onHide={onHide}>
      <div className="form-box-wrapper feedback-form-popup">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-row-wrapper">
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Dropdown
                    name="jobDescription"
                    id="jobDescription"
                    optionLabel="name"
                    optionValue="code"
                    value={formik.values.jobDescription}
                    options={descriptionOptions}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Select"
                    className={classNames({
                      'p-invalid': isFormFieldValid('jobDescription'),
                      'w-full': true
                    })}
                  />
                  {getFormErrorMessage('jobDescription')}
                </div>
              </div>
            </div>
          </div>
          <div className="p-dialog-footer block">
            <CustomButton
              type="submit"
              variant="contained"
              disabled={!!Object.keys(formik.errors)?.length}>
              {BUTTON_TYPES.SEND}
            </CustomButton>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default SendJobDescription;

import {
  FORGOT_PASSWORD_REQUEST,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  RESET_PASSWORD_REQUEST
} from '../../constants/redux';

export const loginHandler = (credentials) => {
  return {
    type: LOGIN_REQUEST,
    payload: credentials
  };
};

export const logoutHandler = () => {
  return {
    type: LOGOUT_REQUEST
  };
};

export const forgotPassword = (email) => {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    payload: email
  };
};

export const resetPassword = (credentials) => {
  return {
    type: RESET_PASSWORD_REQUEST,
    payload: credentials
  };
};

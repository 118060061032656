import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const profileDetailsSlice = createSlice({
  name: 'profileDetail',
  initialState: initialState,
  reducers: {
    getUserProfileDetails(state, action) {
      return { ...state, ...action.payload };
    },
    getUserPermission(state, action) {
      return {
        ...state,
        ...action.payload
      };
    }
  }
});

export const { getUserProfileDetails, getUserPermission } = profileDetailsSlice.actions;
export default profileDetailsSlice.reducer;

export const DROPDOWN_OPTIONS = [
  { name: 'Technical Interview - 1 Pending', value: 1 },
  { name: 'Technical Interview - 1 Schedule', value: 2 },
  { name: 'Technical Interview - 1 Reschedule', value: 11 },
  { name: 'Technical Interview - 1 Complete', value: 3 },
  { name: 'Practical Pending', value: 4 },
  { name: 'Practical Schedule', value: 5 },
  { name: 'Practical Reschedule', value: 12 },
  { name: 'Practical Complete', value: 6 },
  { name: 'HR Pending', value: 7 },
  { name: 'HR Schedule', value: 8 },
  { name: 'HR Reschedule', value: 21 },
  { name: 'HR Complete', value: 9 },
  { name: 'Cancelled', value: 10 },
  { name: 'Technical Interview - 2 Pending', value: 13 },
  { name: 'Technical Interview - 2 Schedule', value: 14 },
  { name: 'Technical Interview - 2 Reschedule', value: 15 },
  { name: 'Technical Interview - 2 Complete', value: 16 },
  { name: 'Management Pending', value: 17 },
  { name: 'Management Schedule', value: 18 },
  { name: 'Management Reschedule', value: 19 },
  { name: 'Management Complete', value: 20 }
];

export const INVITATIONS_DROPDOWN_OPTIONS = [
  { name: 'Pending', value: 1 },
  { name: 'Accepted', value: 2 },
  { name: 'Rejected', value: 3 },
  { name: 'Accepted by Others', value: 4 }
];

export const INTERVIEWERS_DATE_FILTER_DROPDOWN_OPTIONS = [
  { name: 'Last 15 Days', value: 15 },
  { name: 'Last 30 Days', value: 30 }
];

export const TECHNOLOGY_COLORS_OPTIONS = [
  {
    technologyColor: '#9575CD',
    technologyDarkModeColor: '#361C63',
    technologyColorLabel: '#9575CD',
    technologyDarkModeColorLabel: '#361C63'
  },
  {
    technologyColor: '#FF8A65',
    technologyDarkModeColor: '#883318',
    technologyColorLabel: '#FF8A65',
    technologyDarkModeColorLabel: '#883318'
  },
  {
    technologyColor: '#64B5F6',
    technologyDarkModeColor: '#096465',
    technologyColorLabel: '#64B5F6',
    technologyDarkModeColorLabel: '#096465'
  },
  {
    technologyColor: '#90A4AE',
    technologyDarkModeColor: '#254555',
    technologyColorLabel: '#90A4AE',
    technologyDarkModeColorLabel: '#254555'
  },
  {
    technologyColor: '#4FC3F7',
    technologyDarkModeColor: '#035174',
    technologyColorLabel: '#4FC3F7',
    technologyDarkModeColorLabel: '#035174'
  },
  {
    technologyColor: '#F06292',
    technologyDarkModeColor: '#AA385F',
    technologyColorLabel: '#F06292',
    technologyDarkModeColorLabel: '#AA385F'
  },
  {
    technologyColor: '#81C784',
    technologyDarkModeColor: '#156A0C',
    technologyColorLabel: '#81C784',
    technologyDarkModeColorLabel: '#156A0C'
  },
  {
    technologyColor: '#4DD0E1',
    technologyDarkModeColor: '#2A707A',
    technologyColorLabel: '#4DD0E1',
    technologyDarkModeColorLabel: '#2A707A'
  },
  {
    technologyColor: '#AED581',
    technologyDarkModeColor: '#6A9637',
    technologyColorLabel: '#AED581',
    technologyDarkModeColorLabel: '#6A9637'
  }
];

export const DROPDOWN_STATUS_OPTIONS = [
  { name: 'All', value: 'All' },
  { name: 'Technical', value: 'Technical' },
  { name: 'Practical', value: 'Practical' },
  { name: 'HR Round', value: 'HR Round' }
];

export const HR_DASHBOARD_DROPDOWN_OPTIONS = [
  { name: 'All', value: 'All' },
  { name: 'Scheduled', value: 'Scheduled' },
  { name: 'Pending', value: 'Pending' },
  { name: 'Rescheduled', value: 'Rescheduled' }
];

export const MONTH_NAME_OPTIONS = [
  { name: 'All', value: 'All' },
  { name: 'January', value: 'January' },
  { name: 'February ', value: 'February' },
  { name: 'March', value: 'March' },
  { name: 'April', value: 'April' },
  { name: 'May', value: 'May' },
  { name: 'June', value: 'June' },
  { name: 'July', value: 'July' },
  { name: 'August', value: 'August' },
  { name: 'September', value: 'September' },
  { name: 'October', value: 'October' },
  { name: 'November', value: 'November' },
  { name: 'December', value: 'December' }
];

export const PAGINATION_OPTIONS = [10, 25, 50, 100];

export const NUMBER_OF_INTERVIEWERS = ['1', '2', '3', '4'];

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  invitationData: { data: [] }
};

const interviewInvitaionSlice = createSlice({
  name: 'interviewInvitation',
  initialState,
  reducers: {
    addInterviewInvitations(state, action) {
      const { data, pagination } = action.payload;
      return { data, pagination };
    }
  }
});
export const { addInterviewInvitations } = interviewInvitaionSlice.actions;
export default interviewInvitaionSlice.reducer;

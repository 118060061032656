import {
  ADD_COMMON_PARAMS_REQUEST,
  COMMON_PARAMS_LIST_REQUEST,
  GET_COMMON_PARAMS_DETAILS,
  EDIT_COMMON_PARAMS_REQUEST,
  DELETE_COMMON_PARAMS_REQUEST,
  FILTER_COMMON_PARAMS_LIST_REQUEST
} from '../../constants/redux';

export const getCommonParamsList = (payload) => {
  return {
    type: COMMON_PARAMS_LIST_REQUEST,
    payload
  };
};

export const getFilterCommonParamsList = (payload) => {
  return {
    type: FILTER_COMMON_PARAMS_LIST_REQUEST,
    payload
  };
};

export const addCommonParams = (technology) => {
  return {
    type: ADD_COMMON_PARAMS_REQUEST,
    payload: technology
  };
};

export const editCommonParames = (Details) => {
  return {
    type: EDIT_COMMON_PARAMS_REQUEST,
    payload: Details
  };
};

export const deleteCommonParames = (ID) => {
  return {
    type: DELETE_COMMON_PARAMS_REQUEST,
    payload: ID
  };
};

export const getCommonParamsById = (ID) => {
  return {
    type: GET_COMMON_PARAMS_DETAILS,
    payload: ID
  };
};

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

// Redux-Actions
import {
  getInterviewerAvailiblityByFilterAction,
  // getInterviewersListBySearchNameAction,
  getInterviewTechnology
} from '../../redux/actions/hr';

// Utils
import {
  formatExperience,
  getUserRole,
  isDarkMode,
  normalizeObject,
  wordCapitalize
} from '../../utils/common';
import { getFormattedDate, getUpdatedYearsAndMonths } from '../../utils/date';

// Constants
import {
  DEBOUNCE_TIMEOUT,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  TABLE_NAME
} from '../../constants/common';
import DataTable from '../../components/DataTable';
import { HR_INTERVIEWERS_COLUMNS } from '../../constants/tableColumns';
import { Link } from 'react-router-dom';
import { debounce } from '../../utils/debounce';
import UserDetails from '../../components/popup/UserDetails';
import { userDetailsAction } from '../../redux/actions/userDetails';
import ReactTooltip from 'react-tooltip';
import { INTERVIEWERS_DATE_FILTER_DROPDOWN_OPTIONS } from '../../constants/dropdownOptions';
import { Chip } from '@mui/material';

const initialDateValue = dayjs();

const interviewersTableStates = {
  pageNumber: INITIAL_PAGE_NUMBER,
  size: INITIAL_SIZE,
  sortBy: 'DateModified',
  orderBy: 'desc',
  searchName: '',
  startDateFilter: null,
  technology: ''
};

const Schedule = () => {
  // Redux hooks
  const dispatch = useDispatch();
  const { pagination } = useSelector((state) => state.hrs);
  const interviewerAvailiblityList = useSelector((state) => state.hrs.interviewerAvailiblityList);
  const technologyListData = useSelector((state) => state.hrs.interviewTechnologyType);
  const { userDetails } = useSelector((state) => state);

  // React useState hooks
  const [interviewerList, setInterviewerList] = useState([]);
  const [interviewerDetails, setInterviewerDetails] = useState([]);
  const [interviewerId, setInterviewerId] = useState(null);
  const [handleSearchAndFilter, setHandleSearchAndFilter] = useState(interviewersTableStates);
  const calendarRef = useRef();
  const [showPopup, setShowPopup] = useState(false);
  const onTogglePopup = () => setShowPopup((prevState) => !prevState);

  // React useEffect hooks
  useEffect(() => {
    if (interviewerAvailiblityList && interviewerAvailiblityList.length) {
      const setData = interviewerAvailiblityList?.map((interviewer) => {
        const availabilityOfInterviewer = interviewer?.InterviewerToAvailability?.map(
          (availability) => {
            return {
              ...availability,
              title: `${wordCapitalize(interviewer?.FirstName)} ${wordCapitalize(
                interviewer?.LastName
              )}`,
              start: `${getFormattedDate(initialDateValue)} ${
                availability.StartTimeOfAvailability || ''
              }`,
              end: `${getFormattedDate(initialDateValue)} ${
                availability.EndTimeOfAvailability || ''
              }`,
              interviewerId: interviewer?.ID,
              url: `/hr/interviews?interviewId=${interviewer?.ID}&startDate=${getFormattedDate(
                initialDateValue
              )}&startTime=${availability.StartTimeOfAvailability}&technology=`
            };
          }
        );
        return availabilityOfInterviewer;
      });

      let availabilityList = [];
      setData.map((list) => {
        if (list.length) {
          availabilityList.push(...list);
        }
      });
    }
  }, [interviewerAvailiblityList]);

  useEffect(() => {
    interviewerId && dispatch(userDetailsAction(interviewerId));
  }, [dispatch, interviewerId]);

  const modifyUserDetails = useCallback(() => {
    if (userDetails?.ID) {
      let updatedYearsAndMonths = getUpdatedYearsAndMonths(
        userDetails.YearsOfExperience,
        userDetails?.MonthsOfExperience,
        userDetails.ExpirenceUPdatedDateTime
      );
      setInterviewerDetails([
        {
          title: 'Name',
          value: `${wordCapitalize(userDetails?.FirstName || '')} ${wordCapitalize(
            userDetails?.LastName || ''
          )}`
        },
        { title: 'Role', value: getUserRole(userDetails?.UserTypeId || '') },
        { title: 'Gender', value: userDetails?.Gender === 1 ? 'Male' : 'Female' || '' },
        {
          title: 'Skills',
          value: userDetails?.UMap?.length
            ? userDetails?.UMap?.map((tech) => wordCapitalize(tech?.MapT?.Technology)).join(' | ')
            : ''
        },
        {
          title: 'Experience',
          value: formatExperience(
            updatedYearsAndMonths.updatedYears,
            updatedYearsAndMonths.updatedMonths
          )
        },
        {
          title: 'Will take Practical Round?',
          value: userDetails.IsPracticalAllow ? 'Yes' : 'No'
        },
        {
          title: 'Email Address',
          value: (
            <div className="center">
              <span>{userDetails?.Email || '--'}</span>
              {userDetails?.Email && (
                <>
                  <i
                    data-tip="Copied"
                    data-event="click focus"
                    data-for={`copyIcon_${userDetails?.Email}`}
                    id={`copyIcon_${userDetails?.Email}`}
                    className="pi pi-fw pi-copy ml-1 copy-icon"
                  />
                  <ReactTooltip
                    id={`copyIcon_${userDetails?.Email}`}
                    globalEventOff="click"
                    afterShow={() => {
                      navigator.clipboard.writeText(userDetails?.Email);
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 500);
                    }}
                  />
                </>
              )}
            </div>
          )
        },
        {
          title: 'Mobile',
          value: (
            <div className="center">
              <span>{userDetails?.Mobile || '--'}</span>
              {userDetails?.Mobile && (
                <>
                  <i
                    data-tip="Copied"
                    data-event="click focus"
                    data-for={`copyIcon_${userDetails?.Mobile}`}
                    id={`copyIcon_${userDetails?.Mobile}`}
                    className="pi pi-fw pi-copy ml-1 copy-icon"
                  />
                  <ReactTooltip
                    id={`copyIcon_${userDetails?.Mobile}`}
                    globalEventOff="click"
                    afterShow={() => {
                      navigator.clipboard.writeText(userDetails?.Mobile);
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 500);
                    }}
                  />
                </>
              )}
            </div>
          )
        }
      ]);
    }
  }, [userDetails]);

  useEffect(() => {
    modifyUserDetails();
  }, [userDetails, modifyUserDetails]);

  // Call an API on component mount only to get the list of technologies.
  useEffect(() => {
    dispatch(getInterviewTechnology());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getInterviewerAvailiblityByFilterAction(handleSearchAndFilter));
  }, [initialDateValue, dispatch, handleSearchAndFilter]);

  const handleView = (ID) => {
    if (ID) {
      onTogglePopup();
      setInterviewerId(ID);
    }
  };

  useEffect(() => {
    const modifiedCandidate = interviewerAvailiblityList?.map((list) => {
      let updatedYearsAndMonths = getUpdatedYearsAndMonths(
        list.YearsOfExperience,
        list?.MonthsOfExperience,
        list.ExpirenceUPdatedDateTime
      );
      return {
        ...list,
        Name: (
          <Link to="#" className="table-view-popup-link" onClick={() => handleView(list.ID)}>
            {`${wordCapitalize(list.FirstName)} ${wordCapitalize(list.LastName)}`}
          </Link>
        ),
        Technology: (
          <ul className="technology-list">
            {list.UMap && list?.UMap?.length > 0
              ? list.UMap?.map((tech) => (
                  <li
                    key={tech?.MapT?.Technology}
                    className="technology-badge"
                    style={{
                      backgroundColor: `${
                        isDarkMode() ? tech?.MapT?.DarkModeColor : tech?.MapT?.TechnologyColor
                      }`
                    }}>
                    {tech?.MapT?.Technology}
                  </li>
                ))
              : []}
          </ul>
        ),
        Practical: (
          <Chip
            color={list?.IsPracticalAllow ? 'success' : 'error'}
            label={list?.IsPracticalAllow ? 'Yes' : 'No'}
          />
        ),
        'Interviews Taken': list?.InvitationsCount || '--',
        Experience: formatExperience(
          updatedYearsAndMonths.updatedYears,
          updatedYearsAndMonths.updatedMonths
        )
      };
    });

    setInterviewerList(modifiedCandidate);
  }, [interviewerAvailiblityList, isDarkMode()]);

  useEffect(() => {
    if (calendarRef?.current && initialDateValue) {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(initialDateValue);
    }
  }, [initialDateValue]);

  const handlePagination = (pageNumber, size) =>
    setHandleSearchAndFilter((prevState) => {
      return {
        ...prevState,
        pageNumber,
        size
      };
    });

  const handleSearch = (searchValue) => {
    setHandleSearchAndFilter({
      ...handleSearchAndFilter,
      searchName: searchValue
    });
  };

  const handleSort = (sortBy) => {
    setHandleSearchAndFilter((prevState) => {
      return {
        ...prevState,
        sortBy,
        orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
      };
    });
  };

  const handleStatusFilter = (value) => {
    setHandleSearchAndFilter({
      ...handleSearchAndFilter,
      technology: value || ''
    });
  };

  const handleStartDateFilter = (value) => {
    setHandleSearchAndFilter({
      ...handleSearchAndFilter,
      startDateFilter: value || null
    });
  };

  return (
    <>
      <UserDetails
        header="Interviewer Details"
        data={interviewerDetails}
        onHide={onTogglePopup}
        show={showPopup}
      />
      <DataTable
        name={TABLE_NAME.INTERVIEWERS}
        totalRecords={pagination?.totalRecords}
        onPagination={handlePagination}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        searchPlaceHolder="Search by Interviewer name, Email and Mobile"
        onSort={handleSort}
        data={interviewerList}
        isDropdownMultiSelect
        dropdown={
          technologyListData?.length
            ? technologyListData.map((tech) => ({ name: tech.Technology, value: tech.ID }))
            : []
        }
        dropdownValue={handleSearchAndFilter.technology}
        onSecondDropdownChange={handleStartDateFilter}
        secondDropdown={INTERVIEWERS_DATE_FILTER_DROPDOWN_OPTIONS || []}
        secondDropdownPlaceHolder="Select Status"
        onStatusFilter={handleStatusFilter}
        secondDropdownValue={handleSearchAndFilter.startDateFilter}
        dropdownPlaceHolder="Select Technology"
        columns={HR_INTERVIEWERS_COLUMNS}
        clearFilter
        isClearFilterDisabled={
          JSON.stringify(normalizeObject(interviewersTableStates)) ===
          JSON.stringify(normalizeObject(handleSearchAndFilter))
        }
        clearFilterOnClick={() => setHandleSearchAndFilter(interviewersTableStates)}
      />
    </>
  );
};

export default Schedule;

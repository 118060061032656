// Firebase messaging
import { deleteToken, getMessaging, getToken, onMessage } from 'firebase/messaging';
import { isSupported } from 'firebase/messaging';

// Firebase initializedApp
import { firebaseInstance } from './firebase';
import { messagingInstanceExists } from '../redux/slices/notification';

// Utils
import { getDataFromLocalstorage, setDataInLocalstorage } from '../utils/common';

// Constants
import { FCM_TOKEN, PERMISSIONS } from '../constants/common';

export let messagingInstance;

export const initializeMessaging = (dispatch) => {
  if (firebaseInstance && !messagingInstance) {
    dispatch(messagingInstanceExists(false));
    isSupported()
      .then((value) => {
        if (value) {
          messagingInstance = getMessaging(firebaseInstance);
          dispatch(messagingInstanceExists(true));
        }
      })
      .catch((err) => console.log(err));
  }
};

const getVapidKey = () => {
  if (process.env && process.env.REACT_APP_VAPID_KEY) {
    return { vapidKey: `${process.env.REACT_APP_VAPID_KEY}` };
  }

  return;
};

export const requestForToken = async () => {
  try {
    const permission = await Notification.requestPermission();
    const vapidKey = getVapidKey();
    if (permission === PERMISSIONS.GRANTED) {
      const fcmToken = getDataFromLocalstorage(FCM_TOKEN);
      if (!fcmToken && vapidKey && messagingInstance) {
        const savedToken = await getToken(messagingInstance, vapidKey);
        if (savedToken) {
          setDataInLocalstorage(FCM_TOKEN, savedToken);
          return {
            token: savedToken,
            permission
          };
        }
      } else if (!vapidKey) {
        console.log('Vapid key is missing in firebase configuration');
      } else {
        return { permission, token: fcmToken };
      }
    } else
      return {
        permission,
        token: ''
      };
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};

export const onMessageListener = () => {
  if (messagingInstance) {
    return new Promise((resolve) => {
      onMessage(messagingInstance, (payload) => {
        resolve(payload);
      });
    });
  }
};

export const deleteFCMToken = () => {
  if (messagingInstance) {
    deleteToken(messagingInstance)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }
};

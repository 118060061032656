import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Prime-React Components
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';
import { RadioButton } from 'primereact/radiobutton';
import { InputSwitch } from 'primereact/inputswitch';
import { confirmDialog } from 'primereact/confirmdialog';

// Component
import Label from '../../components/Label';
import { CustomeTooltip } from '../../components/ActionButtons';
import CustomButton from '../../components/CustomButton';
import AvtarImage from './AvtarImage';
import { TextAreaWithCounterLimit } from '../../components/TextAreaWithCounterLimit';

// Constants
import {
  ROUTES,
  BUTTON_TYPES,
  LABEL_TYPES,
  APLHABET_PATTERN_REGEX,
  ROLES,
  TEXTAREA_LIMIT
} from '../../constants/common';

// Redux-Actions
import {
  editUserProfileDetails,
  getUserPermissionRequestAction,
  getUserProfileDetails,
  userPermissionChangeRequestAction
} from '../../redux/actions/profileDetails';
import { getInterviewTechnology } from '../../redux/actions/hr';

// Redux-slices
import { notificationPermissionAlertClosed } from '../../redux/slices/notification';

// Utils
import { wordCapitalize } from '../../utils/common';
import { onSuggestSkils } from '../../utils/pageUtils';

//Apis
import { MultiSelect } from 'primereact/multiselect';
import UserProfileUpdatePassword from './UserProfileUpdatePassword';

const pathBase = `${process.env.REACT_APP_API_ENDPOINT}/public/uploads/profile`;
const UserProfile = () => {
  // Redux hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const interviewTechnologyType = useSelector((state) => state.hrs.interviewTechnologyType);
  const { userId, userType, firstName, lastName, userProfile } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.apiStatus);
  const profileDetails = useSelector((state) => state.profileDetails);
  const portal = location?.pathname?.toString().split('/').at(1);

  // react-router-dom hook
  const navigate = useNavigate();

  // React useState hooks
  const [softSkilSuggestion, setSoftSkilSuggestion] = useState([]);
  const [imagePrev, setImagePrev] = useState(null);
  const [removeImage, setRemoveImage] = useState(false);
  const [redirection, setRedirection] = useState(false);
  const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(false);
  const [userProfileData, setUserProfileData] = useState({
    isAdmin: false,
    isHR: false,
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    gender: 1,
    address: '',
    yearsOfExperience: 0,
    monthsOfExperience: 0,
    techStack: [],
    skills: [],
    removeImage: false,
    isPracticalAllow: false
  });
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(true);

  // React useRef hook
  const fileRef = useRef(null);

  const userName = `${wordCapitalize(firstName || '')} ${wordCapitalize(lastName || '')}`;
  const { UserPermissions } = profileDetails;

  useEffect(() => {
    dispatch(getUserProfileDetails());
    dispatch(getInterviewTechnology());
    dispatch(getUserPermissionRequestAction());
  }, [dispatch]);

  const handleSuggestion = (event) => {
    setSoftSkilSuggestion(onSuggestSkils(event));
  };

  useEffect(() => {
    if (UserPermissions) {
      setIsNotificationEnabled(UserPermissions.IsNotificationEnable);
    }
  }, [UserPermissions]);

  useEffect(() => {
    if (redirection) {
      switch (userType) {
        case ROLES.ADMIN:
          return navigate(ROUTES.ADMIN.DASHBOARD);
        case ROLES.HR:
          return navigate(ROUTES.HR.DASHBOARD);
        case ROLES.INTERVIEWER:
          return navigate(ROUTES.INTERVIEWER.DASHBOARD);
        case ROLES.HR_AND_INTERVIEWER:
          return navigate(portal === 'hr' ? ROUTES.HR.DASHBOARD : ROUTES.INTERVIEWER.DASHBOARD);
      }
    }
  }, [redirection, userType, navigate, dispatch]);

  useEffect(() => {
    if (profileDetails && Object.keys(profileDetails).length !== 0) {
      setUserProfileData({
        isAdmin: userType === ROLES.ADMIN,
        isHR: userType === ROLES.HR,
        isInterviewer: userType === ROLES.INTERVIEWER,
        firstName: `${profileDetails.FirstName}`,
        lastName: `${profileDetails.LastName}`,
        email: `${profileDetails.Email || ''}`,
        mobile: profileDetails?.Mobile || '',
        gender: parseInt(profileDetails.Gender),
        address: `${profileDetails.Address || ''}`,
        monthsOfExperience: `${profileDetails.MonthsOfExperience || 0}`,
        yearsOfExperience: `${profileDetails.YearsOfExperience || 0}`,
        techStack: profileDetails?.TechStack || [],
        isPracticalAllow: !!profileDetails?.IsPracticalAllow,
        skills: profileDetails?.UMap?.length
          ? profileDetails?.UMap?.map((tech) => tech.MapT.ID)
          : []
      });
    }
  }, [profileDetails, userType]);

  const formik = useFormik({
    initialValues: userProfileData,
    validationSchema: Yup.object({
      isAdmin: Yup.boolean(),
      isInterviewer: Yup.boolean(),
      firstName: Yup.string()
        .trim()
        .max(15, 'Must be 15 characters or less')
        .required('First Name is required.')
        .matches(APLHABET_PATTERN_REGEX, 'First name should only contain alphabetical characters.'),
      lastName: Yup.string()
        .trim()
        .max(20, 'Must be 20 characters or less')
        .required('Last Name is required.')
        .matches(APLHABET_PATTERN_REGEX, 'Last name should only contain alphabetical characters.'),
      email: Yup.string()
        .trim()
        .email('Enter a valid email address')
        .required('Email Address is required.'),
      mobile: Yup.string()
        .trim()
        .matches(/^[0-9]{10}$/, 'Mobile Number Must Be 10 Digits Only.')
        .required('Mobile Number is required.'),
      gender: Yup.string(['1', '2']).required('Gender is required.'),
      address: Yup.string()
        .trim()
        .min(TEXTAREA_LIMIT.MIN, 'Address should be at least ${min} characters.')
        .max(TEXTAREA_LIMIT.MAX, 'Address should not exceed ${max} characters.'),
      yearsOfExperience: Yup.number().when('isAdmin', {
        is: false,
        then: Yup.number()
          .min(0, 'Minimum 0 Year.')
          .max(100, 'Maximum 100 Years.')
          .typeError('Only numbers are allowed.')
          .required('Exp. in Years is Required.')
      }),
      monthsOfExperience: Yup.number().when('isAdmin', {
        is: false,
        then: Yup.number()
          .min(0, 'Minimum 0 Month.')
          .max(11, 'Maximum 11 Months.')
          .required('Exp. in Months is required.')
          .typeError('Only numbers are allowed.')
      }),
      techStack: Yup.mixed().when('isHR', {
        is: true,
        then: Yup.array()
          .of(Yup.string('Should be of type string.'))
          .min(1, 'Please select at least one technology.')
          .required('Technologies are required.'),
        otherwise: Yup.array().of(Yup.string('Should be of type string.')).min(0)
      }),
      skills: Yup.mixed().when('isInterviewer', {
        is: true,
        then: Yup.array()
          .of(Yup.string('Should be of type string.'))
          .min(1, 'Please select at least one skill.')
          .required('Skills are required.'),
        otherwise: Yup.array().of(Yup.string('Should be of type string.')).min(0)
      }),
      profileImage: Yup.mixed().test(
        'format',
        'Only the following formats are accepted: .jpeg, .jpg, .png',
        (value) => {
          if (!value) {
            return true;
          }
          return (
            value &&
            (value.type === 'image/jpeg' ||
              value.type === 'image/jpg' ||
              value.type === 'image/png')
          );
        }
      )
    }),
    onSubmit: (values) => {
      try {
        const formData = {
          firstName: values.firstName,
          lastName: values.lastName,
          gender: values.gender,
          address: values.address,
          mobile: values.mobile,
          isPracticalAllow: values.isPracticalAllow,
          removeImage,
          isHR: userType === ROLES.HR,
          techStack: values.techStack,
          skills: values.skills
        };
        let postData = new FormData();
        Object.keys(formData).forEach((el) => {
          if (el === 'skills') {
            for (var i = 0; i < formData[el].length; i++) {
              postData.append('skills[]', formData[el][i]);
            }
          } else if (typeof el === 'string') {
            postData.append(el, formData[el]);
          } else {
            postData.append(el, JSON.stringify(formData[el]));
          }
        });
        imagePrev && postData.append('profileImage', values.profileImage);
        !userProfileData.isAdmin &&
          postData.append('monthsOfExperience', values.monthsOfExperience);
        !userProfileData.isAdmin && postData.append('yearsOfExperience', values.yearsOfExperience);
        // Call the dispatch method to update the user profile.
        dispatch(editUserProfileDetails(postData));
      } catch (error) {
        console.log(error);
      }
    },
    enableReinitialize: true
  });

  const isFormFieldValid = (name) => formik.errors[name];
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const imageName = profileDetails?.UserProfile || '';
  const encodedPath = imageName
    .split('/')
    .map((p) => encodeURIComponent(p))
    .join('/');

  useEffect(() => {
    if (removeImage === true) {
      formik.setFieldValue('profileImage', null);
      setImagePrev(null);
    }
  }, [removeImage]);

  const removeImageConfirmation = () => {
    confirmDialog({
      header: 'Delete',
      message: 'Are you sure you want to delete profile image?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => setRemoveImage(true)
    });
  };

  return (
    <>
      <CustomeTooltip />
      <div className="user-profile-wrapper">
        <form onSubmit={formik.handleSubmit} className="form-main" encType="multipart/form-data">
          <div className="left-col">
            <div className="form-col">
              <div className="profile-box">
                <div className="box-inner">
                  <div className="profile-img">
                    {profileDetails?.UserProfile && !imagePrev && !removeImage ? (
                      <img
                        src={`${pathBase}/${userId}/${encodedPath}`}
                        alt="profile"
                        className="user-img"
                      />
                    ) : !imagePrev || removeImage ? (
                      <AvtarImage className="user-img" firstName={firstName} lastName={lastName} />
                    ) : (
                      <img src={imagePrev} alt="profile" className="user-img" />
                    )}
                    <input
                      hidden
                      ref={fileRef}
                      type="file"
                      id="profileImage"
                      name="profileImage"
                      onChange={(e) => {
                        if (e.target.files[0]) {
                          formik.setFieldValue('profileImage', e.target.files[0]);
                          if (
                            ['image/png', 'image/jpeg', 'image/jpg'].includes(
                              e.target.files[0].type
                            )
                          )
                            setImagePrev(URL.createObjectURL(e.target.files[0]));
                          else setImagePrev(false);
                          setRemoveImage(false);
                        }
                      }}
                      accept="image/png, image/jpeg ,image/jpg"
                      className={classNames({
                        'p-invalid': isFormFieldValid('profileImage')
                      })}
                    />

                    <div className="user-details">
                      <div className="user-name">{userName}</div>
                    </div>
                  </div>
                  <div className="edit-delete-btn">
                    <Button
                      type="button"
                      className="border-none edit-btn"
                      icon="pi pi-pencil"
                      label={!userProfile ? 'Upload' : 'Change'}
                      onClick={() => {
                        fileRef.current.click();
                      }}
                    />
                    <Button
                      disabled={!userProfile ? 'disabled' : ''}
                      className="border-none delete-btn"
                      icon="pi pi-trash"
                      label="Delete"
                      type="button"
                      onClick={() => removeImageConfirmation()}
                    />
                  </div>
                  {
                    <small className="p-error">
                      {formik.errors['profileImage'] && formik.errors['profileImage']}
                    </small>
                  }
                </div>
              </div>
              <div className="notification-outer">
                {userType === ROLES.HR ||
                userType === ROLES.INTERVIEWER ||
                userType === ROLES.HR_AND_INTERVIEWER ? (
                  <div className="notification-div">
                    <Label htmlFor="Notification" text={LABEL_TYPES.NOTIFICATION} />
                    <InputSwitch
                      checked={isNotificationEnabled}
                      onChange={(e) => {
                        setIsNotificationEnabled(e.value);
                        if (e.value) {
                          dispatch(notificationPermissionAlertClosed(false));
                        }
                        dispatch(
                          userPermissionChangeRequestAction({
                            isNotificationEnable: e.value,
                            pushNotification: true
                          })
                        );
                      }}
                    />
                  </div>
                ) : (
                  ''
                )}
                <div className="notification-div reset-password-div">
                  <div
                    className="text-right font-medium no-underline text-blue-500 cursor-pointer primary-link"
                    onClick={() => setShowChangePasswordPopup(true)}>
                    Change Password
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-col white-box">
            <div className="form-row-wrapper">
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="firstName" text={LABEL_TYPES.FIRST_NAME} isMandatory />
                    <InputText
                      id="firstName"
                      name="firstName"
                      onChange={formik.handleChange}
                      value={formik.values.firstName}
                      placeholder={LABEL_TYPES.FIRST_NAME + ' *'}
                      className={classNames({
                        'p-invalid': isFormFieldValid('firstName')
                      })}
                    />
                  </div>
                  {getFormErrorMessage('firstName')}
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="lastName" text={LABEL_TYPES.LAST_NAME} isMandatory />
                    <InputText
                      id="lastName"
                      name="lastName"
                      onChange={formik.handleChange}
                      value={formik.values.lastName}
                      placeholder={LABEL_TYPES.LAST_NAME + ' *'}
                      className={classNames({
                        'p-invalid': isFormFieldValid('lastName')
                      })}
                    />
                  </div>
                  {getFormErrorMessage('lastName')}
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="email" text={LABEL_TYPES.EMAIL} isMandatory />
                    <InputText
                      id="email"
                      disabled
                      name="email"
                      value={formik.values.email}
                      placeholder={LABEL_TYPES.EMAIL + ' *'}
                    />
                  </div>
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="mobile" text={LABEL_TYPES.MOBILE} isMandatory />
                    <InputText
                      id="mobile"
                      name="mobile"
                      keyfilter="int"
                      onChange={formik.handleChange}
                      value={formik.values.mobile}
                      placeholder={LABEL_TYPES.MOBILE + ' *'}
                      className={classNames({
                        'p-invalid': isFormFieldValid('mobile')
                      })}
                    />
                  </div>
                  {getFormErrorMessage('mobile')}
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <TextAreaWithCounterLimit
                      placeholder={LABEL_TYPES.ADDRESS}
                      labelText={LABEL_TYPES.ADDRESS}
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      errorMessage={formik.touched['address'] ? formik.errors['address'] : ''}
                      className={classNames({
                        'p-invalid': isFormFieldValid('address')
                      })}
                      rowsValue={1}
                      id="address"
                      name="address"
                      htmlFor="address"
                      isMandatory
                      validationOnMinLimit
                    />
                  </div>
                </div>
              </div>
              <div className="form-col">
                <div className="form-group-outer">
                  <div className="custom-form-group margin-bottom">
                    <Label htmlFor="gender" text={LABEL_TYPES.GENDER} isMandatory isBold />
                    <div className="radio-list-wrapper">
                      <div className="form-group-outer-radiobutton">
                        <RadioButton
                          inputId="male"
                          value={1}
                          name="gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.gender === 1}
                        />
                        <Label htmlFor="male" text={LABEL_TYPES.MALE} isBold />
                      </div>
                      <div className="form-group-outer-radiobutton">
                        <RadioButton
                          inputId="female"
                          value={2}
                          name="gender"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.gender === 2}
                        />
                        <Label htmlFor="female" text={LABEL_TYPES.FEMALE} isBold />
                      </div>
                    </div>
                  </div>
                  {userProfileData.isInterviewer ||
                    (userType === ROLES.HR_AND_INTERVIEWER && (
                      <div className="custom-form-group">
                        <Label for="isPracticalAllow" text={LABEL_TYPES.PRACTICAL_ROUND} />
                        <InputSwitch
                          checked={formik.values.isPracticalAllow}
                          onChange={(e) => {
                            formik.setFieldValue('isPracticalAllow', e.value);
                          }}
                        />
                      </div>
                    ))}
                  {getFormErrorMessage('gender')}
                </div>
              </div>

              {!userProfileData.isAdmin ? (
                <>
                  <div className="form-col full-width">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label htmlFor="mobile" text={LABEL_TYPES.TECHNICAL_SKILLS} />
                        {userProfileData.isInterviewer || userType === ROLES.HR_AND_INTERVIEWER ? (
                          <>
                            <MultiSelect
                              id="skills"
                              name="skills"
                              display="chip"
                              filter
                              options={
                                interviewTechnologyType?.length
                                  ? interviewTechnologyType.map((tech) => ({
                                      name: tech.Technology,
                                      value: tech.ID
                                    }))
                                  : []
                              }
                              optionLabel="name"
                              optionValue="value"
                              value={formik.values.skills}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              placeholder={LABEL_TYPES.TECHNICAL_SKILLS}
                              className={classNames({
                                'p-invalid': isFormFieldValid('skills'),
                                'w-full': true
                              })}
                            />
                            {getFormErrorMessage('skills')}
                          </>
                        ) : (
                          <>
                            <AutoComplete
                              id="techStack"
                              name="techStack"
                              value={formik.values.techStack}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              multiple
                              suggestions={softSkilSuggestion}
                              completeMethod={handleSuggestion}
                              placeholder={LABEL_TYPES.SOFT_SKILLS}
                              className={classNames({
                                'p-invalid': isFormFieldValid('techStack')
                              })}
                            />
                            {getFormErrorMessage('techStack')}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="yearsOfExperience"
                          text={LABEL_TYPES.YEAR_EXPERIENCE}
                          isMandatory
                        />
                        <InputText
                          id="yearsOfExperience"
                          name="yearsOfExperience"
                          placeholder={LABEL_TYPES.YEAR_EXPERIENCE}
                          type="number"
                          value={formik.values.yearsOfExperience}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={classNames({
                            'p-invalid': isFormFieldValid('yearsOfExperience')
                          })}
                        />
                        {getFormErrorMessage('yearsOfExperience')}
                      </div>
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="form-group-outer">
                      <div className="custom-form-group">
                        <Label
                          htmlFor="monthsOfExperience"
                          text={LABEL_TYPES.MONTH_EXPERIENCE}
                          isMandatory
                        />
                        <InputText
                          id="monthsOfExperience"
                          name="monthsOfExperience"
                          placeholder={LABEL_TYPES.MONTH_EXPERIENCE}
                          type="number"
                          value={formik.values.monthsOfExperience}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={classNames({
                            'p-invalid': isFormFieldValid('monthsOfExperience')
                          })}
                        />
                        {getFormErrorMessage('monthsOfExperience')}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
            <div className="form-btn-wrapper">
              <CustomButton
                type="submit"
                variant="contained"
                className="ims-blue-btn"
                disabled={isLoading}>
                {BUTTON_TYPES.SAVE}
              </CustomButton>
              <CustomButton
                variant="contained"
                onClick={() => setRedirection(true)}
                color="error"
                className="gray-btn border-btn">
                {BUTTON_TYPES.CANCEL}
              </CustomButton>
            </div>
          </div>
        </form>
      </div>

      <UserProfileUpdatePassword
        show={showChangePasswordPopup}
        onHide={(value) => setShowChangePasswordPopup(value)}
      />
    </>
  );
};

export default UserProfile;

import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Images
import topCurve from '@assets/images/top-curve.svg';
import bottomCurve from '@assets/images/bottom-curve.svg';
import topCurveDark from '@assets/images/dark-top-curve.svg';
import bottomCurveDark from '@assets/images/dark-bottom-curve.svg';

// PrimeReact-Components
import classNames from 'classnames';

// Components
import Topbar from './Topbar';
import Sidebar from './Sidebar';

// Constants
import { ROLES } from '../constants/common';
import { HR_MENU, ADMIN_MENU, INTERVIEWER_MENU } from '../constants/sidebarMenu';

const Admin = () => {
  const auth = useSelector((state) => state.auth);
  const layoutMode = 'static'; // overlay / static
  const layoutColorMode = 'light'; // light / dark
  const inputStyle = useState('outlined'); // outlined / filled
  const ripple = false; // true / false
  const [menu, setMenu] = useState([]);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const location = useLocation();
  const portal = location?.pathname?.toString().split('/').at(1);
  // console.log(location?.pathname?.toString().split('/')?.at(-1));
  const path = location?.pathname?.toString().split('/')?.at(-1);
  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    mobileMenuActive
      ? addClass(document.body, 'body-overflow-hidden')
      : removeClass(document.body, 'body-overflow-hidden');
  }, [mobileMenuActive]);

  useEffect(() => {
    switch (auth.userType) {
      case ROLES.ADMIN:
        setMenu(ADMIN_MENU);
        break;
      case ROLES.HR:
        setMenu(HR_MENU);
        break;
      case ROLES.INTERVIEWER:
        setMenu(INTERVIEWER_MENU);
        break;
      case ROLES.HR_AND_INTERVIEWER:
        setMenu(portal === 'hr' ? HR_MENU : INTERVIEWER_MENU);
        break;
      default:
        setMenu([]);
    }
  }, [auth.userType]);

  const onWrapperClick = () => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === 'overlay') {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === 'static') {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const isDesktop = () => window.innerWidth >= 992;

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += ' ' + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) {
      element.classList.remove(className);
    } else {
      element.className = element.className.replace(
        new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' '
      );
    }
  };

  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': layoutMode === 'overlay',
    'layout-static': layoutMode === 'static',
    'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
    'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive,
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': ripple === false,
    'layout-theme-light': layoutColorMode === 'light'
  });

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <Topbar
        onToggleMenuClick={onToggleMenuClick}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      <div
        className="layout-sidebar"
        onClick={onSidebarClick}
        onMouseEnter={() => {
          if (document.body.classList.contains('sidebar-hide')) {
            document.body.classList.add('sidebar-hover');
          }
        }}
        onMouseLeave={() => {
          document.body.classList.remove('sidebar-hover');
        }}>
        <Sidebar model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
      </div>
      <div className="top-curve sidebar-curve">
        <img src={topCurve} className="light-theme-curve" />
        <img src={topCurveDark} className="dark-theme-curve" />
      </div>
      <div className="bottom-curve sidebar-curve">
        <img src={bottomCurve} className="light-theme-curve" />
        <img src={bottomCurveDark} className="dark-theme-curve" />
      </div>
      <div className="layout-main-container">
        <div className="layout-main relative">
          <div className="mobile-portal-title">
            <span className="logo-text">
              {path === 'jobdesignations'
                ? path?.substring(0, 3) + ' ' + path?.substring(3, 20)
                : Object.keys(location?.pathname?.toString().split('/')).length === 3
                ? path
                : location?.pathname?.toString().split('/')?.at(-2) || path}
            </span>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Admin;

const softSkils = [];
export const onSuggestSkils = (event) => {
  let filteredData;
  if (!event.query.trim().length) {
    filteredData = [...softSkils];
  } else if (softSkils.some((tech) => tech.toLowerCase() === event.query.toLowerCase())) {
    filteredData = softSkils.filter((skils) => {
      return skils.toLowerCase().startsWith(event.query.toLowerCase());
    });
  } else {
    filteredData = [...softSkils, event.query].filter((skils) => {
      return skils.toLowerCase().startsWith(event.query.toLowerCase());
    });
  }
  return filteredData;
};

const parameters = [];
export const onSuggestParameters = (event) => {
  let filteredData;
  if (!event.query.trim().length) {
    filteredData = [...parameters];
  } else if (parameters.some((tech) => tech.toLowerCase() === event.query.toLowerCase())) {
    filteredData = parameters.filter((skils) => {
      return skils.toLowerCase().startsWith(event.query.toLowerCase());
    });
  } else {
    filteredData = [...parameters, event.query].filter((skils) => {
      return skils.toLowerCase().startsWith(event.query.toLowerCase());
    });
  }
  return filteredData;
};

export const AVAILABILITY_DAYS = [
  {
    number: 1,
    name: 'Monday'
  },
  {
    number: 2,
    name: 'Tuesday'
  },
  {
    number: 3,
    name: 'Wednesday'
  },
  {
    number: 4,
    name: 'Thursday'
  },
  {
    number: 5,
    name: 'Friday'
  },
  {
    number: 6,
    name: 'Saturday'
  }
];

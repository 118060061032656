import React from 'react';
import { useSelector } from 'react-redux';

// Prime-React-Components
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';

const UserDetailsRow = (props) => {
  return (
    <div className="detail-row">
      <p className="title">
        <span>{props.title}</span>
        <span className="colon">:</span>
      </p>
      <div className="content">
        <span>{props.value}</span>
      </div>
    </div>
  );
};

const UserDetails = (props) => {
  const { data, header, onHide, show } = props;
  const { isLoading } = useSelector((state) => state.apiStatus);
  return (
    <Dialog
      header={header}
      className={classNames('card overflow-hidden resize-none person-details-popup', {
        hidden: isLoading
      })}
      visible={show}
      onHide={onHide}>
      {data?.map((item, i) => (
        <UserDetailsRow key={i} title={item.title} value={item.value ? item.value : '--'} />
      ))}
    </Dialog>
  );
};

export default UserDetails;

import React from 'react';
import { useNavigate } from 'react-router-dom';

// Images
import errorImg from '@assets/images/error-img.png';
import errorbgImg from '@assets/images/error-bg.png';

// Components
import CustomButton from '../../components/CustomButton';

// Constants
import { ROUTES, BUTTON_TYPES } from '../../constants/common';

const NotAllowed = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <div className="error-page">
      <div className="error-page-inner">
        <div className="text-wrapper">
          <h2>We are Sorry...</h2>
          <p>The page you are trying to access has restricted access.</p>
          <CustomButton variant="contained" onClick={goBack} className="go-back-btn">
            {BUTTON_TYPES.GO_BACK_HOME}
          </CustomButton>
        </div>
        <div className="error-img">
          <img src={errorImg}></img>
        </div>
      </div>
      <div className="error-bg-img">
        <img src={errorbgImg}></img>
      </div>
    </div>
  );
};

export default NotAllowed;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import DataTable from '../../components/DataTable';
import { ActionButtonWithoutView } from '../../components/ActionButtons';

// Prime-React Components
import { confirmDialog } from 'primereact/confirmdialog';

// Redux-Actions
import {
  deleteTechnology,
  getTechnologyById,
  getTechnologyList
} from '../../redux/actions/technology';

// Constants
import {
  ACTION_TYPE,
  DEBOUNCE_TIMEOUT,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  TABLE_NAME
} from '../../constants/common';
import { INTERVIEW_TECHNOLOGY_COLUMNS } from '../../constants/tableColumns';

// Utils
import { debounce } from '../../utils/debounce';
import { isDarkMode, wordCapitalize } from '../../utils/common';
import AddEditTechnology from './AddEditTechnology';

const Technologies = () => {
  const dispatch = useDispatch();
  const { technology } = useSelector((state) => state);
  const [technologyList, setTechnologyList] = useState([]);
  const [technologyExport, setTechnologyExport] = useState([]);
  const [technologyId, setTechnologyId] = useState(null);
  const [tableStates, setTableStates] = useState({
    searchValue: '',
    pageNumber: INITIAL_PAGE_NUMBER,
    size: INITIAL_SIZE,
    sortBy: 'DateModified',
    orderBy: 'desc'
  });

  const [visibleRight, setVisibleRight] = useState(false);
  const onToggleSiderbar = (visiblity) => setVisibleRight(visiblity);

  useEffect(() => {
    dispatch(getTechnologyList(tableStates));
  }, [
    dispatch,
    tableStates,
    technology?.isTechnologyDelete,
    technology?.isUpdated,
    technology?.isAdded
  ]);

  useEffect(() => {
    const modifiedTechnology = technology?.data.map((technology) => {
      return {
        ...technology,
        Technology: wordCapitalize(technology.Technology),

        Color: (
          <ul className="technology-list">
            {technology.Technology && (
              <li
                key={technology.Technology}
                style={{
                  backgroundColor: `${
                    isDarkMode() ? technology?.DarkModeColor : technology?.TechnologyColor
                  }`
                }}
                className="technology-badge">
                {technology.Technology}
              </li>
            )}
          </ul>
        ),
        action: (
          <div className="flex align-items-center">
            <ActionButtonWithoutView
              onClick={(actionType) => handleRowActions(actionType, technology.ID)}
            />
          </div>
        )
      };
    });
    setTechnologyList(modifiedTechnology);
  }, [technology, isDarkMode()]);

  useEffect(() => {
    const modifiedTechnology = technology?.data.map((technology) => {
      return {
        Technology: wordCapitalize(technology.Technology),
        Parameters: technology.Parameters?.map((tech) => wordCapitalize(tech)).join(', ')
      };
    });
    setTechnologyExport(modifiedTechnology);
  }, [technology]);

  const handlePagination = (pageNumber, size) =>
    setTableStates((prevState) => {
      return {
        ...prevState,
        pageNumber,
        size
      };
    });

  const handleSearch = (searchValue) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        searchValue
      };
    });
  };

  const handleSort = (sortBy) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        sortBy,
        orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
      };
    });
  };

  const handleRowActions = (actionType, rowId) => {
    if (actionType === ACTION_TYPE.EDIT) {
      onToggleSiderbar(true);
      setTechnologyId(rowId);
      dispatch(getTechnologyById(rowId));
      document.body.classList.add('sidebar-open');
    } else if (actionType === ACTION_TYPE.DELETE) {
      confirmDialog({
        header: 'Delete',
        message: 'Are you sure you want to delete?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => dispatch(deleteTechnology(rowId))
      });
    }
  };

  const handleAdd = () => {
    setTechnologyId(null);
    onToggleSiderbar(true);
    setVisibleRight(true);
    document.body.classList.add('sidebar-open');
  };

  return (
    <>
      <AddEditTechnology ID={technologyId} onHide={onToggleSiderbar} show={visibleRight} />
      <DataTable
        name={TABLE_NAME.TECHNOLOGY}
        searchPlaceHolder="Search by Technology"
        columns={INTERVIEW_TECHNOLOGY_COLUMNS}
        data={technologyList}
        exportData={technologyExport}
        totalRecords={technology?.pagination?.totalRecords}
        onPagination={handlePagination}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        onAdd={() => handleAdd()}
        onSort={handleSort}
      />
    </>
  );
};

export default Technologies;

import {
  ADMIN_DASHBOARD_COUNT_REQUEST,
  ADMIN_INTERVIEW_ROUND_REQUEST,
  ADMIN_INTERVIEWERS_LIST_REQUEST,
  EDIT_USER_DETAILS,
  ADMIN_INTERVIEW_ROUND_FILTER,
  TECHNOLOGY_WITH_FILTER,
  ROUND_WITH_FILTER,
  USER_EMAIL_OR_MOBILE_EXIST
} from '../../constants/redux';

export const editUserDetailsAction = (userId) => {
  return {
    type: EDIT_USER_DETAILS,
    payload: userId
  };
};

export const getAdminDashboardCount = () => {
  return {
    type: ADMIN_DASHBOARD_COUNT_REQUEST
  };
};

export const getInterviewerRound = () => {
  return {
    type: ADMIN_INTERVIEW_ROUND_REQUEST
  };
};

export const getInterviewerRoundWithFilter = (interviewStartTime, interviewEndTime) => {
  return {
    type: ADMIN_INTERVIEW_ROUND_FILTER,
    payload: { interviewStartTime, interviewEndTime }
  };
};

export const getTechnologyWithFilter = (interviewStartTime, interviewEndTime) => {
  return {
    type: TECHNOLOGY_WITH_FILTER,
    payload: { interviewStartTime, interviewEndTime }
  };
};

export const getRoundWithFilter = (interviewStartTime, interviewEndTime) => {
  return {
    type: ROUND_WITH_FILTER,
    payload: { interviewStartTime, interviewEndTime }
  };
};

export const getInterviewersList = () => {
  return {
    type: ADMIN_INTERVIEWERS_LIST_REQUEST
  };
};

export const checkEmailMobile = (payload) => {
  return {
    type: USER_EMAIL_OR_MOBILE_EXIST,
    payload: payload
  };
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  pagination: {},
  searchData: [],
  loading: false,
  interviewTechnologyType: []
};

const HrListSlice = createSlice({
  name: 'hrs',
  initialState: initialState,
  reducers: {
    addHrData(state, action) {
      const { data, pagination } = action.payload;
      return { data, pagination, isDeleted: false, isUpdated: false, isAdded: false };
    },
    deleteHR(state) {
      return { ...state, isDeleted: true };
    },
    updateHR(state, action) {
      const hrId = action.payload.id;
      const hrs = [...state.data].map((hr) => {
        if (hr.ID === hrId) {
          hr = {
            ...hr,
            ...action.payload
          };
        }
        return hr;
      });
      return { ...state, data: hrs };
    },
    getAvailableSchedule(state, action) {
      const data = action.payload;
      return { ...state, data };
    },
    getScheduleInterview(state, action) {
      const data = action.payload;
      return { ...state, data };
    },
    getJobDesignation(state, action) {
      const jobDesignationData = action.payload;
      return { ...state, jobDesignationData };
    },
    getInterviewTechnology(state, action) {
      const interviewTechnologyType = action.payload;
      return { ...state, interviewTechnologyType };
    },
    interviewTechnologyBySearch(state, action) {
      const searchData = action.payload;
      return { ...state, searchData };
    },
    interviewersListBySearchName(state, action) {
      const interviewersList = action.payload;
      return { ...state, interviewersList };
    },
    getJobDesignationBySearch(state, action) {
      const searchData = action.payload;
      return { ...state, searchData };
    },

    interviewerAvailiblityByFilter(state, action) {
      const { data, pagination } = action.payload;
      const interviewerAvailiblityList = data;
      return { ...state, interviewerAvailiblityList, pagination };
    },
    getScheduleInterviewsCountByHR(state, action) {
      return { ...state, scheduleInterviewCount: action.payload };
    },
    updateLoading(state, action) {
      const { loading } = action.payload;
      return { ...state, loading };
    },
    updateData(state) {
      return { ...state, isUpdated: true, isAdded: true };
    }
  }
});

export const {
  addHrData,
  deleteHR,
  updateHR,
  getAvailableSchedule,
  getJobDesignation,
  getInterviewTechnology,
  getScheduleInterview,
  getJobDesignationBySearch,
  interviewTechnologyBySearch,
  interviewerAvailiblityByFilter,
  interviewersListBySearchName,
  getScheduleInterviewsCountByHR,
  updateData,
  updateLoading
} = HrListSlice.actions;
export default HrListSlice.reducer;

// Utils
import { isDarkMode } from './common';

export const getLightOptions = () => {
  return {
    aspectRatio: 1,
    borderWidth: 0,
    offset: 2,
    plugins: {
      datalabels: {
        font: {
          size: 16
        }
      },
      legend: {
        position: 'bottom',
        padding: {
          top: 50
        },
        labels: {
          usePointStyle: true,
          boxWidth: 6,
          boxHeight: 6,
          color: isDarkMode() ? 'rgba(228, 230, 244, 1)' : '#8b8787',
          font: {
            size: 13,
            family: "'Public Sans', sans-serif",
            weight: 300
          }
        }
      }
    }
  };
};

export const getPieOptions = () => {
  return {
    aspectRatio: 1.2,
    borderWidth: 0,
    plugins: {
      datalabels: {
        font: {
          size: 16
        }
      },
      legend: {
        position: 'bottom',
        padding: {
          top: 50
        },
        labels: {
          usePointStyle: true,
          boxWidth: 6,
          boxHeight: 6,
          color: isDarkMode() ? 'rgba(228, 230, 244, 1)' : '#8b8787',
          font: {
            size: 13,
            family: "'Public Sans', sans-serif",
            weight: 300
          }
        }
      }
    }
  };
};

export const getBarOptions = () => {
  return {
    aspectRatio: 1,
    borderWidth: 0,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: (context) => {
            return context[0].label.replaceAll(',', ' ');
          }
        }
      },
      datalabels: {
        font: {
          size: 16
        },
        formatter: (v) => (v ? v : '')
      },
      legend: {
        display: false,
        position: 'bottom',
        padding: {
          top: 50
        },
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          color: isDarkMode() ? 'rgba(228, 230, 244, 1)' : '#8b8787',
          font: {
            size: 10,
            family: "'Public Sans', sans-serif",
            weight: 300
          }
        }
      }
    },
    scales: {
      y: {
        grid: {
          ...(isDarkMode() && {
            color: '#2C2A40'
          })
        },
        ticks: {
          autoskip: false,
          color: isDarkMode() ? 'rgba(228, 230, 244, 1)' : '#8b8787',
          font: {
            family: "'Public Sans', sans-serif",
            size: 13,
            weight: 300
          },
          precision: 0
        },
        beginAtZero: true, // Start y-axis at 0
        min: 0, // Ensures that the y-axis starts at 0
        suggestedMin: 0, // Suggests the minimum value for y-axis as 0
        suggestedMax: 10, // Suggests the maximum value for y-axis as 5 if all data points are 0
        stepSize: 1 // Ensures the y-axis increments in whole numbers
      },
      x: {
        grid: {
          ...(isDarkMode() && {
            color: '#2C2A40'
          })
        },
        ticks: {
          autoSkip: false,
          color: isDarkMode() ? 'rgba(228, 230, 244, 1)' : '#8b8787',
          font: {
            family: "'Public Sans', sans-serif",
            size: 13,
            weight: 300
          },
          minRotation: 0,
          maxRotation: 0
        }
      }
    }
  };
};

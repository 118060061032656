import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';

// Api-Services
import { forgotPasswordApi, loginApi, resetPasswordApi } from '../apis/auth';

// Constants
import {
  LOGIN_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST
} from '../../constants/redux';
import { RESPONSE_STATUS_CODES } from '../../constants/common';

// Redux-Actions
import { login, logout } from '../slices/auth';
import { apiFail, apiSuccess } from '../slices/apiStatus';

function* authSaga(action) {
  try {
    const result = yield call(loginApi, action.payload);
    const responseData = result.data.data;
    const {
      FirstName: firstName,
      Email: userEmail,
      LastName: lastName,
      ID: userId,
      IsRegisteredUser: isRegisteredUser,
      UserTypeId: userType
    } = responseData.loginUserData;
    const authData = {
      userId,
      userEmail,
      userType,
      firstName,
      lastName,
      userName: `${firstName} ${lastName}`,
      token: responseData.tokenData,
      isRegisteredUser: isRegisteredUser,
      userProfile: responseData.loginUserData.userProfile
    };
    yield put(login(authData));
    yield put(apiSuccess());
    toast.success(result.data.message, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  } catch (error) {
    yield put(apiFail());
  }
}

function* forgotPasswordSaga(action) {
  try {
    const response = yield call(forgotPasswordApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      yield put(apiSuccess());
      toast.success('Mail Sent Successfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* resetPasswordSaga(action) {
  try {
    const response = yield call(resetPasswordApi, action.payload);

    if (response.status === RESPONSE_STATUS_CODES.SUCCESS) {
      yield put(apiSuccess());
      yield put(logout());
      toast.success('Password Updated Successfully');
    }
  } catch (error) {
    console.log(error);
  }
}

function* watcherAuthSaga() {
  yield takeLatest(LOGIN_REQUEST, authSaga);
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordSaga);
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordSaga);
}

export default watcherAuthSaga;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  historyData: { data: [] }
};

const interviewHistorySlice = createSlice({
  name: 'interviewHistory',
  initialState,
  reducers: {
    addInterviewHistory(state, action) {
      const { data, pagination } = action.payload;
      return { data, pagination };
    }
  }
});
export const { addInterviewHistory } = interviewHistorySlice.actions;
export default interviewHistorySlice.reducer;

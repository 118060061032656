// import moment from 'moment';
import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import { NOTIFICATION_TYPE } from '../../constants/common';
import moment from 'moment';

export default function NotificationModal(props) {
  // Props
  const { open, anchor, notifications, handleClose, handleClick } = props;

  return (
    <Menu
      anchorEl={anchor}
      id="notification-menu"
      className="dropdown_menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}>
      <div className="heading">
        <h2>Notifications</h2>
      </div>
      <div className="notification-modal">
        {notifications?.length ? (
          notifications.map((notification, index) => {
            return (
              <MenuItem
                key={index}
                className={`user-info ${!notification?.IsRead && 'unread'} ${
                  notification?.Type === NOTIFICATION_TYPE.INVITATION_SENT
                    ? 'new-invitation'
                    : notification?.Type === NOTIFICATION_TYPE.INTERVIEW_RESCHEDULE
                    ? 'rescheduled'
                    : notification?.Type === NOTIFICATION_TYPE.INTERVIEW_CANCEL
                    ? 'cancelled'
                    : notification?.Type === NOTIFICATION_TYPE.INVITATION_ACCEPTED
                    ? 'accepted'
                    : notification?.Type === NOTIFICATION_TYPE.INVITATION_REJECTED
                    ? 'rejected'
                    : notification?.Type === NOTIFICATION_TYPE.INTERVIEW_SCHEDULE
                    ? 'completed'
                    : notification?.Type === NOTIFICATION_TYPE.INVITATION__REMINDER
                    ? 'reminder'
                    : notification?.Type === NOTIFICATION_TYPE.FEEDBACK_REMINDER
                    ? 'feedback-reminder'
                    : ''
                }`}
                onClick={() => handleClick([notification.ID])}>
                <div className="user-data">
                  <h4>{notification?.Title || ''}</h4>
                  <p>{notification?.Message || ''}</p>
                  <span>
                    {notification?.DateCreated
                      ? moment(notification?.DateCreated).isSame(moment(), 'day')
                        ? moment(notification?.DateCreated).format('[Today] h:mm A')
                        : moment(notification?.DateCreated).isSame(
                            moment().clone().subtract(1, 'days'),
                            'day'
                          )
                        ? moment(notification?.DateCreated).format('[Yesterday] h:mm A')
                        : moment(notification?.DateCreated).format('DD-MM-YYYY h:mm A')
                      : ''}
                  </span>
                </div>
              </MenuItem>
            );
          })
        ) : (
          <div className="null-notifications">No notifications</div>
        )}
      </div>
      <div className="all-read">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleClick(
              notifications
                ?.filter((notification) => !notification.IsRead)
                .map((notification) => notification.ID)
            );
          }}>
          Mark all as read
        </a>
      </div>
    </Menu>
  );
}

// Components
import Asteric from './Asteric';

import ReactTooltip from 'react-tooltip';
import { classNames } from 'primereact/utils';

const Label = (props) => {
  const {
    htmlFor,
    className,
    text,
    isMandatory,
    isChildren,
    isBold,
    tooltip,
    ishidden,
    onFocus,
    onBlur
  } = props;
  return (
    <label
      htmlFor={htmlFor}
      className={[
        !isBold ? 'text-gray font-bold' : '',
        ishidden ? 'hide-label' : '',
        className || ''
      ].join(' ')}>
      <span
        className={classNames({
          'input-focus': onFocus,
          'text-gray': onBlur
        })}>
        {text}
      </span>
      {isMandatory && <Asteric />}
      {isChildren && (
        <>
          <ReactTooltip />
          <i
            className="custom-target-icon pi pi-info-circle pl-1"
            data-tip={tooltip}
            style={{ cursor: 'pointer' }}
          />
        </>
      )}
    </label>
  );
};

export default Label;

import {
  CANDIDATE_ADD_REQUEST,
  CANDIDATE_DELETE_REQUEST,
  CANDIDATE_LIST_REQUEST,
  CANDIDATE_EDIT_REQUEST,
  CANDIDATE_GET_DETAILS,
  CANDIDATE_REQUEST,
  CANDIDATE_SEARCH_REQUEST,
  CANDIDATE_EMAIL_CHECK_REQUEST,
  CANDIDATE_NOTE_ADD_REQUEST,
  CANDIDATE_NOTE_GET_DETAILS,
  CANDIDATE_NOTE_EDIT_REQUEST,
  CANDIDATE_NOTE_DELETE_REQUEST
} from '../../constants/redux';

export const addCandidate = (candidateDetails) => {
  return {
    type: CANDIDATE_ADD_REQUEST,
    payload: candidateDetails
  };
};

export const emailCheck = (candidateDetails) => {
  return {
    type: CANDIDATE_EMAIL_CHECK_REQUEST,
    payload: candidateDetails
  };
};

export const getCandidateList = (payload) => {
  return {
    type: CANDIDATE_LIST_REQUEST,
    payload
  };
};

export const getCandidate = () => {
  return {
    type: CANDIDATE_REQUEST
  };
};

export const getCandidateBySearch = (searchText) => {
  return {
    type: CANDIDATE_SEARCH_REQUEST,
    payload: searchText
  };
};

export const deleteCandidate = (candidateId) => {
  return {
    type: CANDIDATE_DELETE_REQUEST,
    payload: candidateId
  };
};

export const getCandidateDetails = (userId) => {
  return {
    type: CANDIDATE_GET_DETAILS,
    payload: userId
  };
};

export const editCandidate = (candidateDetails) => {
  return {
    type: CANDIDATE_EDIT_REQUEST,
    payload: candidateDetails
  };
};

export const getCandidateNote = (candidateId) => {
  return {
    type: CANDIDATE_NOTE_GET_DETAILS,
    payload: candidateId
  };
};

export const addCandidateNote = (notes) => {
  return {
    type: CANDIDATE_NOTE_ADD_REQUEST,
    payload: notes
  };
};

export const editCandidateNote = (noteId) => {
  return {
    type: CANDIDATE_NOTE_EDIT_REQUEST,
    payload: noteId
  };
};

export const deleteCandidateNote = (noteId) => {
  return {
    type: CANDIDATE_NOTE_DELETE_REQUEST,
    payload: noteId
  };
};

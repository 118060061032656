import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

// Images
import logoImg from '@assets/images/site-logo.svg';
import logodarkImg from '@assets/images/site-logo-dark.svg';
import siteLogoTatva from '@assets/images/site-logo-tatva.svg';
import siteLogoTatvaDark from '@assets/images/site-logo-tatva-dark.svg';

// Components
import CustomButton from '../../components/CustomButton';
import CustomPassword from '../../components/CustomPassword';
import Label from '../../components/Label';
import Asteric from '../../components/Asteric';

// Prime-React Components
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';

// Redux-Actions
import { loginHandler } from '../../redux/actions/auth';

// Redux-Slices
import { apiStatusClear } from '../../redux/slices/apiStatus';

// Constants
import {
  ROLES,
  ROUTES,
  BUTTON_TYPES,
  LABEL_TYPES,
  EMAIL_PATTERN_REGEX,
  SECRET_KEY,
  KEEP_SIGN_IN_KEY
} from '../../constants/common';
import { getDataFromLocalstorage, setDataInLocalstorage } from '../../utils/common';
import { AES, enc } from 'crypto-js';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLogged, userType, isRegisteredUser, token } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.apiStatus);
  const [onFocus, setOnFocus] = useState(false);
  const [onBlur, setOnBlur] = useState(false);
  const [email = '', password = ''] = getDataFromLocalstorage(KEEP_SIGN_IN_KEY)
    ? AES.decrypt(getDataFromLocalstorage(KEEP_SIGN_IN_KEY), SECRET_KEY)
        .toString(enc.Utf8)
        .split('|')
    : [];

  const handleOnFocus = () => {
    setOnFocus(true);
    setOnBlur(false);
  };

  const handleOnBlur = () => {
    setOnFocus(false);
    setOnBlur(true);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      keepSignIn: !!getDataFromLocalstorage(KEEP_SIGN_IN_KEY),
      username: email,
      password
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .matches(EMAIL_PATTERN_REGEX, 'Username is Invalid.')
        .required('Username is required.'),
      password: Yup.string().required('Password is required.')
    }),
    onSubmit: async ({ username, password, keepSignIn }) => {
      try {
        if (keepSignIn) {
          setDataInLocalstorage(
            KEEP_SIGN_IN_KEY,
            AES.encrypt(`${username}|${password}`, SECRET_KEY).toString()
          );
        } else {
          localStorage.removeItem(KEEP_SIGN_IN_KEY);
        }
        dispatch(
          loginHandler({
            email: username,
            password
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  });

  useEffect(() => {
    if (isLogged && !isRegisteredUser) {
      dispatch(apiStatusClear());
      return navigate(`${ROUTES.RESET_PASSWORD_WITHOUT_TOKEN}/${token}`);
    } else if (isLogged) {
      switch (userType) {
        case ROLES.ADMIN:
          return navigate(ROUTES.ADMIN.DASHBOARD);
        case ROLES.HR:
        case ROLES.HR_AND_INTERVIEWER:
          return navigate(ROUTES.HR.DASHBOARD);
        case ROLES.INTERVIEWER:
          return navigate(ROUTES.INTERVIEWER.DASHBOARD);
        default:
          return navigate(ROUTES.LOGIN);
      }
    }
  }, [isLogged, userType, isRegisteredUser, navigate, dispatch]);

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  return (
    <div className="authentication-wrapper">
      <div className="authentication-inner">
        <div className="login-container">
          <span className="site-logo">
            <img src={logoImg}></img>
            <img src={logodarkImg} className="dark-theme-logo"></img>
          </span>
          <h2>Welcome to IMS portal!</h2>
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="field">
              <Label
                htmlFor="username"
                text={LABEL_TYPES.USERNAME}
                isBold
                onFocus={onFocus}
                onBlur={onBlur}
              />
              <Asteric />
              <span className="p-input-icon-right">
                <i className="pi pi-user" />
                <InputText
                  id="username"
                  name="username"
                  placeholder="Eg. example@gmail.com"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                  className={classNames({
                    'p-invalid': isFormFieldValid('username')
                  })}
                />
              </span>
              {getFormErrorMessage('username')}
            </div>
            <div className="field pwd">
              <CustomPassword
                htmlFor="password"
                text={LABEL_TYPES.PASSWORD}
                ismandatory={+true}
                isbold={+true}
                id="password"
                name="password"
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                toggleMask
                className={classNames({ 'p-invalid': isFormFieldValid('password') })}
                feedback={false}
              />
              {getFormErrorMessage('password')}
            </div>
            <div className="link-wrapper space-between">
              <div className="keep-me-sign-in">
                <input
                  type="checkbox"
                  checked={formik.values.keepSignIn}
                  onChange={formik.handleChange}
                  name="keepSignIn"
                  className="styled-checkbox"
                />
                <label>Keep me signed in</label>
              </div>
              <Link to={ROUTES.FORGOT_PASSWORD}>Forgot password?</Link>
            </div>
            <CustomButton type="submit" variant="contained" className="w-full" disabled={isLoading}>
              {BUTTON_TYPES.LOGIN}
            </CustomButton>
          </form>
          <div className="tatva-logo-wrap">
            <span>Powered by</span>
            <img src={siteLogoTatva} alt="Tatvasoft" />
            <img src={siteLogoTatvaDark} alt="Tatvasoft" className="dark-theme-img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Prime-React-Components
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';

// Components
import DataTable from '../../components/DataTable';

// Constants
import { HR_FEEDBACK_HISTORY_COLUMNS } from '../../constants/tableColumns';
import {
  CODE_QUALITY_STATUS,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  INTERVIEW_TYPE,
  OVERALL_FEEDBACK_STATUS,
  OVERALL_GRADE_STATUS,
  PRACTICAL_RESULT_STATUS
} from '../../constants/common';
import { renderHrAvatar } from '../../utils/common';

// Redux Actions
import { getHrFeedbackHistoryLogAction } from '../../redux/actions/interviews';
import { formatTime, getFormattedDateView } from '../../utils/date';

function LogDetails(props) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.apiStatus);
  const { data, onHide, show, interviewId } = props;
  const { interviews } = useSelector((state) => state);
  const [tableStates, setTableStates] = useState({
    pageNumber: INITIAL_PAGE_NUMBER,
    size: INITIAL_SIZE,
    sortBy: 'DateModified',
    orderBy: 'desc',
    interviewId: interviewId
  });
  const [logsData, setLogsData] = useState([]);

  useEffect(() => {
    if (tableStates && show) {
      dispatch(getHrFeedbackHistoryLogAction(tableStates));
    }
  }, [tableStates, show]);

  const handlePagination = (pageNumber, size) =>
    setTableStates((prevState) => {
      return {
        ...prevState,
        pageNumber,
        size
      };
    });

  const handleSort = (sortBy) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        sortBy,
        orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
      };
    });
  };

  useEffect(() => {
    // Mapping object for field names to status mapping functions
    const statusMappings = {
      TechnicalAttitude: OVERALL_GRADE_STATUS,
      TechnicalCommunication: OVERALL_GRADE_STATUS,
      OverAllGrades: OVERALL_GRADE_STATUS,
      CodeReview: OVERALL_GRADE_STATUS,
      PracticalCommunication: OVERALL_GRADE_STATUS,
      CanDoAttitude: OVERALL_GRADE_STATUS,
      OverallFeedback: OVERALL_FEEDBACK_STATUS,
      CodeQuality: CODE_QUALITY_STATUS,
      Result: PRACTICAL_RESULT_STATUS
    };

    // Function to convert date fields to IST
    const convertDateToIST = (value) => {
      const date = new Date(value);
      return date.getFullYear() === 1970
        ? ''
        : date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
    };

    const modifiedLogsData = data.map((log) => {
      const statusMapping = statusMappings[log.FieldName];
      const OldValue = statusMapping ? statusMapping[log.OldValue] || log.OldValue : log.OldValue;
      const UpdatedValue = statusMapping
        ? statusMapping[log.UpdatedValue] || log.UpdatedValue
        : log.UpdatedValue;

      return {
        ...log,
        InterviewRound: (
          <>
            {log?.InterviewRound?.StartDateOfInterviewRound &&
              getFormattedDateView(log.InterviewRound?.StartDateOfInterviewRound)}{' '}
            {log?.InterviewRound?.StartDateOfInterviewRound &&
              formatTime(log.InterviewRound?.StartDateOfInterviewRound)}{' '}
            {!!INTERVIEW_TYPE.filter((num) => num.code === log?.InterviewRound?.InterviewRound)
              ?.length && (
              <strong>
                (
                {INTERVIEW_TYPE.filter(
                  (num) => num.code === log?.InterviewRound?.InterviewRound
                ).map((type) => type.name)}
                )
              </strong>
            )}
          </>
        ),
        UpdatedBy: renderHrAvatar(
          log.User?.ID,
          log.User?.UserProfile,
          log.User?.FirstName,
          log.User?.LastName
        ),
        OldValue: ['PracticalStartTime', 'PracticalEndTime'].includes(log.FieldName)
          ? convertDateToIST(log.OldValue)
          : OldValue,
        UpdatedValue: ['PracticalStartTime', 'PracticalEndTime'].includes(log.FieldName)
          ? convertDateToIST(log.UpdatedValue)
          : UpdatedValue
      };
    });

    setLogsData(modifiedLogsData);
  }, [data]);

  return (
    <Dialog
      header="History Log Details"
      style={{ width: '80%' }}
      className={classNames('log-detail-popup', { hidden: isLoading })}
      visible={show}
      onHide={onHide}>
      <DataTable
        name="History_Log_Details"
        totalRecords={interviews.pagination.totalRecords}
        data={logsData}
        exportData={data}
        columns={HR_FEEDBACK_HISTORY_COLUMNS}
        onSort={handleSort}
        onPagination={handlePagination}
      />
    </Dialog>
  );
}

export default LogDetails;

import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from '@redux-saga/core';

// RootSaga
import rootSaga from './sagas/root';

// Reducers
import authReducer from './slices/auth';
import interviewersReducer from './slices/interviewers';
import apiStatusReducer from './slices/apiStatus';
import hrListReducer from './slices/hr';
import candidatesReducer from './slices/candidates';
import userDetailsReducer from './slices/userDetails';
import optionsReducer from './slices/showOptions';
import interviewsReducer from './slices/interviews';
import interviewInvitationReducer from './slices/interviewInvitation';
import interviewScheduleReducer from './slices/interviewSchedule';
import interviewHistoryReducer from './slices/interviewHistory';
import interviewPendingFeedbackReducer from './slices/feedbackPending';
import interviewersDashboardReducer from './slices/interviewDashboard';
import adminDashboardReducer from './slices/admin';
import profileDetailsReducer from './slices/profileDetails';
import designationReducer from './slices/designation';
import jobDescriptionReducer from './slices/jobDescriptions';
import technologyReducer from './slices/technology';
import notificationReducer from './slices/notification';
import darkModeReducer from './slices/darkMode';
import commonParameterReducer from './slices/commonParamters';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    auth: authReducer,
    apiStatus: apiStatusReducer,
    interviewers: interviewersReducer,
    interviewInvitaion: interviewInvitationReducer,
    interviewSchedule: interviewScheduleReducer,
    interviewHistory: interviewHistoryReducer,
    interviewPendingFeedback: interviewPendingFeedbackReducer,
    interviewerDashboard: interviewersDashboardReducer,
    hrs: hrListReducer,
    candidates: candidatesReducer,
    userDetails: userDetailsReducer,
    options: optionsReducer,
    interviews: interviewsReducer,
    adminDashboard: adminDashboardReducer,
    profileDetails: profileDetailsReducer,
    designation: designationReducer,
    jobDescription: jobDescriptionReducer,
    technology: technologyReducer,
    notification: notificationReducer,
    darkMode: darkModeReducer,
    parameter: commonParameterReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(sagaMiddleware)
});

sagaMiddleware.run(rootSaga);

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  searchData: [],
  pagination: {},
  candidateDetails: {},
  candidateNotes: [],
  existCandidateId: ''
};

const candidateSlice = createSlice({
  name: 'candidates',
  initialState,
  reducers: {
    editCandidateDetails(state, action) {
      const candidateDetails = action.payload;
      return {
        ...state,
        candidateDetails: candidateDetails
      };
    },
    editCandidateNotes(state, action) {
      return {
        ...state,
        candidateNotes: action.payload
      };
    },
    getCandidates(state, action) {
      const { data, pagination } = action.payload;
      return { data, pagination, isDeleted: false, isUpdated: false, isAdded: false };
    },
    getCandidatesBySearch(state, action) {
      const searchData = action.payload;
      return { ...state, searchData };
    },
    getCandidateData(state, action) {
      const candidateData = action.payload;
      return { ...state, candidateData };
    },
    deleteCandidate(state, action) {
      const candidateId = action.payload;
      const candidates = [...state.data].filter((candidate) => candidate.ID !== candidateId);
      return { ...state, data: candidates, isDeleted: true };
    },
    getEmailCheck(state, action) {
      const successMsg = action.payload;
      return { ...state, successMsg, existCandidateId: '' };
    },
    getExistCandidate(state, action) {
      const existCandidateId = action.payload;
      return { ...state, existCandidateId };
    },
    setIsUpdatedSuccess(state, action) {
      const isUpdatedSuccess = action.payload;
      return { ...state, isUpdatedSuccess };
    },
    updateData(state) {
      return {
        ...state,
        isUpdated: true,
        isAdded: true
      };
    }
  }
});

export const {
  getCandidates,
  getCandidateData,
  deleteCandidate,
  editCandidateDetails,
  getCandidatesBySearch,
  getEmailCheck,
  getExistCandidate,
  updateData,
  editCandidateNotes,
  setIsUpdatedSuccess
} = candidateSlice.actions;
export default candidateSlice.reducer;

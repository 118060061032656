import {
  INTERVIEWS_ADD_REQUEST,
  INTERVIEWS_LIST_REQUEST,
  INTERVIEW_FEEDBACK_LIST_REQUEST,
  INTERVIEW_INVITATION_LIST_REQUEST,
  INTERVIEW_INVITATION_STATUS,
  INTERVIEW_SCHEDULE_LIST_REQUEST,
  INTERVIEW_GET_DETAILS,
  INTERVIEW_EDIT_REQUEST,
  INTERVIEW_HISTORY_LIST_REQUEST,
  INTERVIEW_STATUS,
  INTERVIEW_FEEDBACK,
  INTERVIEWS_ADD_ROUND_REQUEST,
  INTERVIEWS_EDIT_ROUND_REQUEST,
  INTERVIEWS_EDIT_REQUEST,
  INTERVIEWS_TECHPARAMS_REQUEST,
  GET_HR_FEEDBACK_HISTORY_LOGS,
  COMPLETE_INTERVIEW_FEEDBACK_REQUEST,
  INTERVIEW_CANCEL_REQUEST,
  INTERVIEW_ROUND_CANCEL_REQUEST,
  CANCEL_CANDIDATE_INTERVIEW_ROUND
} from '../../constants/redux';

export const getInterviewsList = (payload) => {
  return {
    type: INTERVIEWS_LIST_REQUEST,
    payload
  };
};
export const getTechparamsList = (payload) => {
  return {
    type: INTERVIEWS_TECHPARAMS_REQUEST,
    payload
  };
};

export const getInterviewDetails = (interviewId) => {
  return {
    type: INTERVIEW_GET_DETAILS,
    payload: interviewId
  };
};

export const editInterview = (interviewDetails) => {
  return {
    type: INTERVIEW_EDIT_REQUEST,
    payload: interviewDetails
  };
};
export const editInterviews = (interviewDetails) => {
  return {
    type: INTERVIEWS_EDIT_REQUEST,
    payload: interviewDetails
  };
};

export const addInterviews = (interviewsDetails) => {
  return {
    type: INTERVIEWS_ADD_REQUEST,
    payload: interviewsDetails
  };
};

export const editInterviewRoundAction = (interviewDetails) => {
  return {
    type: INTERVIEWS_EDIT_ROUND_REQUEST,
    payload: interviewDetails
  };
};

export const addInterviewRoundAction = (payload) => {
  return {
    type: INTERVIEWS_ADD_ROUND_REQUEST,
    payload
  };
};

export const getInterviewSheduleList = (payload) => {
  return {
    type: INTERVIEW_SCHEDULE_LIST_REQUEST,
    payload
  };
};

export const getInterviewInvitationsList = (payload) => {
  return {
    type: INTERVIEW_INVITATION_LIST_REQUEST,
    payload
  };
};

export const getInterviewFeedbackPendingList = (payload) => {
  return {
    type: INTERVIEW_FEEDBACK_LIST_REQUEST,
    payload
  };
};

export const interviewInvitaionStatus = ({ id, rejectionReason, invitationStatus }) => {
  return {
    type: INTERVIEW_INVITATION_STATUS,
    payload: { id, rejectionReason, invitationStatus }
  };
};

export const getInterviewHistoryList = (payload) => {
  return {
    type: INTERVIEW_HISTORY_LIST_REQUEST,
    payload
  };
};

export const interviewStatus = ({ id, interviewStatus }) => {
  return {
    type: INTERVIEW_STATUS,
    payload: { id, interviewStatus }
  };
};

export const addFeedback = (payload) => {
  return {
    type: INTERVIEW_FEEDBACK,
    payload: payload
  };
};

export const updateFeedback = (payload) => {
  return {
    type: COMPLETE_INTERVIEW_FEEDBACK_REQUEST,
    payload: payload
  };
};

export const getHrFeedbackHistoryLogAction = (payload) => {
  return {
    type: GET_HR_FEEDBACK_HISTORY_LOGS,
    payload: payload
  };
};

export const cancelInterviewAction = (cancelInterviewData) => {
  return {
    type: INTERVIEW_CANCEL_REQUEST,
    payload: cancelInterviewData
  };
};

export const cancelInterviewRoundAction = (cancelInterviewRoundData) => {
  return {
    type: INTERVIEW_ROUND_CANCEL_REQUEST,
    payload: cancelInterviewRoundData
  };
};

export const rejectCandidateInterviewRound = (InterviewRoundData) => {
  return {
    type: CANCEL_CANDIDATE_INTERVIEW_ROUND,
    payload: InterviewRoundData
  };
};

import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';

// Api-Services
import {
  technologylistApi,
  addTechnologyApi,
  getTechnologyByIdApi,
  deleteTechnologyApi,
  editTechnologyApi
} from '../apis/technology';

// Constants
import {
  TECHNOLOGY_REQUEST,
  ADD_TECHNOLOGY_REQUEST,
  EDIT_TECHNOLOGY_REQUEST,
  DELETE_TECHNOLOGY_REQUEST,
  GET_TECHNOLOGY_DETAILS
} from '../../constants/redux';
import { RESPONSE_STATUS_CODES } from '../../constants/common';

// Redux-Actions
import { addTechnologyData, editTechnologyDetails, deleteTechnology } from '../slices/technology';
import { apiSuccess } from '../slices/apiStatus';

function* addTechnologySaga(action) {
  try {
    const response = yield call(addTechnologyApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.OK) {
      toast.success('Technology Added Succesfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteTechnologyData(action) {
  try {
    const response = yield call(deleteTechnologyApi, action.payload);
    if (response?.status) {
      yield put(deleteTechnology(action.payload));
    }
  } catch (error) {
    console.log(error);
  }
}

function* editTechnologyData(action) {
  try {
    const response = yield call(editTechnologyApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.SUCCESS) {
      toast.success('Detail have been Updated Successfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

function* technologyListSaga(action) {
  try {
    const response = yield call(technologylistApi, {
      ...action.payload
    });
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      const data = result.technologies;
      const pagination = result.pagination;
      yield put(addTechnologyData({ data, pagination }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getTechnologyByIdSaga(action) {
  const ID = action.payload;
  const response = yield call(getTechnologyByIdApi, ID);
  if (response.status === RESPONSE_STATUS_CODES.OK) {
    const designationDetails = response.data.data;
    yield put(editTechnologyDetails(designationDetails));
    yield put(apiSuccess());
  }
}

export default function* watcherTechnologySaga() {
  yield takeEvery(TECHNOLOGY_REQUEST, technologyListSaga);
  yield takeEvery(ADD_TECHNOLOGY_REQUEST, addTechnologySaga);
  yield takeEvery(DELETE_TECHNOLOGY_REQUEST, deleteTechnologyData);
  yield takeEvery(EDIT_TECHNOLOGY_REQUEST, editTechnologyData);
  yield takeEvery(GET_TECHNOLOGY_DETAILS, getTechnologyByIdSaga);
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import DataTable from '../../components/DataTable';
import { ActionButtonWithoutView } from '../../components/ActionButtons';
import AddEditJobDesignation from './AddEditJobDesignation';

// Prime-React Components
import { confirmDialog } from 'primereact/confirmdialog';

// Redux-Actions
import {
  deleteDesignation,
  getJobDesignationById,
  getJobDesignationList
} from '../../redux/actions/designation';

// Constants
import {
  ACTION_TYPE,
  DEBOUNCE_TIMEOUT,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  TABLE_NAME
} from '../../constants/common';
import { JOB_DESIGNATION_COLUMNS } from '../../constants/tableColumns';

// Utils
import { debounce } from '../../utils/debounce';
import { wordCapitalize } from '../../utils/common';

const JobDesignation = () => {
  const dispatch = useDispatch();
  const { designation } = useSelector((state) => state);
  const [roleList, setRoleList] = useState([]);
  const [roleExport, setRoleExport] = useState([]);
  const [editDesignationID, setDesignationId] = useState(null);
  const [tableStates, setTableStates] = useState({
    searchValue: '',
    pageNumber: INITIAL_PAGE_NUMBER,
    size: INITIAL_SIZE,
    sortBy: 'DateModified',
    orderBy: 'desc'
  });

  const [visibleRight, setVisibleRight] = useState(false);
  const onToggleSiderbar = () => setVisibleRight(!visibleRight);

  useEffect(() => {
    dispatch(getJobDesignationList(tableStates));
  }, [
    dispatch,
    tableStates,
    designation?.isDesignationDeleted,
    designation?.isUpdated,
    designation?.isAdded
  ]);

  useEffect(() => {
    const modifiedDesignation = designation?.data.map((designation) => {
      return {
        ...designation,
        Designation: wordCapitalize(designation?.Designation),
        action: (
          <div className="flex align-items-center">
            <ActionButtonWithoutView
              onClick={(actionType, e) => handleRowActions(actionType, designation.ID, e)}
            />
          </div>
        )
      };
    });
    setRoleList(modifiedDesignation);
  }, [designation]);

  useEffect(() => {
    const modifiedDesignation = designation?.data.map((designation) => {
      return {
        Designation: wordCapitalize(designation?.Designation)
      };
    });
    setRoleExport(modifiedDesignation);
  }, [designation]);

  const handlePagination = (pageNumber, size) =>
    setTableStates((prevState) => {
      return {
        ...prevState,
        pageNumber,
        size
      };
    });

  const handleSearch = (searchValue) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        searchValue
      };
    });
  };
  const handleSort = (sortBy) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        sortBy,
        orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
      };
    });
  };

  const handleRowActions = (actionType, rowId) => {
    if (actionType === ACTION_TYPE.EDIT) {
      onToggleSiderbar();
      setDesignationId(rowId);
      dispatch(getJobDesignationById(rowId));
      document.body.classList.add('sidebar-open');
    } else if (actionType === ACTION_TYPE.DELETE) {
      confirmDialog({
        header: 'Delete',
        message: 'Are you sure you want to delete?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => dispatch(deleteDesignation(rowId))
      });
    }
  };

  const handleAdd = () => {
    setDesignationId(null);
    onToggleSiderbar();
    setVisibleRight(true);
    document.body.classList.add('sidebar-open');
  };

  return (
    <>
      <AddEditJobDesignation ID={editDesignationID} onHide={onToggleSiderbar} show={visibleRight} />
      <DataTable
        name={TABLE_NAME.DESIGNATION}
        searchPlaceHolder="Search by Designation"
        columns={JOB_DESIGNATION_COLUMNS}
        data={roleList}
        exportData={roleExport}
        totalRecords={designation?.pagination?.totalRecords}
        onPagination={handlePagination}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        onAdd={() => handleAdd()}
        onSort={handleSort}
      />
    </>
  );
};

export default JobDesignation;

import { call, put, takeEvery } from 'redux-saga/effects';

// Api-Services
import {
  checkEmailMobileApi,
  editUserDetailsApi,
  getAdminDashboardCountApi,
  getInterviewerListApi,
  getInterviewRoundApi,
  getInterviewRoundApiWithFilter,
  getRoundApiWithFilter,
  getTechnologyApiWithFilter
} from '../apis/admin';

// Redux-Slice
import {
  editUserDetails,
  getAdminDashboardData,
  getInterviewersData,
  getInterviewersFilterdData,
  getInterviewRoundData,
  getPieChartFilterdData,
  getRoundFilterdData,
  setEmailMobileValidationData
} from '../slices/admin';
import { apiSuccess } from '../slices/apiStatus';

// Constants
import {
  ADMIN_DASHBOARD_COUNT_REQUEST,
  ADMIN_INTERVIEWERS_LIST_REQUEST,
  ADMIN_INTERVIEW_ROUND_FILTER,
  ADMIN_INTERVIEW_ROUND_REQUEST,
  EDIT_USER_DETAILS,
  ROUND_WITH_FILTER,
  TECHNOLOGY_WITH_FILTER,
  USER_EMAIL_OR_MOBILE_EXIST
} from '../../constants/redux';
import { RESPONSE_STATUS_CODES } from '../../constants/common';

function* editUserDetailsSaga(action) {
  const userId = action.payload;
  const response = yield call(editUserDetailsApi, userId);
  if (response?.status === RESPONSE_STATUS_CODES.OK) {
    const userDetailsData = response.data.data;
    yield put(editUserDetails(userDetailsData));
    yield put(apiSuccess());
  }
}

function* getAdminDashboardCountSaga() {
  const response = yield call(getAdminDashboardCountApi);
  if (response?.status === RESPONSE_STATUS_CODES.OK) {
    const result = response.data.data;
    yield put(getAdminDashboardData(result));
  }
  return response;
}

function* getInterviewRoundSaga() {
  const response = yield call(getInterviewRoundApi);
  if (response?.status === RESPONSE_STATUS_CODES.OK) {
    const result = response.data.data;
    yield put(getInterviewRoundData(result));
  }
  return response;
}
function* getInterviewRoundWithFilterSaga(action) {
  const response = yield call(getInterviewRoundApiWithFilter, { ...action.payload });
  if (response?.status === RESPONSE_STATUS_CODES.OK) {
    const result = response.data.data;
    yield put(getInterviewersFilterdData(result));
  }
  return response;
}

function* getTechnologyWithFilterSaga(action) {
  const response = yield call(getTechnologyApiWithFilter, { ...action.payload });
  if (response?.status === RESPONSE_STATUS_CODES.OK) {
    const result = response.data.data;
    yield put(getPieChartFilterdData(result));
  }
  return response;
}

function* getRoundWithFilterSaga(action) {
  const response = yield call(getRoundApiWithFilter, { ...action.payload });
  if (response?.status === RESPONSE_STATUS_CODES.OK) {
    const result = response.data.data;
    yield put(getRoundFilterdData(result));
  }
  return response;
}

function* getInterviewerListSaga() {
  const response = yield call(getInterviewerListApi);
  if (response?.status === RESPONSE_STATUS_CODES.OK) {
    const result = response.data.data;
    yield put(getInterviewersData(result));
  }
  return response;
}

function* checkEmailMobileSaga(action) {
  const response = yield call(checkEmailMobileApi, { ...action.payload });
  yield put(setEmailMobileValidationData(response?.data?.status || false));
  return response;
}

export default function* watcherAdminDashboardCountSaga() {
  yield takeEvery(ADMIN_DASHBOARD_COUNT_REQUEST, getAdminDashboardCountSaga);
  yield takeEvery(ADMIN_INTERVIEW_ROUND_REQUEST, getInterviewRoundSaga);
  yield takeEvery(ADMIN_INTERVIEW_ROUND_FILTER, getInterviewRoundWithFilterSaga);
  yield takeEvery(ADMIN_INTERVIEWERS_LIST_REQUEST, getInterviewerListSaga);
  yield takeEvery(TECHNOLOGY_WITH_FILTER, getTechnologyWithFilterSaga);
  yield takeEvery(ROUND_WITH_FILTER, getRoundWithFilterSaga);
  yield takeEvery(EDIT_USER_DETAILS, editUserDetailsSaga);
  yield takeEvery(USER_EMAIL_OR_MOBILE_EXIST, checkEmailMobileSaga);
}

import { call, put, takeEvery } from 'redux-saga/effects';

// Api-Services
import {
  candidateDetailsApi,
  interviewDetailsApi,
  interviewerFeedbackDetailApi,
  interviewerInterviewDetailApi,
  overallInterviewerFeedbackDetailApi,
  userDetailsApi
} from '../apis/userDetails';

// Constants
import {
  CANDIDATE_DETAILS_REQUEST,
  GET_INTERVIEW_FEEDBACK_REQUEST,
  INTERVIEWER_FEEDBACK_DETAILS_REQUEST,
  INTERVIEWER_INTERVIEW_DETAILS_REQUEST,
  INTERVIEWS_DETAILS_REQUEST,
  USER_DETAILS_REQUEST
} from '../../constants/redux';
import { RESPONSE_STATUS_CODES } from '../../constants/common';

// Redux-Actions
import {
  userDetails,
  candidateDetails,
  interviewDetails,
  interviewerInterviewDetails,
  intervieweFeedbackDetails,
  overallintervieweFeedbackDetails
} from '../slices/userDetails';

function* userDetailsSaga(action) {
  const userId = action.payload;
  const response = yield call(userDetailsApi, userId);
  if (response?.status === RESPONSE_STATUS_CODES.OK) {
    const userDetailsData = response.data.data;
    yield put(userDetails(userDetailsData));
  }
}
function* candidateDetailsSaga(action) {
  const candidateId = action.payload;
  const response = yield call(candidateDetailsApi, candidateId);
  if (response.status === RESPONSE_STATUS_CODES.OK) {
    const candidateDetailsData = response.data.data;
    yield put(candidateDetails(candidateDetailsData));
  }
}

function* interviewDetailsSaga(action) {
  const response = yield call(interviewDetailsApi, action.payload);
  if (response.status === RESPONSE_STATUS_CODES.OK) {
    const interviewDetailsData = response.data.data;
    yield put(interviewDetails(interviewDetailsData));
  }
}
function* interviewerInterviewDetailsSaga(action) {
  const interviewID = action.payload;
  const response = yield call(interviewerInterviewDetailApi, interviewID);
  if (response.status === RESPONSE_STATUS_CODES.OK) {
    const interviewDetailsData = response.data.data;
    yield put(interviewerInterviewDetails(interviewDetailsData));
  }
}
function* interviewerFeedbackDetailsSaga(action) {
  const interviewID = action.payload;
  const response = yield call(interviewerFeedbackDetailApi, interviewID);
  if (response.status === RESPONSE_STATUS_CODES.OK) {
    const interviewDetailsData = response.data.data;
    yield put(intervieweFeedbackDetails(interviewDetailsData));
  }
}

function* overallInterviewerFeedbackDetailsSaga(action) {
  const interviewID = action.payload;
  const response = yield call(overallInterviewerFeedbackDetailApi, interviewID);
  if (response.status === RESPONSE_STATUS_CODES.OK) {
    const interviewDetailsData = response.data.data;
    yield put(overallintervieweFeedbackDetails(interviewDetailsData));
  }
}

export default function* watcherInterviewersSaga() {
  yield takeEvery(USER_DETAILS_REQUEST, userDetailsSaga);
  yield takeEvery(CANDIDATE_DETAILS_REQUEST, candidateDetailsSaga);
  yield takeEvery(INTERVIEWS_DETAILS_REQUEST, interviewDetailsSaga);
  yield takeEvery(INTERVIEWER_INTERVIEW_DETAILS_REQUEST, interviewerInterviewDetailsSaga);
  yield takeEvery(INTERVIEWER_FEEDBACK_DETAILS_REQUEST, interviewerFeedbackDetailsSaga);
  yield takeEvery(GET_INTERVIEW_FEEDBACK_REQUEST, overallInterviewerFeedbackDetailsSaga);
}

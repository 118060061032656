import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasHRAddedFeedback: false
};

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState: initialState,
  reducers: {
    userDetails(state, action) {
      const userDetails = action.payload;
      return {
        ...userDetails,
        isUpdated: false,
        isAdded: false,
        hasHRAddedFeedback: state.hasHRAddedFeedback
      };
    },
    candidateDetails(state, action) {
      const candidateDetails = action.payload;
      Object.assign(candidateDetails, {
        hasHRAddedFeedback: state.hasHRAddedFeedback
      });
      return candidateDetails;
    },
    interviewDetails(state, action) {
      const interviewDetails = action.payload;
      Object.assign(interviewDetails, {
        hasHRAddedFeedback: state.hasHRAddedFeedback
      });
      return { ...state, ...interviewDetails };
    },
    interviewerInterviewDetails(state, action) {
      const interviewerInterviewDetails = action.payload;
      Object.assign(interviewerInterviewDetails, {
        hasHRAddedFeedback: state.hasHRAddedFeedback
      });
      return interviewerInterviewDetails;
    },
    getFeedbackText(state, action) {
      var data = {};
      if (action.payload instanceof FormData) {
        action.payload.forEach(function (value, key) {
          data[key] = value;
        });
      } else {
        data = action.payload;
      }
      const {
        monthsOfExperience,
        yearsOfExperience,
        packagePerAnum,
        candidateExpectations,
        designation,
        codeQuality,
        feedbackNote,
        overallFeedback,
        overallGrades,
        taskCompletion,
        ratings,
        technicalAttitude,
        technicalCommunication,
        practicalCommunication,
        codeReview,
        canDoAttitude,
        candidateImage,
        practicalDefinition,
        practicalPerformedUsing,
        // practicalStartTime,
        // practicalEndTime,
        result,
        feedbackParameters,
        spendTime
      } = data;
      return {
        ...state,
        Designation: designation,
        CandidateExpectations: candidateExpectations,
        Package: packagePerAnum,
        YearsOfExperience: yearsOfExperience,
        MonthsOfExperience: monthsOfExperience,
        CodeQuality: codeQuality,
        FeedbackNote: feedbackNote,
        OverAllFeedback: overallFeedback,
        OverAllGrades: overallGrades,
        TaskCompletion: taskCompletion,
        Ratings: ratings ? JSON.parse(ratings) : '',
        TechnicalAttitude: technicalAttitude,
        TechnicalCommunication: technicalCommunication,
        PracticalCommunication: practicalCommunication,
        CodeReview: codeReview,
        CanDoAttitude: canDoAttitude,
        candidateImage: candidateImage || '',
        PracticalDefinition: practicalDefinition,
        PracticalPerformedUsing: practicalPerformedUsing,
        // PracticalStartTime: practicalStartTime,
        // PracticalEndTime: practicalEndTime,
        Result: result,
        SpendTime: spendTime,
        FeedbackParameters: feedbackParameters,
        hasHRAddedFeedback: state.hasHRAddedFeedback
      };
    },
    intervieweFeedbackDetails(state, action) {
      const interviewerInterviewDetails = action.payload;
      return {
        ...state,
        ...interviewerInterviewDetails,
        Package: interviewerInterviewDetails?.Package || '',
        Ratings: interviewerInterviewDetails?.Ratings || {},
        CodeQuality: interviewerInterviewDetails?.CodeQuality || '',
        Designation: interviewerInterviewDetails?.Designation || '',
        OverAllGrades: interviewerInterviewDetails?.OverAllGrades || '',
        TaskCompletion: interviewerInterviewDetails?.TaskCompletion || '',
        OverAllFeedback: interviewerInterviewDetails?.OverAllFeedback || '',
        YearsOfExperience: interviewerInterviewDetails?.YearsOfExperience || '',
        MonthsOfExperience: interviewerInterviewDetails?.MonthsOfExperience || '',
        CandidateExpectations: interviewerInterviewDetails?.CandidateExpectations || '',
        TechnicalAttitude: interviewerInterviewDetails?.TechnicalAttitude || '',
        TechnicalCommunication: interviewerInterviewDetails?.TechnicalCommunication || '',
        PracticalCommunication: interviewerInterviewDetails?.PracticalCommunication || '',
        CodeReview: interviewerInterviewDetails?.CodeReview || '',
        CanDoAttitude: interviewerInterviewDetails?.CanDoAttitude || '',
        PracticalDefinition: interviewerInterviewDetails?.PracticalDefinition || '',
        PracticalPerformedUsing: interviewerInterviewDetails?.PracticalPerformedUsing || '',
        PracticalStartTime: interviewerInterviewDetails?.PracticalStartTime || '',
        PracticalEndTime: interviewerInterviewDetails?.PracticalEndTime || '',
        Result: interviewerInterviewDetails?.Result || '',
        SpendTime: interviewerInterviewDetails?.SpendTime || '',
        FeedbackParameters: interviewerInterviewDetails?.FeedbackParameters || '',
        candidateImage: interviewerInterviewDetails?.candidateImage || ''
      };
    },
    overallintervieweFeedbackDetails(state, action) {
      return {
        ...state,
        overallFeedback: action.payload
      };
    },
    getUserID(state, action) {
      const userID = action.payload;
      return { ...state, userID };
    },
    updateData(state) {
      return { ...state, isUpdated: true, isAdded: true };
    },
    getUpdateFeedbackText(state, action) {
      const { codeQuality, feedbackNote, overallFeedback, overallGrades, taskCompletion, ratings } =
        action.payload;
      const InterviewRoundFeedback = {
        CodeQuality: codeQuality,
        FeedbackNote: feedbackNote,
        OverAllFeedback: overallFeedback,
        OverAllGrades: overallGrades,
        TaskCompletion: taskCompletion,
        Ratings: ratings,
        hasHRAddedFeedback: state.hasHRAddedFeedback
      };
      return InterviewRoundFeedback;
    },
    updateHasHRAddedFeedback(state) {
      return { ...state, hasHRAddedFeedback: !state.hasHRAddedFeedback };
    },
    updateCandidateData(state) {
      return { ...state, isCandidateUpdated: !state?.isCandidateUpdated };
    },
    isFeedbackUpdated(state, action) {
      return { ...state, isFeedbackUpdated: action.payload };
    }
  }
});

export const {
  userDetails,
  candidateDetails,
  interviewDetails,
  interviewerInterviewDetails,
  getFeedbackText,
  intervieweFeedbackDetails,
  getUserID,
  updateData,
  getUpdateFeedbackText,
  overallintervieweFeedbackDetails,
  updateHasHRAddedFeedback,
  isDataUpdated,
  updateCandidateData,
  isFeedbackUpdated
} = userDetailsSlice.actions;
export default userDetailsSlice.reducer;

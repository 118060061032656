import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Constants
import { ROLES, ROUTES } from '../constants/common';

const Home = () => {
  const { isLogged, userType } = useSelector((state) => state.auth);

  if (!isLogged) return <Navigate to={ROUTES.LOGIN} />;

  switch (userType) {
    case ROLES.ADMIN:
      return <Navigate to={ROUTES.ADMIN.DASHBOARD} />;
    case ROLES.HR:
    case ROLES.HR_AND_INTERVIEWER:
      return <Navigate to={ROUTES.HR.DASHBOARD} />;
    case ROLES.INTERVIEWER:
      return <Navigate to={ROUTES.INTERVIEWER.DASHBOARD} />;
    default:
      return <Navigate to={ROUTES.LOGIN} />;
  }
};

export default Home;

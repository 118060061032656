import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';

// Api-Services
import {
  interviewerAddApi,
  interviewersListApi,
  interviewerDeleteApi,
  interviewerScheduledApi,
  editInterviewerApi,
  userStatusApi,
  interviewerInvitationApi,
  interviewerPendingFeedbackApi,
  interviewersAvailabilityApi,
  interviewerAddAvailbilityApi,
  updateMobileApi
} from '../apis/interviewer';

// Constants
import {
  INTERVIEWERS_ADD_REQUEST,
  INTERVIEWERS_LIST_REQUEST,
  INTERVIEWERS_DELETE_REQUEST,
  INTERVIEWER_SCHEDULE_REQUEST,
  INTERVIEWERS_EDIT_REQUEST,
  USER_STATUS,
  INTERVIEWER_INVITATION_REQUEST,
  INTERVIEWER_FEEDBACK_REQUEST,
  INTERVIEWER_AVAILABLEILITY_REQUEST,
  INTERVIEWER_AVAILABLEILITY_ADD_REQUEST,
  UPDATE_MOBILE_NUMBER
} from '../../constants/redux';
import { RESPONSE_STATUS_CODES } from '../../constants/common';

// Redux-Slices
import {
  addAvailability,
  addInterviewers,
  deleteInterviewer,
  updateInterviewer
} from '../slices/interviewers';
import { apiSuccess } from '../slices/apiStatus';
import { addInterviewersSchedule } from '../slices/interviewDashboard';
import { updateHR } from '../slices/hr';
import { addInterviewersFeedback, addInterviewersInvitation } from '../slices/interviewDashboard';

function* interviewersListSaga(action) {
  try {
    const response = yield call(interviewersListApi, { ...action.payload });
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      const data = result.interviewers;
      const pagination = result.pagination;
      yield put(addInterviewers({ data, pagination }));
    }
  } catch (error) {
    throw new Error(error);
  }
}

function* interviewerAddSaga(action) {
  try {
    const response = yield call(interviewerAddApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.CREATED) {
      toast.success('Interviewer Added Successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
    }
  } catch (error) {
    throw new Error(error);
  }
}

function* interviewerDeleteSaga(action) {
  try {
    const response = yield call(interviewerDeleteApi, action.payload);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      yield put(deleteInterviewer(action.payload));
      toast.success('Interviewer Deleted Successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }
  } catch (error) {
    throw new Error(error);
  }
}
function* interviewerScheduledSaga() {
  const response = yield call(interviewerScheduledApi);
  if (response?.status === RESPONSE_STATUS_CODES.OK) {
    const result = response.data.data;
    const data = {
      scheduleInterview: result.scheduleInterview,
      totalRecords: result.pagination.totalRecords
    };
    yield put(addInterviewersSchedule(data));
  }
  return response;
}

function* editInterviewer(action) {
  try {
    const response = yield call(editInterviewerApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.SUCCESS) {
      toast.success('Details have been Updated Successfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

function* userStatusSaga(action) {
  const response = yield call(userStatusApi, action.payload);
  if (response?.status === RESPONSE_STATUS_CODES.OK) {
    const message = response.data.message;
    toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  }
  yield put(updateInterviewer(action.payload));
  yield put(updateHR(action.payload));
  return response;
}
function* interviewerInvitationSaga() {
  const response = yield call(interviewerInvitationApi);
  if (response?.status === RESPONSE_STATUS_CODES.OK) {
    const result = response.data.data;
    const data = {
      interviewInvitations: result.interviewInvitations,
      totalRecords: result.pagination.totalRecords
    };
    yield put(addInterviewersInvitation(data));
  }
  return response;
}
function* interviewerPendingFeedbackSaga() {
  const response = yield call(interviewerPendingFeedbackApi);
  if (response?.status === RESPONSE_STATUS_CODES.OK) {
    const result = response.data.data;
    const data = {
      pendingFeedback: result.pendingFeedback,
      totalRecords: result.pagination.totalRecords
    };
    yield put(addInterviewersFeedback(data));
  }
  return response;
}
function* interviewersAvailabilitySaga() {
  try {
    const response = yield call(interviewersAvailabilityApi);
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      yield put(addAvailability(result));
    }
  } catch (error) {
    throw new Error(error);
  }
}
function* interviewerAddAvailbilitydSaga(action) {
  try {
    const response = yield call(interviewerAddAvailbilityApi, action.payload);
    const availbility = action.payload;
    yield put(addAvailability(availbility.availability));
    if (response?.status === RESPONSE_STATUS_CODES.CREATED) {
      toast.success('Data Added Successfully.', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
    }
  } catch (error) {
    throw new Error(error);
  }
}
function* updateMobileSaga(action) {
  try {
    const response = yield call(updateMobileApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.SUCCESS) {
      toast.success('Details have been Updated Successfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}
export default function* watcherInterviewersSaga() {
  yield takeEvery(INTERVIEWER_SCHEDULE_REQUEST, interviewerScheduledSaga);
  yield takeEvery(INTERVIEWER_INVITATION_REQUEST, interviewerInvitationSaga);
  yield takeEvery(INTERVIEWER_FEEDBACK_REQUEST, interviewerPendingFeedbackSaga);
  yield takeEvery(INTERVIEWERS_LIST_REQUEST, interviewersListSaga);
  yield takeEvery(INTERVIEWERS_ADD_REQUEST, interviewerAddSaga);
  yield takeEvery(INTERVIEWERS_DELETE_REQUEST, interviewerDeleteSaga);
  yield takeEvery(INTERVIEWERS_EDIT_REQUEST, editInterviewer);
  yield takeEvery(USER_STATUS, userStatusSaga);
  yield takeEvery(INTERVIEWER_AVAILABLEILITY_REQUEST, interviewersAvailabilitySaga);
  yield takeEvery(INTERVIEWER_AVAILABLEILITY_ADD_REQUEST, interviewerAddAvailbilitydSaga);
  yield takeEvery(UPDATE_MOBILE_NUMBER, updateMobileSaga);
}

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

// Components
import ScrollToTop from './components/ScrollToTop';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary';

// Redux-Store
import { store } from './redux/store';

// Firebase
import { initializeFirebase } from './firebase/firebase';
import { initializeMessaging } from './firebase/messaging';

initializeFirebase();
initializeMessaging(store.dispatch);

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router>
      <ScrollToTop>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </ScrollToTop>
    </Router>
  </Provider>
);

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  technologyDetails: {},
  pagination: {}
};

const technologyListSlice = createSlice({
  name: 'technology',
  initialState: initialState,
  reducers: {
    addTechnologyData(state, action) {
      const { data, pagination } = action.payload;
      return { data, pagination, isTechnologyDelete: false, isUpdated: false, isAdded: false };
    },
    editTechnologyDetails(state, action) {
      const technologyDetails = action.payload;
      return { ...state, technologyDetails };
    },
    deleteTechnology(state) {
      return { ...state, isTechnologyDelete: true };
    },
    updateData(state) {
      return { ...state, isUpdated: true, isAdded: true };
    }
  }
});

export const { addTechnologyData, editTechnologyDetails, deleteTechnology, updateData } =
  technologyListSlice.actions;
export default technologyListSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  descriptionDetails: {},
  pagination: {}
};

const DescriptionListSlice = createSlice({
  name: 'jobDescription',
  initialState: initialState,
  reducers: {
    addJobDescriptionData(state, action) {
      const { data, pagination } = action.payload;
      return { data, pagination, isDescriptionDeleted: false, isUpdated: false, isAdded: false };
    },
    editJobDescriptionDetails(state, action) {
      const descriptionDetails = action.payload;
      return { ...state, descriptionDetails };
    },
    deleteDescription(state) {
      return { ...state, isDescriptionDeleted: true };
    },
    updateData(state) {
      return { ...state, isUpdated: true, isAdded: true };
    }
  }
});

export const { addJobDescriptionData, editJobDescriptionDetails, deleteDescription, updateData } =
  DescriptionListSlice.actions;
export default DescriptionListSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  scheduleData: { data: [] }
};

const interviewScheduleSlice = createSlice({
  name: 'interviewSchedule',
  initialState,
  reducers: {
    addScheduleInterview(state, action) {
      const { data, pagination } = action.payload;
      return { data, pagination };
    }
  }
});
export const { addScheduleInterview } = interviewScheduleSlice.actions;
export default interviewScheduleSlice.reducer;

import { API_ROUTES } from '../../constants/apiRoutes';
import api from '../../utils/axios';

export const notificationSendTokenApi = async (payload) => {
  try {
    const response = await api.put(API_ROUTES.PUSH_NOTIFICATION, {
      registrationToken: payload
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const notificationsApi = async () => {
  try {
    const response = await api.get(`${API_ROUTES.NOTIFICATIONS}?orderBy=DESC&sortBy=DateCreated`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const readNotificationsApi = async (notificationIds) => {
  try {
    const response = await api.put(`${API_ROUTES.READ_NOTIFICATIONS}`, { notificationIds });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

import { call, put, takeEvery } from 'redux-saga/effects';

// Api-Services
import { allInterviewersApi } from '../apis/showOptions';

// Constants
import { ALL_INTERVIEWERS_REQUEST } from '../../constants/redux';

// Redux-Actions
import { allInterviewers } from '../slices/showOptions';

function* allInterviewersSaga(action) {
  try {
    const interviewersResponse = yield call(allInterviewersApi, { ...action.payload });
    yield put(allInterviewers(interviewersResponse?.data?.data));
  } catch (error) {
    console.log(error);
  }
}

export default function* watcherOptionsSaga() {
  yield takeEvery(ALL_INTERVIEWERS_REQUEST, allInterviewersSaga);
}

import React from 'react';
import { useSelector } from 'react-redux';

// Prime-React-Components
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { TabView, TabPanel } from 'primereact/tabview';
import { isEmptyObject } from '../../utils/common';
import { Accordion, AccordionTab } from 'primereact/accordion';

const InterviewDetails = (props) => {
  const { isLoading } = useSelector((state) => state.apiStatus);
  const { onHide, show, data, isTabView = false } = props;

  return (
    <Dialog
      header="Interview Details"
      className={classNames('card interview-details-dialog', { hidden: isLoading })}
      visible={show}
      onHide={onHide}>
      {isTabView ? (
        <TabView>
          {data &&
            Object.keys(data).map((tabName, p) => {
              return (
                <TabPanel header={tabName} key={p}>
                  {tabName === 'Previous Round Details' ? (
                    <Accordion activeIndex={0}>
                      {data[tabName] && !isEmptyObject(data[tabName])
                        ? Object.keys(data[tabName]).map((label, i) => {
                            return label && data[tabName][label] ? (
                              <AccordionTab header={label} key={i}>
                                {data[tabName][label] &&
                                  Object.keys(data[tabName][label]).map((detail, i) => {
                                    return data[tabName][label][detail] ? (
                                      <div className="detail-row" key={i}>
                                        <p className="title">
                                          <span>{detail}</span>
                                          <span className="colon">:</span>
                                        </p>
                                        <div className="content">
                                          <span>{data[tabName][label][detail]}</span>
                                        </div>
                                      </div>
                                    ) : null;
                                  })}
                              </AccordionTab>
                            ) : null;
                          })
                        : 'Record Not Available'}
                    </Accordion>
                  ) : data[tabName] && !isEmptyObject(data[tabName]) ? (
                    Object.keys(data[tabName]).map((label, i) => {
                      return label && data[tabName][label] ? (
                        <div className="content-wrap" key={i}>
                          {data[tabName][label] &&
                            Object.keys(data[tabName][label]).map((detail, i) => {
                              return data[tabName][label][detail] ? (
                                <div className="detail-row" key={i}>
                                  <p className="title">
                                    <span>{detail}</span>
                                    <span className="colon">:</span>
                                  </p>
                                  <div className="content">
                                    <span>{data[tabName][label][detail]}</span>
                                  </div>
                                </div>
                              ) : null;
                            })}
                        </div>
                      ) : null;
                    })
                  ) : (
                    'Record Not Available'
                  )}
                </TabPanel>
              );
            })}
        </TabView>
      ) : (
        data &&
        Object.keys(data).map((tabName, i) => {
          return tabName && data[tabName] ? (
            <div className="content-wrap" key={i}>
              {data &&
                Object.keys(data[tabName]).map((detail, i) => {
                  return data[tabName][detail] ? (
                    <div className="detail-row" key={i}>
                      <p className="title">
                        <span>{detail}</span>
                        <span className="colon">:</span>
                      </p>
                      <div className="content">
                        <span>{data[tabName][detail]}</span>
                      </div>
                    </div>
                  ) : null;
                })}
            </div>
          ) : null;
        })
      )}
    </Dialog>
  );
};

export default InterviewDetails;

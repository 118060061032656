import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Images
import logoImg from '@assets/images/site-logo.svg';
import logodarkImg from '@assets/images/site-logo-dark.svg';

// Components
import CustomButton from '../../components/CustomButton';
import Asteric from '../../components/Asteric';
import Label from '../../components/Label';

// Prime-React Components
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';

// Constants
import { ROUTES, BUTTON_TYPES, LABEL_TYPES, EMAIL_PATTERN_REGEX } from '../../constants/common';

// Redux-Actions
import { forgotPassword } from '../../redux/actions/auth';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.apiStatus);
  const [onFocus, setOnFocus] = useState(false);
  const [onBlur, setOnBlur] = useState(false);

  const handleOnFocus = () => {
    setOnFocus(true);
    setOnBlur(false);
  };

  const handleOnBlur = () => {
    setOnFocus(false);
    setOnBlur(true);
  };

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(EMAIL_PATTERN_REGEX, 'Email Address is invalid.')
        .required('Email Address is required.')
    }),

    onSubmit: () => {
      try {
        dispatch(forgotPassword(formik.values));
        formik.resetForm();
      } catch (error) {
        console.log(error);
      }
    }
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  return (
    <div className="authentication-wrapper forgot-pwd">
      <div className="authentication-inner">
        <div className="login-container">
          <span className="site-logo">
            <img src={logoImg}></img>
            <img src={logodarkImg} className="dark-theme-logo"></img>
          </span>
          <h2>Forgot Password</h2>
          <p>Enter your email and we will send you instructions to reset your password</p>
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="field">
              <Label
                htmlFor="email"
                text={LABEL_TYPES.EMAIL}
                isBold
                onFocus={onFocus}
                onBlur={onBlur}
              />
              <Asteric />
              <span className="p-input-icon-right">
                <i className="pi pi-user" />
                <InputText
                  id="email"
                  name="email"
                  placeholder="Eg. example@gmail.com"
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  className={classNames({
                    'p-invalid': isFormFieldValid('email')
                  })}
                />
              </span>
              {getFormErrorMessage('email')}
            </div>

            <CustomButton
              type="submit"
              variant="contained"
              className="w-full"
              disabled={isLoading ? true : false}>
              {BUTTON_TYPES.SEND}
            </CustomButton>

            <div className="link-wrapper back-login">
              <Link to={ROUTES.LOGIN}>
                <span className="pi pi-angle-left"></span>Back to login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

import React, { useState } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { Ripple } from 'primereact/ripple';
import { Badge } from 'primereact/badge';
import { Tooltip } from 'primereact/tooltip';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

// Images
import siteLogo from '@assets/images/site-logo.svg';
import siteLogoDark from '@assets/images/site-logo-dark.svg';
import siteLogoTatva from '@assets/images/site-logo-tatva.svg';
import siteLogoTatvaDark from '@assets/images/site-logo-tatva-dark.svg';
import sidebarShowIcon from '@assets/images/sidebar-toggle-show.svg';
import sidebarHideIcon from '@assets/images/sidebar-toggle-hide.svg';

// Components
import { CustomeTooltip } from '../components/ActionButtons';

// Constants
import { APP_VERSION, ROUTES } from '../constants/common';

const AppSubmenu = (props) => {
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(
    [
      ROUTES.ADMIN.DESIGNATION,
      ROUTES.ADMIN.TECHNOLOGY,
      ROUTES.ADMIN.COMMON_PARAMETERS,
      ROUTES.ADMIN.DESCRIPTIONS
    ].indexOf(location.pathname) !== -1
      ? 4
      : null
  );

  const onMenuItemClick = (event, item, index) => {
    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    //execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    if (index === activeIndex) setActiveIndex(null);
    else setActiveIndex(index);

    if (props.onMenuItemClick) {
      props.onMenuItemClick({
        originalEvent: event,
        item: item
      });
    }
  };

  const onKeyDown = (event) => {
    if (event.code === 'Enter' || event.code === 'Space') {
      event.preventDefault();
      event.target.click();
    }
  };

  const renderLinkContent = (item) => {
    const submenuIcon = item.items && (
      <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
    );

    return (
      <React.Fragment>
        <i className={item.icon}></i>
        <span>{item.label}</span>
        {submenuIcon}
        <Ripple />
      </React.Fragment>
    );
  };

  const renderLink = (item, i) => {
    const content = renderLinkContent(item);
    const badge = item.badge && (
      <>
        <Tooltip target=".custom-target-icon" position="top" />
        <div
          className="custom-target-icon"
          data-pr-tooltip="Work in progress"
          data-pr-position="top">
          <Badge value={item.badge} className="white-space-nowrap" />
        </div>
      </>
    );

    if (item.to) {
      return (
        <div className="flex align-items-center justify-content-between">
          <NavLink
            aria-label={item.label}
            onKeyDown={onKeyDown}
            role="menuitem"
            className={(link) =>
              link.isActive ? 'router-link-active router-link-exact-active' : 'p-ripple'
            }
            to={item.to}
            onClick={(e) => onMenuItemClick(e, item, i)}
            target={item.target}>
            {content}
          </NavLink>
          <div className="badge">{badge}</div>
          <Ripple />
        </div>
      );
    } else {
      return (
        <a
          tabIndex="0"
          aria-label={item.label}
          onKeyDown={onKeyDown}
          role="menuitem"
          href={item.url}
          className="p-ripple subMenu"
          onClick={(e) => onMenuItemClick(e, item, i)}
          target={item.target}>
          {content}
        </a>
      );
    }
  };

  const items =
    props.items &&
    props.items.map((item, i) => {
      const active = activeIndex === i;
      const styleClass = classNames(item.badgeStyleClass, {
        'layout-menuitem-category': props.root,
        'active-menuitem': active && !item.to
      });

      if (props.root) {
        return (
          <li className={styleClass} key={i} role="none">
            {props.root === true && (
              <React.Fragment>
                <div className="layout-menuitem-root-text" aria-label={item.label}>
                  {item.label}
                </div>
                <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick} />
              </React.Fragment>
            )}
          </li>
        );
      } else {
        return (
          <li className={styleClass} key={i} role="none">
            {renderLink(item, i)}
            <CSSTransition
              classNames="layout-submenu-wrapper"
              timeout={{ enter: 1000, exit: 450 }}
              in={active}
              unmountOnExit>
              <AppSubmenu items={item.items} onMenuItemClick={props.onMenuItemClick} />
            </CSSTransition>
          </li>
        );
      }
    });

  return items ? (
    <ul className={props.className} role="menu">
      {items}
    </ul>
  ) : null;
};

const AppMenu = (props) => {
  return (
    <div className="layout-menu-container">
      <div className="site-logo-wrap">
        <Link to={ROUTES.HOME} className="site-logo" title="IMS">
          <img src={siteLogo} className="light-theme-logo" />
          <img src={siteLogoDark} className="dark-theme-logo" />
        </Link>
        <span
          className="sidebar-toggle"
          onClick={() => {
            document.body.classList.toggle('sidebar-hide');
          }}>
          <img src={sidebarShowIcon} alt="show-dot" className="show-dot" />
          <img src={sidebarHideIcon} alt="hide-dot" className="hide-dot" />
        </span>
      </div>
      <div className="sidebar-inner-content ">
        <AppSubmenu
          items={props.model}
          className="layout-menu"
          onMenuItemClick={props.onMenuItemClick}
          root={true}
          role="menu"
        />
      </div>
      <div className="copyright-block">
        <CustomeTooltip></CustomeTooltip>
        <p className="site-name">
          <img src={siteLogoTatva} alt="Tatvasoft-logo" className="light-theme-logo" />
          <img src={siteLogoTatvaDark} alt="Tatvasoft-logo" className="dark-theme-logo" />
        </p>
        <p className="copyright-text">Version: {APP_VERSION}</p>
      </div>
    </div>
  );
};

export default AppMenu;

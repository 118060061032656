import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  designationDetails: {},
  pagination: {}
};

const DesignationListSlice = createSlice({
  name: 'designation',
  initialState: initialState,
  reducers: {
    addJobDesignationData(state, action) {
      const { data, pagination } = action.payload;
      return { data, pagination, isDesignationDeleted: false, isUpdated: false, isAdded: false };
    },
    editJobDesignationDetails(state, action) {
      const designationDetails = action.payload;
      return { ...state, designationDetails };
    },
    deleteDesignation(state) {
      return { ...state, isDesignationDeleted: true };
    },
    updateData(state) {
      return { ...state, isUpdated: true, isAdded: true };
    }
  }
});

export const { addJobDesignationData, editJobDesignationDetails, deleteDesignation, updateData } =
  DesignationListSlice.actions;
export default DesignationListSlice.reducer;

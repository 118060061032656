import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Components
import Layout from '../layout/Layout';

// Constants
import { ROUTES } from '../constants/common';

const Protected = (props) => {
  const { isLogged, userType, isRegisteredUser, token } = useSelector((state) => state.auth);
  if (!isLogged) return <Navigate to={ROUTES.LOGIN} />;
  if (isLogged && !isRegisteredUser)
    return <Navigate to={`${ROUTES.RESET_PASSWORD_WITHOUT_TOKEN}/${token}`} />;
  if (
    (Array.isArray(props.userRole)
      ? props.userRole.indexOf(userType) !== -1
      : props.userRole === userType) ||
    props.isLogged === isLogged
  )
    return <Layout />;
  return <Navigate to={ROUTES.NOT_ALLOWED} />;
};

export default Protected;

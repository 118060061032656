import {
  CANDIDATE_DETAILS_REQUEST,
  INTERVIEWS_DETAILS_REQUEST,
  USER_DETAILS_REQUEST,
  INTERVIEWER_INTERVIEW_DETAILS_REQUEST,
  INTERVIEWER_FEEDBACK_DETAILS_REQUEST,
  GET_INTERVIEW_FEEDBACK_REQUEST
} from '../../constants/redux';

export const userDetailsAction = (userId) => {
  return {
    type: USER_DETAILS_REQUEST,
    payload: userId
  };
};

export const candidateDetailsAction = (candidateId) => {
  return {
    type: CANDIDATE_DETAILS_REQUEST,
    payload: candidateId
  };
};

export const interviewDetailAction = (payload) => {
  return {
    type: INTERVIEWS_DETAILS_REQUEST,
    payload: payload
  };
};
export const interviewerInterviewDetailAction = (interviewID) => {
  return {
    type: INTERVIEWER_INTERVIEW_DETAILS_REQUEST,
    payload: interviewID
  };
};

export const interviewerFeedbackDetailAction = (interviewRoundId) => {
  return {
    type: INTERVIEWER_FEEDBACK_DETAILS_REQUEST,
    payload: interviewRoundId
  };
};

export const overallIintervieweFeedbackDetailAction = (interviewId) => {
  return {
    type: GET_INTERVIEW_FEEDBACK_REQUEST,
    payload: interviewId
  };
};

import { API_ROUTES } from '../../constants/apiRoutes';
import api from '../../utils/axios';

export const allInterviewersApi = async ({
  interviewStartTime,
  interviewEndTime,
  technologies,
  interviewRoundId,
  isHrRound,
  isPracticalAllow
}) => {
  try {
    const data = {
      interviewStartTime,
      interviewEndTime,
      technologies,
      interviewRoundId,
      isHrRound,
      isPracticalAllow
    };
    const response = await api.post(`${API_ROUTES.INTERVIEWER_DROPDOWN}`, data);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const allHrsApi = async () => {
  try {
    const response = await api.get(`${API_ROUTES.FILTERED_HRS_LIST}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
